import logo from "../../assets/divine.webp";

import React, { useEffect } from "react";
import { useAppContext } from "../../Context/UseAuth.js";
import { useCampoContext } from "../../Context/CampoAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import "../../CSS/Home.css";
import { useCamposUser } from "../../CustomHooks/useCamposUser";
library.add(fab, fas, far);

const Home = () => {
  const appContext = useAppContext();
  const campoContext = useCampoContext();

  const user = JSON.parse(localStorage.getItem("@usuario"));
  const { camposUser } = useCamposUser(user.id);

  const LogoutHandler = () => {
    localStorage.clear();
    appContext.setIsLoggedin(false);
  };

  const SetCampoHandler = (e, index) => {
    let selectedCampo = camposUser[index];

    campoContext.setSelectedCampo(selectedCampo.idcampo);
    campoContext.setCampoState(selectedCampo.idcampo);
    localStorage.setItem("Campo", selectedCampo.campo);
  };

  useEffect(() => {
    const setCampo = () => {
      if (camposUser.length === 1) {
        campoContext.setSelectedCampo(camposUser[0].idcampo);
        campoContext.setCampoState(camposUser[0].idcampo);
        localStorage.setItem("Campo", camposUser[0].campo);
      }
    };

    setCampo();
  }, [camposUser, campoContext]);

  return (
    <>
      <header>
        <nav className="main-nav">
          <a href="/">
            <img height={48} width={48} src={logo} alt="logo" />
          </a>
          <button className="user-nav" onClick={() => LogoutHandler()}>
            <div className="logout_menu">
              <i>
                <FontAwesomeIcon icon="fa-solid fa-arrow-right-from-bracket" />
              </i>
              <p>Cerrar sesión</p>
            </div>
          </button>
        </nav>
      </header>

      <main className="main-contenedor">
        <h2 className="header-container">CAMPOS</h2>
        <hr />
        <div className="container-child">
          {camposUser.map((item, index) => (
            <button
              key={item.idcampo}
              className="tarjetas-campo"
              value={item.idcampo}
              id={item.campo + "-" + item.idcampo}
              name={item.campo + "-" + item.idcampo}
              onClick={(e) => SetCampoHandler(e, index)}
            >
              <i>
                <FontAwesomeIcon icon="fa-solid fa-map-location-dot" />
              </i>
              <p>{item.campo}</p>
            </button>
          ))}
        </div>
      </main>
    </>
  );
};

export default Home;
