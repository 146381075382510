import React from "react";
import logo from "../../assets/divine.webp";

import "../../CSS/inicio.css";

const Inicio = () => {
  return (
    <div className="titulo">
      <img height={94} width={94} src={logo} alt="logo" />
    </div>
  );
};

export default Inicio;
