import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "react-bootstrap";
import { Card, CardBody, CardHeader } from "reactstrap";
import Swal from "sweetalert2";
import api from "../../Component/axios";
import "../../CSS/configuracion.css";

const validateInput = (inputValue) => {
  const nonAlphabetPattern = /^[^a-zA-Z]*$/;
  return nonAlphabetPattern.test(inputValue);
};

const InsertarCultivo = () => {
  const [cultivo, setCultivo] = useState("");
  const [tamanosSelected, setTamanoSelected] = useState([]);
  const [editar, setEditar] = useState({
    isActive: false,
    id_cultivo: 0,
  });
  const [listCultivo, setListCultivo] = useState([]);
  const [envaseList, setEnvaseList] = useState([]);
  const token = localStorage.getItem("@Token");
  const user = JSON.parse(localStorage.getItem("@usuario"));

  const envaseChangeHandler = (e, i) => {
    let aux = [...envaseList];
    aux[i].estatus = e.target.checked ? 1 : 0;
    const tamano_selected = aux.filter((item) => item.estatus === 1);
    const new_envase_list = tamano_selected.map((item) => {
      return item.idtamano_envase;
    });
    setTamanoSelected(new_envase_list);
    setEnvaseList(aux);
  };

  const validateData = (e) => {
    e.preventDefault();
    if (cultivo === "" || validateInput(cultivo)) {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "Favor de llenar todos los campos",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    if (!editar.isActive) {
      saveNewCultivo();
    }

    if (editar.isActive) {
      updateCultivo();
    }
  };

  const updateCultivo = () => {
    api
      .put(
        "api/cultivo/cultivo/",
        {
          data: {
            id_cultivo: editar.id_cultivo,
            cultivo: cultivo,
            id_tamano: tamanosSelected,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + token,
          },
        }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Registro actualizado",
          timer: 1500,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Algo salió mal!",
        });
      })
      .finally(() => {
        setEditar({
          ...editar,
          isActive: false,
          id_cultivo: 0,
        });
        setCultivo("");
        fetchCultivo();
      });
  };

  const saveNewCultivo = () => {
    api
      .post(
        "api/cultivo/cultivo/",
        {
          data: {
            cultivo: cultivo,
            idusuario: user.id,
            relacion: tamanosSelected,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + token,
          },
        }
      )
      .then(() => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Registro guardado",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        if (error.response.status === 500) {
          Swal.fire({
            title: "Oh oh algo salio mal!",
            text: "Contacte al area de desarrollo para solucionar el error",
            icon: "error",
          });
        }
      })
      .finally(() => {
        setCultivo("");
        fetchCultivo();
      });
  };

  const fetchCultivo = () => {
    api
      .get("api/cultivo/cultivo/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        setListCultivo(response.data);
      })
      .catch((error) => {
        if (error.response.status === 500) {
          Swal.fire({
            title: "Oh oh algo salio mal!",
            text: "Surgió un error al intentar obtener la lista de cultivos",
            icon: "error",
          });
        }
      });

    api.get("api/etiquetas/obt/tamano-envase").then((response) => {
      setEnvaseList(response.data);
    });
  };

  const deletCultivo = (id_cultivo, estatus) => {
    const action = estatus === 0 ? "activar" : "desactivar";
    const responseText = estatus === 0 ? "activado" : "desactivado";

    Swal.fire({
      title: `¿Desea ${action} el cultivo?`,
      showCancelButton: true,
      confirmButtonText: estatus === 0 ? "Activar" : "Desactivar",
      confirmButtonColor: estatus === 0 ? "#28a745" : "#dc3545",
      cancelButtonColor: "blue",
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      backdrop: true,
      preConfirm: async () => {
        try {
          const response = await api.delete(
            `api/cultivo/cultivo/?id_cultivo=${id_cultivo}&estatus=${
              estatus === 1 ? 0 : 1
            }`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + token,
              },
            }
          );
          return response;
        } catch (error) {
          Swal.showValidationMessage(
            `Error - Favor de comunicarlo con el equipo de desarrollo`
          );
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: `Registro ${responseText}`,
          timer: 1500,
        });
        fetchCultivo();
      }
    });
  };

  const OpenEditModal = (id_cultivo) => {
    const cultivo_selected = listCultivo.find(
      (item) => item.idcultivo === id_cultivo
    );
    setEditar({
      ...editar,
      isActive: true,
      id_cultivo: id_cultivo,
    });
    setCultivo(cultivo_selected.cultivo);

    api
      .get(`api/cultivo/relacion/?id_cultivo=${id_cultivo}`)
      .then((response) => {
        const new_envase_list = envaseList.map((item) => ({
          ...item,
          estatus: response.data.includes(item.idtamano_envase) ? 1 : 0,
        }));
        setEnvaseList(new_envase_list);
      });
  };

  const cancelEditCultivo = () => {
    setEditar({
      ...editar,
      isActive: false,
      id_cultivo: 0,
    });
    setCultivo("");
    fetchCultivo();
  };

  useEffect(() => {
    fetchCultivo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className="my-2" style={{ height: "90vh" }}>
      <CardHeader style={{ fontWeight: "bold", textAlign: "center" }}>
        Nuevo Cultivo
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <form onSubmit={(e) => validateData(e)}>
              <Row>
                <Col md={3}>
                  <input
                    className="form-control"
                    placeholder="Cultivo"
                    value={cultivo}
                    onChange={(e) => setCultivo(e.target.value)}
                  />
                </Col>
                <Col md={3}>
                  <div className="d-grid">
                    <button className="btn btn-success" type="submit">
                      <i>
                        <FontAwesomeIcon icon="fa-solid fa-floppy-disk" />
                      </i>{" "}
                      Guardar
                    </button>
                  </div>
                </Col>
                {editar.isActive && (
                  <Col md={3}>
                    <div className="d-grid">
                      <button
                        className="btn btn-danger"
                        onClick={() => cancelEditCultivo()}
                      >
                        Cancelar
                      </button>
                    </div>
                  </Col>
                )}
              </Row>
            </form>
          </Col>
        </Row>
        <br />
        <Row style={{ overflow: "auto", height: "70vh" }}>
          <Col>
            <div
              style={{
                border: "1px solid black",
                maxWidth: "100%",
                minWidth: "50%",
                maxHeight: "400px",
                minHeight: "100px",
                overflowY: "scroll",
              }}
            >
              {envaseList.map((item, i) => (
                <div className="form-check" key={item.idtamano_envase}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={item.estatus === 0 ? false : true}
                    onChange={(e) => envaseChangeHandler(e, i)}
                  />
                  <label className="form-check-label">{item.tamano}</label>
                </div>
              ))}
            </div>
          </Col>
          <Col>
            <div>
              <table className="table table-sm">
                <thead className="table-light">
                  <tr>
                    <th>Cultivo</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {listCultivo.map((item, i) => (
                    <tr>
                      <td key={item.idcultivo}>{item.cultivo}</td>
                      <td>
                        <button
                          className={
                            item.estatus === 1
                              ? "btn btn-danger btn-sm"
                              : "btn btn-success btn-sm"
                          }
                          onClick={() =>
                            deletCultivo(item.idcultivo, item.estatus)
                          }
                          style={{ marginRight: "10px" }}
                        >
                          <FontAwesomeIcon icon="fas fa-power-off" />
                        </button>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => OpenEditModal(item.idcultivo)}
                        >
                          <FontAwesomeIcon icon="fa-solid fa-pen" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default InsertarCultivo;
