/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import Swal from "sweetalert2";
import api from "../../Component/axios";
import axios from "axios";

const ProyeccionDiaria = () => {
  const [form, setForm] = useState({
    id_cultivo: 0,
    fecha: moment().add(1, "day").format("YYYY-MM-DD"),
  });
  const [tableContent, setTableContent] = useState([]);
  const [bitacoraContent, setBitacoraContent] = useState([]);
  const [tamanoList, setTamanoList] = useState([]);
  const [cultivoList, setCultivoList] = useState([]);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState(0);
  const [notificationNumbers, setNotificationNumbers] = useState([]);
  const [totalDia, setTotalDia] = useState(0);

  const getTamano = () => {
    api
      .get(`api/temporada/tamanos?id_cultivo=${form.id_cultivo}`)
      .then((response) => {
        setTamanoList(response.data);
      });
  };

  const getData = () => {
    const token = localStorage.getItem("@Token");
    api
      .get(
        `api/proyeccion-diaria/get/proyeccion_diaria/?fecha=${
          form.fecha
        }&id_cultivo=${form.id_cultivo}&id_campo=${localStorage.getItem(
          "codigo_campo"
        )}`,
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response) => {
        let total = 0;
        setNotificationStatus(response.data[1]);
        setTableContent(response.data[0]);
        response.data[0].forEach((item) => {
          total += Number(item.total_dia);
        });
        setTotalDia(total);
      });
  };

  const handleTamañoChange = (variedadIndex, tamanoIndex, e) => {
    let arr = [...tableContent];
    let total = 0;
    arr[variedadIndex].tamanos[tamanoIndex].valor = e.target.value;
    for (let index in arr[variedadIndex].tamanos) {
      let valor = arr[variedadIndex].tamanos[index].valor;
      if (valor === "") {
        valor = 0;
      }
      total += parseFloat(valor);
      if (total > 100) {
        total -= parseFloat(valor);
        arr[variedadIndex].tamanos[tamanoIndex].valor = 0;
        break;
      }
    }
    arr[variedadIndex].porcentaje_total = total;
    setTableContent(arr);
  };

  const handleTotalDiaChange = (variedadIndex, e) => {
    let total = 0;
    let arr = [...tableContent];
    arr[variedadIndex].total_dia = e.target.value;
    arr.forEach((item) => {
      total += Number(item.total_dia);
    });
    setTotalDia(total);
    setTableContent(arr);
  };

  const validateData = () => {
    let error = false;
    let variedades = "";
    tableContent.forEach((item, i) => {
      if (item.total_dia !== "" && parseInt(item.total_dia) !== 0) {
        if (parseInt(item.porcentaje_total) < 100) {
          error = true;
          if (i === 0) {
            variedades += item.variedad;
          } else {
            variedades += ", " + item.variedad;
          }
        }
      }
    });

    if (error) {
      Swal.fire({
        icon: "info",
        title: "Porcentajes no alcanzados",
        text:
          "Los porcentajes en las variedades " +
          variedades +
          " no fueron alcanzados",
      });
      return;
    }

    guardarProyeccion();
  };

  const guardarProyeccion = async () => {
    const orden_empaqie_exists = tableContent.filter(
      (item) => item.exists === true
    );
    const variedad_name = orden_empaqie_exists.map((item) => item.variedad);
    if (variedad_name.length > 0) {
      Swal.fire({
        icon: "warning",
        title: `Las variedades ${variedad_name.join(
          ", "
        )} cuentan con pedido de empaque.`,
        text: "¿Está seguro que desa continuar?",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
        confirmButtonColor: "green",
        cancelButtonColor: "red",
        showLoaderOnConfirm: true,
        backdrop: true,
        allowOutsideClick: () => !Swal.isLoading(),
        preConfirm: async () => {
          try {
            await postData();
          } catch (error) {
            if (axios.isAxiosError(error)) {
              if (error.response.status === 400) {
                Swal.showValidationMessage(`${error.response.data.msg}`);
                return;
              }
            }
            Swal.showValidationMessage("Algo salió mal!");
          }
        },
      }).then((res) => {
        if (res.isConfirmed) {
          Swal.fire({
            icon: "success",
            title: "Registros guardados",
            timer: 1500,
          });
          getData();
        }
      });
    } else {
      try {
        const response = await postData();
        Swal.fire({
          icon: "success",
          title: response.msg,
          timer: 1500,
        });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response.status === 400) {
            Swal.fire({
              icon: "error",
              title: `${error.response.data.msg}`,
            });
            return;
          }
        }
        Swal.fire({
          icon: "error",
          title: `Algo salio mal!`,
          text: "Favor de comunicarlo con el equipo de desarrollo",
        });
      }
      getData();
    }
  };

  const postData = async () => {
    const usuario = localStorage.getItem("@usuario");
    const usuario_json = JSON.parse(usuario);
    const token = localStorage.getItem("@Token");
    try {
      const response = await api.post(
        "api/proyeccion-diaria/get/proyeccion_diaria/",
        {
          data: {
            tableContent,
            id_usuario: usuario_json.id,
            usuario: usuario_json.usuario,
            id_campo: localStorage.getItem("codigo_campo"),
            fecha: form.fecha,
          },
        },
        { headers: { Authorization: "Token " + token } }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const openBitacora = (id_variedad) => {
    api
      .get(
        `api/proyeccion-diaria/bitacora/?id_variedad=${id_variedad}&id_campo=${localStorage.getItem(
          "codigo_campo"
        )}&fecha=${form.fecha}`
      )
      .then((response) => {
        setBitacoraContent(response.data);
      })
      .finally(() => {
        setModalIsVisible(true);
      });
  };

  const sendNotifications = () => {
    const user = localStorage.getItem("@usuario");
    const json_user = JSON.parse(user);
    if (notificationNumbers.length === 0) {
      Swal.fire({
        icon: "info",
        title: "Favor de comunicarlo con el equipo de desarrollo",
        text: "No hay numeros a donde mandar notificaciones",
      });
      return;
    }
    Swal.fire({
      icon: "info",
      title: "Enviar notificación",
      text: "¿Está seguro de que desea enviar la notificación?",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
      confirmButtonText: "Enviar",
      cancelButtonText: "Cancelar",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        let mensaje = `capturado`;
        if (notificationStatus === 2) {
          mensaje = `modificado`;
        }
        await notificationNumbers.reduce(async (promise, current) => {
          await promise;
          var data = JSON.stringify({
            token: process.env.REACT_APP_NOTIFICATION_TOKEN,
            to: current.number,
            body: `✉️ Notificación automática: 

Les informamos que ${json_user.nombre} ${
              json_user.apellido
            } ha ${mensaje} la proyección de cosecha del dia 📅 ${moment(
              form.fecha
            ).format("DD/MM/YYYY")} del campo ${localStorage.getItem(
              "Campo"
            )} y se encuentra disponible en la plataforma, favor de verificarlo ✅`,
          });

          var config = {
            method: "post",
            url: `https://api.ultramsg.com/${process.env.REACT_APP_NOTIFICATION_INSTANCE}/messages/chat`,
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          };
          try {
            await axios(config);
          } catch (error) {
            Swal.showValidationMessage(
              `Error - Favor de comunicarlo con el equipo de desarrollo`
            );
            return;
          }
        }, Promise.resolve());
      },
      allowOutsideClick: () => {
        return !Swal.isLoading();
      },
      backdrop: true,
    }).then((res) => {
      if (res.isConfirmed) {
        api
          .post("api/proyeccion-diaria/bitacora/", {
            data: {
              id_campo: localStorage.getItem("codigo_campo"),
              fecha: form.fecha,
            },
          })
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Notificación enviada",
              timer: 1400,
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title:
                "La notificacion fue enviada pero no se logro guardar la bitacora de notificaciónes, favor de comunicarlo al equipo de desarrollo",
            });
          })
          .finally(() => {
            getData();
          });
      }
    });
  };

  const deleteProyeccion = (id_variedad) => {
    const token = localStorage.getItem("@Token");
    Swal.fire({
      icon: "info",
      title: "¿Está seguro que desea eliminar la proyección?",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#28a745",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const url = `api/proyeccion-diaria/get/proyeccion_diaria/?fecha=${
          form.fecha
        }&id_cultivo=${form.id_cultivo}&id_campo=${localStorage.getItem(
          "codigo_campo"
        )}&id_variedad=${id_variedad}`;

        try {
          await api.delete(url, {
            headers: {
              Authorization: "Token " + token,
            },
          });
        } catch (error) {
          if (axios.isAxiosError(error)) {
            if (error.response.status === 400) {
              Swal.showValidationMessage(`${error.response.data.msg}`);
              return;
            }
          }
          Swal.showValidationMessage(
            `Error - Favor de comunicarlo con el equipo de desarrollo`
          );
          return;
        }
      },
      allowOutsideClick: () => {
        return !Swal.isLoading();
      },
      backdrop: true,
    }).then((res) => {
      if (res.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Proyección eliminada",
          timer: 1500,
        });
        getData();
      }
    });
  };

  useEffect(() => {
    const getCultivo = async () => {
      const token = localStorage.getItem("@Token");
      const codigo_campo = localStorage.getItem("codigo_campo");

      try {
        const res = await api.post(
          "api/cultivo/get/cultivo-por-campo",
          {
            data: codigo_campo,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + token,
            },
          }
        );
        setCultivoList(res.data);
        if (res.data.length === 1) {
          setForm({
            ...form,
            id_cultivo: res.data[0].idcultivo,
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getNotificationNumbers = () => {
      const token = localStorage.getItem("@Token");
      const id_campo = localStorage.getItem("codigo_campo");
      api
        .get(
          `api/permisos/notification-numbers/?type=diaria&id_campo=${id_campo}`,
          {
            headers: {
              Authorization: "Token " + token,
            },
          }
        )
        .then((response) => {
          setNotificationNumbers(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getNotificationNumbers();

    getCultivo();
  }, []);

  useEffect(() => {
    if (form.id_cultivo !== 0) {
      getTamano();
    }
  }, [form.id_cultivo]);

  useEffect(() => {
    getData();
  }, [form]);

  return (
    <>
      <Card className="my-2">
        <CardHeader>Proyección diaria</CardHeader>
        <CardBody>
          <div className="row">
            {cultivoList.length > 1 && (
              <div className="col-sm-12 my-2">
                <select
                  value={form.id_cultivo}
                  className="form-control"
                  onChange={(value) => {
                    setForm({
                      ...form,
                      id_cultivo: value.target.value,
                    });
                  }}
                >
                  <option value={0}>Cultivo...</option>
                  {cultivoList.map((item) => (
                    <option key={item.idcultivo} value={item.idcultivo}>
                      {item.cultivo}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="col-sm-12 col-md-3 my-2">
              <input
                type="date"
                value={form.fecha}
                className="form-control"
                onChange={(value) => {
                  setForm({
                    ...form,
                    fecha: value.target.value,
                  });
                }}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <button
                className="btn btn-success my-2"
                onClick={() => validateData()}
              >
                <FontAwesomeIcon icon="fas fa-save" /> Guardar
              </button>{" "}
              {notificationStatus !== 1 && (
                <button
                  className="btn btn-outline-success my-2"
                  onClick={() => sendNotifications()}
                >
                  <FontAwesomeIcon icon="fab fa-whatsapp" />
                </button>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 table-responsive">
              {tableContent.length > 0 && (
                <table className="table">
                  <thead>
                    <tr>
                      <th>Variedad</th>
                      <th>Prod/Ha.</th>
                      <th>Total del día</th>
                      {tamanoList?.map((tamano) => (
                        <th key={tamano.tamano}>{`%${tamano.tamano}`}</th>
                      ))}
                      <th>{totalDia}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableContent?.map((item, variedadIndex) => (
                      <tr key={item.id_variedad}>
                        <td>{item.variedad}</td>
                        <td>
                          {parseFloat(item.produccioin_hectarea).toFixed(0)}
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            value={item.total_dia}
                            onChange={(e) =>
                              handleTotalDiaChange(variedadIndex, e)
                            }
                            style={{ minWidth: "100px" }}
                          />
                        </td>
                        {item.tamanos.map((tamano, tamanoIndex) => (
                          <td key={tamano.tamano}>
                            <input
                              value={tamano.valor}
                              className="form-control"
                              onWheel={(e) => e.target.blur()}
                              onChange={(e) => {
                                handleTamañoChange(
                                  variedadIndex,
                                  tamanoIndex,
                                  e
                                );
                              }}
                              type="number"
                              min="0"
                              style={{ minWidth: "100px" }}
                            />
                            <label>
                              {parseFloat(
                                ((item.total_dia !== ""
                                  ? parseFloat(item.total_dia)
                                  : 0) *
                                  (tamano.valor !== ""
                                    ? parseFloat(tamano.valor)
                                    : 0)) /
                                  100
                              ).toFixed(0)}
                            </label>
                          </td>
                        ))}
                        <td>{item.porcentaje_total}%</td>
                        <td>
                          <button
                            className="btn btn-outline-dark"
                            onClick={() => {
                              openBitacora(item.id_variedad);
                            }}
                          >
                            <FontAwesomeIcon icon="fas fa-book" />
                          </button>{" "}
                          {item.id_diaria > 0 && (
                            <button
                              onClick={() => deleteProyeccion(item.id_variedad)}
                              className="btn btn-outline-danger"
                            >
                              <FontAwesomeIcon icon="fas fa-trash-alt" />
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <Modal
        isOpen={modalIsVisible}
        size="xl"
        toggle={() => {
          setModalIsVisible(false);
        }}
      >
        <ModalHeader
          toggle={() => {
            setModalIsVisible(false);
          }}
        >
          Bitácora
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Temporada</th>
                  <th scope="col">Campo</th>
                  <th scope="col">Variedad</th>
                  <th scope="col">Cantidad</th>
                  <th scope="col">Usuario</th>
                  <th scope="col">Fecha</th>
                </tr>
              </thead>
              <tbody>
                {bitacoraContent?.map((item) => (
                  <tr key={item.id_bitacora}>
                    <td>{item.temporada}</td>
                    <td>{item.campo}</td>
                    <td>{item.variedad}</td>
                    <td>{item.cantidad}</td>
                    <td>{item.usuario}</td>
                    <td>
                      {moment(item.fecha_registro).locale("es-mx").format("LL")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ProyeccionDiaria;
