import LoginRouter from './Vistas/Router/Loginrouter';
import {AppProvider} from './Context/UseAuth'


function App() {

  return (
    <AppProvider>
      <LoginRouter/>
    </AppProvider>
  )
}

export default App;