import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import { Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../../Component/axios";
import "../../CSS/configuracion.css";
const MySwal = withReactContent(Swal);
library.add(fab, fas, far);

const RelacionarCultivoLote = () => {
  const [campoList, setCampoList] = useState([]);
  const [loteList, setLoteList] = useState([]);
  const [cultivoList, setCultivoList] = useState([]);
  const [variedadList, setVariedadList] = useState([]);

  const [selectedCampo, setSelectedCampo] = useState("");
  const [idcampo, setIdCampo] = useState(0);
  const [acronimoCampo, setAcronimoCampo] = useState("");

  const [showLoteInfo, setShowLoteInfo] = useState(false);
  const [codigoLote, setCodigoLote] = useState("");
  const [superficie, setSuperficie] = useState("");
  const [idlote, setIdLote] = useState(0);
  const [selectedCultivo, setSelectedCultivo] = useState("Cultivo...");
  const [selectedVariedad, setSelectedVariedad] = useState("Seleccionar...");

  const [idVariedad, setIdVariedad] = useState(0);

  const [showEditModal, setShowEditModal] = useState(false);

  const [editLote, setEditLote] = useState("");
  const [edtiSuperficie, setEditSuperficie] = useState("");
  const [editCultivo, setEditoCultivo] = useState("");
  const [editVariedad, setEditVariedad] = useState("");
  const [editIdVariedad, setEditIdVariedad] = useState(0);

  const CampoChangeHandler = (e) => {
    if (e.target.value === "Seleccionar campo...") {
      setSelectedCampo("Seleccionar campo...");
      setShowLoteInfo(false);
      return;
    }

    const xcampo = campoList.filter((item) => item.campo === e.target.value);
    setSelectedCampo(xcampo[0].campo);
    setIdCampo(xcampo[0].idcampo);
    getLoteList(xcampo[0].idcampo);
  };

  const cultivoChangeHandler = (e) => {
    if (e.target.value === "Cultivo...") {
      setSelectedCultivo("Cultivo...");
      setVariedadList([]);
      return;
    }

    const xcultivo = cultivoList.filter(
      (item) => item.cultivo === e.target.value
    );
    setSelectedCultivo(xcultivo[0].cultivo);
    getVariedadList(xcultivo[0].idcultivo);
  };

  const getVariedadList = (idcultivo) => {
    api
      .post("api/cultivo/all_tipo_cultivo", {
        data: {
          idcultivo: idcultivo,
        },
      })
      .then((response) => {
        setVariedadList(response.data);
        if (editVariedad !== "") {
          const xid = response.data.filter((item) => item.tipo == editVariedad);
          setEditIdVariedad(xid[0].idtipo);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const variedadChangeHandler = (e) => {
    if (e.target.value === "Seleccionar...") {
      setSelectedVariedad("Seleccionar...");
      return;
    }
    const xvariedad = variedadList.filter(
      (item) => item.tipo == e.target.value
    );
    setSelectedVariedad(xvariedad[0].tipo);
    setIdVariedad(xvariedad[0].idtipo);
  };

  const getLoteList = (id) => {
    api
      .post("api/cultivo/get/info_lote/por_campo", {
        data: {
          idcampo: id,
        },
      })
      .then((response) => {
        setLoteList(response.data);
        setAcronimoCampo(response.data[0].acronimo_campo);
        setShowLoteInfo(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const guardarButtonActioin = async () => {
    if (
      idcampo === 0 ||
      idVariedad === 0 ||
      codigoLote.trim() === "" ||
      superficie === "" ||
      superficie === 0
    ) {
      MySwal.fire({
        position: "center",
        icon: "warning",
        title: "Favor de llenar todos los campos",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    const user = await localStorage.getItem("@usuario");
    const json_user = JSON.parse(user);
    api
      .post("api/cultivo/new_lote/info_lote", {
        data: {
          idcampo: idcampo,
          idtipo: idVariedad,
          codigo_lote: codigoLote,
          superficie: parseFloat(superficie),
          idusuario: json_user.id,
        },
      })
      .then((response) => {
        MySwal.fire({
          position: "center",
          icon: "success",
          title: "Registro guardado",
          showConfirmButton: false,
          timer: 1500,
        });
        getLoteList(idcampo);
        setCodigoLote("");
        setSuperficie("");
      })
      .catch((error) => {
        MySwal.fire({
          position: "center",
          icon: "error",
          title: "Oh oh, algo salio mal!",
          text:
            error +
            " Favor de contactar al equipo de desarrollo para solucionar el problema",
        });
      });
  };

  const EditLote = (e) => {
    setIdLote(loteList[0].lote[e].idlote);
    setEditSuperficie(loteList[0].lote[e].superficie);
    setEditLote(loteList[0].lote[e].codigo_lote);
    setEditVariedad(loteList[0].lote[e].tipo_cultivo);
    //setEditIdVariedad(loteList[0].lote[e].tipo_cultivo)
    setEditoCultivo(loteList[0].lote[e].cultivo);
    const idtipo = cultivoList.filter(
      (item) => item.cultivo == loteList[0].lote[e].cultivo
    );
    getVariedadList(idtipo[0].idcultivo);
    setShowEditModal(true);
  };

  /*Metodo para editar la informacion de un lote */
  const pushEditLote = () => {
    if (
      idlote === 0 ||
      editLote === "" ||
      edtiSuperficie === "" ||
      editIdVariedad === 0
    ) {
      MySwal.fire({
        position: "center",
        icon: "warning",
        title: "Favor de llenar todos los campos",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    api
      .post("api/cultivo/edit/exist_lote/info_lote", {
        data: {
          idlote: idlote,
          codigo_lote: editLote,
          superficie: parseFloat(edtiSuperficie),
          idtipo: editIdVariedad,
        },
      })
      .then((response) => {
        MySwal.fire({
          position: "center",
          icon: "success",
          title: "Registro guardado",
          showConfirmButton: false,
          timer: 1500,
        });

        setIdLote(0);
        setSuperficie(0);
        setCodigoLote(0);
        getLoteList(idcampo);
        setVariedadList([]);
        setShowEditModal(false);
      })
      .catch((error) => {
        console.log(error);
        MySwal.fire({
          position: "center",
          icon: "error",
          title: "Error al guardar los datos",
          text:
            error +
            " Favor de contactar al equipo de desarrollo para solucionar el problema",
        });
      });
  };

  const closeEditModalLote = () => {
    setIdLote(0);
    setSuperficie(0);
    setCodigoLote("");
    setShowEditModal(false);
  };

  const AlertDelLote = (e) => {
    MySwal.fire({
      icon: "warning",
      title: "¿Desea eliminar el registro?",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        DelLote(e);
      }
    });
  };

  const DelLote = (e) => {
    api
      .post("api/cultivo/delete/exist_lote/info_lote", {
        data: {
          idlote: loteList[0].lote[e].idlote,
        },
      })
      .then((reponse) => {
        MySwal.fire("Registro eliminado", "", "success");
        getLoteList(idcampo);
      })
      .catch((error) => {
        MySwal.fire({
          icon: "error",
          title: "Error al eliminar",
          text:
            error +
            " Contacte con el equipo de desarrollo para solucionar el problema",
        });
      });
  };

  useEffect(() => {
    const getCampo = () => {
      api
        .get("api/permisos/obtener/all-campos")
        .then((response) => {
          setCampoList(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const GetCultivo = () => {
      api
        .get("api/cultivo/get/cultivo_all")
        .then((response) => {
          setCultivoList(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    GetCultivo();
    getCampo();
  }, []);

  return (
    <Card className="my-2" style={{ height: "90vh" }}>
      <CardHeader style={{ fontWeight: "bold", textAlign: "center" }}>
        Agregar nuevo lote
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={2}>
            <label>Campo</label>
            <select
              className="form-select"
              value={selectedCampo}
              onChange={(e) => CampoChangeHandler(e)}
              name="campo"
              id="campo"
            >
              <option value={"Seleccionar campo..."}>
                Seleccionar campo...
              </option>
              {campoList.map((item, i) => (
                <option key={item.campo} value={item.campo}>
                  {item.campo}
                </option>
              ))}
            </select>
          </Col>
          {showLoteInfo && (
            <Col md={9}>
              <Row>
                <Col md={10}>
                  <Row>
                    <Col md={3} sm={12}>
                      <label>Lote</label>
                      <input
                        placeholder="Código lote"
                        type="text"
                        className="form-control"
                        name="codigo-lote"
                        id="codigo-lote"
                        onChange={(e) => setCodigoLote(e.target.value)}
                        value={codigoLote}
                      />
                    </Col>
                    <Col md={3} sm={12}>
                      <label>Superficie</label>
                      <input
                        placeholder="Superficie"
                        type="number"
                        className="form-control"
                        min="0"
                        name="superficie"
                        id="superficie"
                        onChange={(e) => setSuperficie(e.target.value)}
                        value={superficie}
                      />
                    </Col>
                    <Col md={3} sm={12}>
                      <label>Cultivo</label>
                      <select
                        className="form-select"
                        onChange={(e) => cultivoChangeHandler(e)}
                        value={selectedCultivo}
                      >
                        <option value="Cultivo...">Seleccionar...</option>
                        {cultivoList.map((item, i) => (
                          <option key={item.idcultivo} value={item.cultivo}>
                            {item.cultivo}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col md={3} sm={12}>
                      <label>Variedad</label>
                      <select
                        className="form-select"
                        onChange={(e) => variedadChangeHandler(e)}
                      >
                        <option value="Seleccionar...">Seleccionar...</option>
                        {variedadList.map((item, i) => (
                          <option key={item.idtipo} value={item.tipo}>
                            {item.tipo}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                </Col>
                <Col md={2} sm={12}>
                  <div className="d-grid">
                    <button
                      className="btn btn-success button-label-margin"
                      onClick={(e) => guardarButtonActioin()}
                    >
                      <FontAwesomeIcon icon="fa-solid fa-floppy-disk" /> Guardar
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
        <br />
        {showLoteInfo && (
          <Row>
            <Col
              md={12}
              className="table-responsive"
              style={{ maxHeight: "550px" }}
            >
              <table className="table table-condensed">
                <thead>
                  <tr>
                    <th>Campo</th>
                    <th>Código Campo</th>
                    <th>Código Lote</th>
                    <th>Superficie</th>
                    <th>Cultivo</th>
                    <th>Variedad</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {loteList[0].lote.map((item, i) => (
                    <tr>
                      <td>{selectedCampo}</td>
                      <td>{acronimoCampo}</td>
                      <td key={item.codigo_lote}>{item.codigo_lote}</td>
                      <td key={item.idlote}>{item.superficie}</td>
                      <td key={i}>{item.cultivo}</td>
                      <td>{item.tipo_cultivo}</td>
                      <td>
                        <button
                          className="btn btn-danger btn-block btn-sm"
                          onClick={() => AlertDelLote(i)}
                        >
                          <i className="trash">
                            <FontAwesomeIcon icon="fa-solid fa-trash" />
                          </i>
                        </button>
                        <button
                          className="btn btn-primary btn-block btn-sm"
                          onClick={() => EditLote(i)}
                        >
                          <i className="edit">
                            <FontAwesomeIcon icon="fa-solid fa-pen" />
                          </i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          </Row>
        )}
      </CardBody>

      <Modal isOpen={showEditModal} toggle={() => closeEditModalLote()}>
        <ModalHeader toggle={() => closeEditModalLote()}>
          Editar información lote
        </ModalHeader>
        <ModalBody>
          <span>Código lote</span>
          <input
            className="form-control"
            placeholder="Lote"
            name="codigo_lote-edit"
            id="codigo_lote-edit"
            onChange={(e) => {
              setEditLote(e.target.value);
            }}
            value={editLote}
          />
          <br />
          <span>Superficie</span>
          <input
            type="numbre"
            className="form-control"
            min="0"
            name="superficie-edit"
            id="superficie-edit"
            placeholder="Superficie"
            onChange={(e) => {
              setEditSuperficie(e.target.value);
            }}
            value={edtiSuperficie}
          />
          <br />
          <span>Cultivo</span>
          <select
            className="form-select"
            onChange={(e) => cultivoChangeHandler(e)}
            value={editCultivo}
          >
            <option></option>
            {cultivoList.map((item, i) => (
              <option key={item.idcultivo} value={item.cultivo}>
                {item.cultivo}
              </option>
            ))}
          </select>
          <br />
          <span>Variedad</span>
          <select
            value={editVariedad}
            className="form-select"
            onChange={(e) => {
              const xvariedad = variedadList.filter(
                (item) => item.tipo == e.target.value
              );
              setEditIdVariedad(xvariedad[0].idtipo);
              setEditVariedad(xvariedad[0].tipo);
            }}
          >
            <option></option>
            {variedadList.map((item, i) => (
              <option key={item.idtipo} value={item.tipo}>
                {item.tipo}
              </option>
            ))}
          </select>
          <br />
          <button className="btn btn-success" onClick={() => pushEditLote()}>
            <FontAwesomeIcon icon="fa-solid fa-pen" /> Guardar
          </button>
        </ModalBody>
      </Modal>
    </Card>
  );
};

export default RelacionarCultivoLote;
