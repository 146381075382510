import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "react-bootstrap";
import { Card, CardBody, CardHeader } from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../../CSS/configuracion.css";
import api from "../../Component/axios";
const MySwal = withReactContent(Swal);

const Etiquetas = () => {
  const [tipoEtiqueta, setTipoEtiqueta] = useState([]);
  const [tamanoEnvaseList, setTamanoEnvaseList] = useState([]);
  const [envaseList, setEnvaseList] = useState([]);
  const [etiqueta, setEtiqueta] = useState([]);
  const [bultosPorPalet, setBultosPorPalet] = useState([]);

  const [data, setData] = useState({
    tipo_etiqueta: 0,
    tamano_envase: 0,
    envase: 0,
  });

  const resetEtiquetaSelect = () => {
    let arr = [...etiqueta];
    etiqueta.forEach((item, i) => {
      arr[i].estatus = false;
    });
    setEtiqueta(arr);
  };

  const resetBultosPorPaletSelect = () => {
    let arr = [...bultosPorPalet];
    bultosPorPalet.forEach((item, i) => {
      arr[i].estatus = false;
    });
    setBultosPorPalet(arr);
  };

  const tipoEtiquetaChangeHandler = (id_tipo_etiqueta) => {
    if (parseInt(id_tipo_etiqueta) === 0) {
      setData({
        ...data,
        tipo_etiqueta: 0,
        tamano_envase: 0,
        envase: 0,
      });
      resetEtiquetaSelect();
      resetBultosPorPaletSelect();
      setEnvaseList([]);
      return;
    }

    setData({
      ...data,
      tipo_etiqueta: id_tipo_etiqueta,
    });
    if (parseInt(data.envase) !== 0) {
      api
        .post("api/etiquetas/relacion-etiqueta-envase", {
          data: {
            idenvase: data.envase,
            idtipo_etiqueta: data.tipo_etiqueta,
          },
        })
        .then((response) => {});
    }
  };

  const TamanoEnvaseEventChangeHandler = (id_tamano_envase) => {
    setData({
      ...data,
      tamano_envase: id_tamano_envase,
      envase: 0,
    });

    resetBultosPorPaletSelect();
    resetEtiquetaSelect();

    if (id_tamano_envase === 0) {
      setEnvaseList([]);
      return;
    }

    api
      .post("api/etiquetas/requerir/todos-envase", {
        data: id_tamano_envase,
      })
      .then((response) => {
        setEnvaseList(response.data);
      });
  };

  const EnvaseChangeHandler = (id_envase, envase) => {
    setData({
      ...data,
      envase: id_envase,
    });
    if (parseInt(id_envase) === 0) {
      resetEtiquetaSelect();
      resetBultosPorPaletSelect();
      return;
    }
    api
      .post("api/etiquetas/relacion-etiqueta-envase", {
        data: { idenvase: id_envase, idtipo_etiqueta: data.tipo_etiqueta },
      })
      .then((response) => {
        let arr = [...etiqueta];
        etiqueta.forEach((item, i) => {
          arr[i].estatus = false;
          response.data.forEach((value) => {
            if (item.etiqueta === value.etiqueta) {
              arr[i].estatus = true;
            }
          });
        });
        setEtiqueta(arr);
      });

    api
      .post("api/etiquetas/bultos_por_palet", {
        data: id_envase,
      })
      .then((response) => {
        setBultosPorPalet(response.data);
      });
  };

  const EtiquetaEventChangeHandler = (e) => {
    let arr = [...etiqueta];
    arr[e.target.value].estatus = e.target.checked;
    setEtiqueta(arr);
  };

  const pushRelacionEtiquetas = () => {
    if (
      data.tipo_etiqueta === 0 ||
      data.envase === 0 ||
      data.tamano_envase === 0
    ) {
      Swal.fire({
        icon: "info",
        title: "Favor de llenar todos los campos",
      });
      return;
    }
    api
      .post("api/etiquetas/insert-or-update/relacion", {
        data: {
          data,
          etiqueta: etiqueta.filter((item) => item.estatus === true),
        },
      })
      .then((response) => {
        MySwal.fire({
          icon: "success",
          title: "Registros guardados",
          timer: 1500,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        MySwal.fire({
          icon: "error",
          title: error,
          text: "Favor de contactar con el equipo de desarrollo para solucionar el problema",
        });
      });

    api.put("api/etiquetas/bultos_por_palet", {
      data: {
        id_envase: data.envase /*selectedEnvase*/,
        bultos_por_palet: bultosPorPalet.filter(
          (item) => item.estatus === true
        ),
      },
    });
  };

  const bultosPorPaletHandleChange = (event) => {
    let palets = [...bultosPorPalet];
    const index = bultosPorPalet.findIndex(
      (item) => item.id_palet === parseInt(event.target.value)
    );
    palets[index].estatus = event.target.checked;
    setBultosPorPalet(palets);
  };

  useEffect(() => {
    const getEtiqueta = () => {
      api
        .get("api/etiquetas/get/all-etiqueta")
        .then((response) => {
          setEtiqueta(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getTipoEtiqueta = () => {
      api
        .get("api/etiquetas/obtener/all-tipo-etiqueta")
        .then((response) => {
          setTipoEtiqueta(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getTamanoEnvase = () => {
      api
        .get("api/etiquetas/obt/tamano-envase")
        .then((response) => {
          setTamanoEnvaseList(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getBultosPorPalet = () => {
      api
        .get("api/etiquetas/bultos_por_palet")
        .then((response) => {
          setBultosPorPalet(response.data);
        })
        .catch((error) => {
          console.log(error.data);
        });
    };

    getTipoEtiqueta();
    getEtiqueta();
    getTamanoEnvase();
    getBultosPorPalet();
  }, []);

  return (
    <Card className="my-2" style={{ height: "90vh" }}>
      <CardHeader style={{ fontWeight: "bold", textAlign: "center" }}>
        Configuración de etiquetas
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={4} sm={12}>
            <div className="form-container-etiquetas">
              <span>Tipo etiqueta: </span>
              <select
                className="form-select"
                onChange={(e) => tipoEtiquetaChangeHandler(e.target.value)}
                value={data.tipo_etiqueta}
              >
                <option value={0}>Seleccionar...</option>
                {tipoEtiqueta.map((item, i) => (
                  <option
                    key={item.idtipo_etiqueta}
                    value={item.idtipo_etiqueta}
                  >
                    {item.etiqueta}
                  </option>
                ))}
              </select>
              <span>Tamaño envase: </span>
              <select
                className="form-select"
                id="tamano-envase-select"
                onChange={(e) => TamanoEnvaseEventChangeHandler(e.target.value)}
                value={data.tamano_envase}
              >
                <option value={0}>Seleccionar...</option>
                {tamanoEnvaseList.map((item, i) => (
                  <option
                    key={item.idtamano_envace}
                    value={item.idtamano_envase}
                  >
                    {item.tamano}
                  </option>
                ))}
              </select>
              <span>Envase: </span>
              <select
                className="form-select"
                id="envase-select"
                onChange={(e) => EnvaseChangeHandler(e.target.value)}
                value={data.envase}
              >
                <option value={0}>Seleccionar...</option>
                {envaseList.map((item) => (
                  <option key={item.id_envase} value={item.id_envase}>
                    {item.envase}
                  </option>
                ))}
              </select>
              <button
                className="btn btn-success"
                style={{ marginBottom: "25px" }}
                onClick={() => pushRelacionEtiquetas()}
              >
                <i>
                  <FontAwesomeIcon icon="fa-solid fa-floppy-disk" />
                </i>{" "}
                Guardar
              </button>
            </div>
          </Col>
          <Col md={8} sm={12}>
            <Row>
              <Col>
                <div className="etiquetas-container">
                  {etiqueta.map((item, i) => (
                    <div className="form-check" key={item.idetiqueta}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={i}
                        id={item.etiqueta}
                        onChange={(e) => EtiquetaEventChangeHandler(e)}
                        checked={item.estatus}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        {item.etiqueta}
                      </label>
                    </div>
                  ))}
                </div>
              </Col>
              <Col>
                <div className="etiquetas-container">
                  {bultosPorPalet.map((item, i) => (
                    <div className="form-check" key={item.id_palet}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={item.id_palet}
                        checked={item.estatus}
                        onChange={(event) => bultosPorPaletHandleChange(event)}
                      />
                      <label className="form-check-label">{item.palet}</label>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Etiquetas;
