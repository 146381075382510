import React, { useState } from "react";
import { useFormik } from "formik";
import { string, object } from "yup";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import moment from "moment";
import api from "../../Component/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const initialValues = {
  inicio: moment().format("YYYY-MM-DD"),
  fin: moment().format("YYYY-MM-DD"),
};

const ProduccionDiariaGeneral = () => {
  const [loading, setLoading] = useState(false);
  const [campos, setCampos] = useState([]);
  const [variedades, setVariedades] = useState([]);
  const [proyeccion, setProyeccion] = useState([]);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await api.get(
        `api/proyeccion-diaria/reporte?inicio=${values.inicio}&fin=${values.fin}`
      );
      console.log(response.data);
      setCampos(response.data.campos);
      setVariedades(response.data.variedades);
      setProyeccion(response.data.proyeccion);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const { values, errors, touched, handleSubmit, handleChange } = useFormik({
    onSubmit,
    initialValues,
    validationSchema,
  });

  return (
    <Card className="my-2" style={{ height: "90vh" }}>
      <CardHeader style={{ textAlign: "center" }}>
        <b>Reporte de proyección diaria por campo</b>
      </CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={3}>
              <label>Fecha inicio</label>
              <input
                className="form-control"
                value={values.inicio}
                type="date"
                name="inicio"
                onChange={handleChange}
                style={
                  (errors.inicio && touched.inicio) !== undefined
                    ? styles.error
                    : {}
                }
              />
            </Col>
            <Col md={3}>
              <label>Fecha fin</label>
              <input
                className="form-control"
                value={values.fin}
                type="date"
                name="fin"
                onChange={handleChange}
                style={
                  (errors.fin && touched.fin) !== undefined ? styles.error : {}
                }
              />
            </Col>
            <Col md={4}>
              <button
                type="sumbit"
                className="btn btn-primary btn-block"
                style={{ marginTop: "25px" }}
                disabled={loading}
              >
                {loading ? (
                  <FontAwesomeIcon icon="fa-solid fa-spinner" spin />
                ) : (
                  "Buscar"
                )}
              </button>
            </Col>
          </Row>
        </form>
        <br />
        <Row>
          {proyeccion.length > 0 && (
            <Col
              className="table-responsive"
              style={{ margin: "20px", overflow: "scroll", height: "70vh" }}
            >
              <table className="table table-bordered table-striped table-condensed table-sm">
                <thead>
                  <tr>
                    <th>Variedad</th>
                    {campos.map((item) => (
                      <th key={item}>{item}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {variedades.map((variedad) => (
                    <tr key={variedad}>
                      <td>{variedad}</td>
                      {campos.map((campo) => {
                        const current_proyeccion = proyeccion.find(
                          (item) =>
                            item.variedad === variedad && item.campo === campo
                        );

                        if (current_proyeccion) {
                          return <td>{current_proyeccion.total}</td>;
                        }
                        return <td>0</td>;
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

const validationSchema = object().shape({
  inicio: string().required(),
  fin: string().required(),
});

const styles = {
  error: {
    borderColor: "red",
  },
};

export default ProduccionDiariaGeneral;
