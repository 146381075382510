import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  user: Yup.string().required("Favor de ingresar usuario"),
  contra: Yup.string().required("Favor de ingresar contraseña"),
});

export const userValidationSchema = Yup.object().shape({
  username: Yup.string().trim().required("Ingresa el nombre de usuario"),
  firstName: Yup.string().trim().required("Ingresa el nombre"),
  lastName: Yup.string().trim().required("Ingresa el apellido"),
  campos: Yup.array()
    .of(Yup.string())
    .min(1, "Selecciona al menos 1 campo")
    .required("Selecciona al menos 1 campo"),
  password: Yup.string().required("Ingresa la contraseña"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Las contraseñas deben coincidir")
    .required("Confirma la contraseña"),
});

export const userSelectValidationSchema = Yup.object().shape({
  user: Yup.string().required("Selecciona a un usuario"),
});

export const notificationValidationSchema = Yup.object().shape({
  nombre: Yup.string().trim().required("Campo obligatorio"),
  numero: Yup.string().trim().required(),
  tipo: Yup.string().oneOf(["ambas", "empaque", "diaria"]).required(),
  id_campo: Yup.number()
    .min(1, "Favor de seleccionar un campo")
    .required("Campo obligatorio"),
});
