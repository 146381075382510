import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "react-bootstrap";
import { Card, CardBody, CardHeader } from "reactstrap";
import "../../CSS/configuracion.css";
import api from "../../Component/axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const validateInput = (inputValue) => {
  const nonAlphabetPattern = /^[^0-9]*$/;
  return nonAlphabetPattern.test(inputValue);
};

const returnClassState = (estatus) => {
  return estatus === 0 ? "btn btn-outline-success" : "btn btn-outline-danger";
};

const Palet = () => {
  const [paletList, setPaletList] = useState([]);
  const [newPalet, setNewPalet] = useState("");

  const getPalet = () => {
    const token = localStorage.getItem("@Token");
    api
      .get("api/empaque/estiba/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        setPaletList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const inserNewPalet = () => {
    const token = localStorage.getItem("@Token");
    if (newPalet === "" || validateInput(newPalet)) {
      MySwal.fire({
        icon: "info",
        title: "Favor de llenar todos los campos",
        timer: 1500,
      });
      return;
    }
    api
      .post(
        "api/empaque/estiba/",
        {
          data: { palet: newPalet },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + token,
          },
        }
      )
      .then((response) => {
        MySwal.fire({
          icon: "success",
          title: "Datos guardados",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        if (error.response.status === 400) {
          MySwal.fire({
            icon: "error",
            title: error.response.data.msg,
          });
        } else {
          MySwal.fire({
            icon: "error",
            title: error,
            text: "Favor de contactar con el equipo de desarrollo para solucionar el problema",
          });
        }
      })
      .finally(() => {
        setNewPalet("");
        getPalet();
      });
  };

  const editPalet = (id_palet) => {
    const token = localStorage.getItem("@Token");
    const palet_selected = paletList.find((item) => item.idpalet === id_palet);
    MySwal.fire({
      title: "Modificar estiba",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
        value: palet_selected.palet,
      },
      showCancelButton: true,
      confirmButtonText: "Guardar",
      showLoaderOnConfirm: true,
      preConfirm: async (palet) => {
        try {
          if (palet === "" || validateInput(palet)) {
            throw new Error("Favor de llenar los campos");
          }
          const response = await api.put(
            "api/empaque/estiba/",
            {
              data: {
                id_palet: id_palet,
                palet: palet,
              },
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + token,
              },
            }
          );
          return response;
        } catch (error) {
          Swal.showValidationMessage(`Error - ${error}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Datos guardados",
        });
        getPalet();
      }
    });
  };

  const deletePalet = (id_palet, estatus) => {
    const token = localStorage.getItem("@Token");
    const accion = estatus === 1 ? "desactivar" : "activar";
    const accionResponse = estatus === 1 ? "desactivado" : "activado";
    MySwal.fire({
      icon: "warning",
      title: `¿Está seguro que desea ${accion} la estiba?`,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: estatus === 0 ? "Activar" : "Eliminar",
      confirmButtonColor: estatus === 1 ? "#dc3545" : "#28a745",
      cancelButtonColor: "#007bff",
      showLoaderOnConfirm: true,
      backdrop: true,
      preConfirm: async () => {
        try {
          const response = await api.delete(
            `api/empaque/estiba/?id_palet=${id_palet}&estatus=${
              estatus === 0 ? 1 : 0
            }`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + token,
              },
            }
          );
          return response;
        } catch {
          Swal.showValidationMessage(
            `Error - Favor de comunicarlo con el equipo de desarrollo`
          );
          return;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        getPalet();
        MySwal.fire({
          icon: "success",
          title: `Registro ${accionResponse}`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };

  useEffect(() => {
    getPalet();
  }, []);

  return (
    <Card className="my-2" style={{ height: "90vh" }}>
      <CardHeader
        style={{
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Agregar nueva estiba
      </CardHeader>
      <CardBody className="container">
        <Row>
          <Col md={6} xs={12} sm={12} lg={4}>
            <input
              type="number"
              min="0"
              className="form-control"
              placeholder="Estiba"
              value={newPalet}
              onChange={(e) => setNewPalet(e.target.value)}
            />
          </Col>
          <Col md={6} xs={12} sm={12} lg={4} className="d-grid">
            <button className="btn btn-primary" onClick={() => inserNewPalet()}>
              <i>
                <FontAwesomeIcon icon="fa-solid fa-plus" />
              </i>{" "}
              Agregar
            </button>
          </Col>
        </Row>
        <br />
        <Row>
          <Col style={{ overflow: "auto", height: "70vh" }}>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Palet</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {paletList.map((item, i) => (
                    <tr key={item.idpalet}>
                      <th>{item.palet}</th>
                      <td>
                        <button
                          className="btn btn-outline-primary"
                          onClick={() => editPalet(item.idpalet)}
                        >
                          <FontAwesomeIcon icon="fa-solid fa-pencil" />
                        </button>{" "}
                        <button
                          className={returnClassState(item.estatus)}
                          onClick={() =>
                            deletePalet(item.idpalet, item.estatus)
                          }
                        >
                          <FontAwesomeIcon icon="fas fa-power-off" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Palet;
