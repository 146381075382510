/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import moment from "moment";
import api from "../../Component/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "../../Component/Tooltip";
import Swal from "sweetalert2";
import { number } from "yup";
import axios from "axios";

const getSizeBtnStyle = (cantidad, seleccionado) => {
  // if (cantidad === 0) {
  //   return "btn btn-outline-success disabled";
  // }

  if (/*cantidad !== 0 &&*/ seleccionado === 1) {
    return "btn btn-outline-success active";
  }

  return "btn btn-outline-success";
};

const returnActiveButton = (estatus) => {
  if (estatus === 1) {
    return "btn btn-outline-success active";
  }
  return "btn btn-outline-success";
};

const HeaderModal = ({
  tamanos_list,
  tamanosClickHandler,
  variedades_list,
  variedadesClickHanler,
  editar,
  bicolor,
}) => {
  return (
    <>
      <Row>
        <Col className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                {tamanos_list?.map((tamano) => (
                  <th key={tamano.tamano}>
                    <button
                      className={getSizeBtnStyle(tamano.valor, tamano.selected)}
                      style={
                        editar.status && tamano.selected
                          ? {
                              backgroundColor: "#198754",
                              color: "white",
                              textAlign: "center",
                              width: "200px",
                            }
                          : { textAlign: "center", width: "200px" }
                      }
                      onClick={() => tamanosClickHandler(tamano.tamano)}
                      disabled={editar.status}
                    >
                      {tamano.tamano}
                      <br />
                      {tamano.valor}
                    </button>
                  </th>
                ))}
              </tr>
            </thead>
          </table>
        </Col>
      </Row>
      {bicolor && (
        <>
          <br />
          <Row>
            <Col>
              <label>Variedades</label>
              <hr />
              <Row>
                {variedades_list?.map((item, index) => (
                  <Col className="d-grid" key={item.id_variedad}>
                    <button
                      onClick={() => variedadesClickHanler(index)}
                      className={returnActiveButton(item.selected)}
                      disabled={item.valor === 0 || editar.status}
                      style={
                        editar.status && item.valor === 1
                          ? {
                              backgroundColor: "#198754",
                              color: "white",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }
                          : {
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }
                      }
                    >
                      <label>{item.variedad}</label>
                      <label>{item.valor}</label>
                    </button>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

const tomorrow = moment().add(1, "day").format("YYYY-MM-DD");
const TIPO_PEDIDO = [
  { id: 1, tipo: "Exacto", activo: 1 },
  { id: 2, tipo: "Aproximado", activo: 0 },
];

const YEGUERE = "yeguere";
const DESTINO = "destino";
const INMEDIATO = "inmediato";
const TIPO_PEDIDO_VALUE = "tipo_pedido";
const TARIMA = "tarima";

const PedidoEmpaque = () => {
  const [filter, setFilter] = useState({
    fecha: tomorrow,
    id_cultivo: 0,
    id_variedad: 0,
  });
  const [form, setForm] = useState({
    calibre: "",
    valor_calibre: 0,
    id_campo: 0,
    id_variedad: 0,
    variedad: "",
    resto: 0,
    resto_check: false,
    id_tipo_etiqueta: 0,
    id_peso_envase: 0,
    id_tipo_caja: 0,
    id_etiqueta: 0,
    bultos_por_palet: -1,
    otro_bultos_por_palet: false,
    cantidad_palet: "",
    fumigar: false,
    prioridad: false,
    observaciones: "",
    limite: "",
  });
  const [tipoEtiqueta, setTipoEtiqueta] = useState([]);
  const [pesoEnvaseList, setPesoEnvaseList] = useState([]);
  const [tipoCajaList, setTipoCajaList] = useState([]);
  const [etiquetaList, setEtiquetaList] = useState([]);
  const [yeguere, setYeguere] = useState([]);
  const [destino, setDestino] = useState([]);
  const [tarima, setTarima] = useState([]);
  const [tipoPedidoList, setTipoPedidoList] = useState([...TIPO_PEDIDO]);
  const [destinoInmediatoList, setDestinoInmediatoList] = useState([]);
  const [bultosPorPaletList, setBultosPorPaletList] = useState([]);
  const [cultivoList, setCultivoList] = useState([]);

  const [editar, setEditar] = useState({
    status: false,
    id_empaque: 0,
  });

  const [empaqueListShown, setEmpaqueListShown] = useState([]);
  const [tamanoList, setTamanoList] = useState([]);

  const [variedadList, setVariedadList] = useState([]);
  const [variedadOptions, setVariedadOptions] = useState([]);

  const [isVisible, setIsVisible] = useState(false);

  const [tamanoOptionModal, setTamanoOptionModal] = useState([]);
  const [variedadOptionsModal, setVariedadOptionsModal] = useState([]);
  const [variedadSeleccionadasLength, setVariedadSeleccioandasLength] =
    useState(0);
  const [variedadesCampoBicolorSelected, setVariedadesCampoBicolorSelected] =
    useState([]);
  const [bicolorFormAux, setBicolorFormAux] = useState({
    calibre: "",
    valor_calibre: 0,
  });
  const [bicolorForm, setBicolorForm] = useState([]);
  const [isBicolorActive, setIsBicolorActive] = useState(false);

  const [notificationNumbers, setNotificationNumbers] = useState([]);

  const getTamano = () => {
    api
      .post("api/cultivo/obtener-tamano-por-cultivo", {
        data: filter.id_cultivo,
      })
      .then((response) => {
        setTamanoList(response.data);
      });
  };

  const getPesoEnvase = () => {
    api
      .post("api/etiquetas/obt/tamano-envase", {
        data: filter.id_cultivo,
      })
      .then((response) => {
        setPesoEnvaseList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEmpaqueData = () => {
    Swal.showLoading();
    const user = localStorage.getItem("@usuario");
    const json_user = JSON.parse(user);
    let variedad = ``;
    if (parseInt(filter.id_variedad) !== 0) {
      variedad = `&id_variedad=${filter.id_variedad}`;
    }
    api
      .get(
        `api/pedido-empaque/proyeccion-diaria/pedido-empaque/?id_usuario=${json_user.id}&fecha=${filter.fecha}&id_cultivo=${filter.id_cultivo}${variedad}`
      )
      .then((response) => {
        setEmpaqueListShown(response.data);
        if (Swal.isLoading) {
          Swal.close();
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Algo salio mal!",
          text: "Favor de comunicarlo al equipo de desarrollo",
        });
      });
  };

  const openDetalleEmpaque = (campoIndex, variedadIndex) => {
    let arr = [...empaqueListShown];
    arr[campoIndex].variedades[variedadIndex].isVisible =
      !arr[campoIndex].variedades[variedadIndex].isVisible;
    setEmpaqueListShown(arr);
  };

  const unselectElements = () => {
    const tipo_etiqueta_arr = tipoEtiqueta.map((item) => ({
      ...item,
      activo: 0,
    }));
    setTipoEtiqueta(tipo_etiqueta_arr);
  };

  const sendNotification = (id_campo, estatus) => {
    const user = localStorage.getItem("@usuario");
    const json_user = JSON.parse(user);
    if (notificationNumbers.length === 0) {
      Swal.fire({
        icon: "info",
        title: "Favor de comunicarlo con el equipo de desarrollo",
        text: "Actualmente no existen números configurados, para el envío de notificaciones",
      });
      return;
    }
    const numbers = notificationNumbers.filter(
      (item) => item.id_campo === id_campo
    );
    if (numbers.length === 0) {
      Swal.fire({
        icon: "info",
        title: "Favor de comunicarlo con el equipo de desarrollo",
        text: "Actualmente no existen números configurados, para el envío de notificaciones",
      });
      return;
    }
    Swal.fire({
      icon: "info",
      title: "Se enviará una notificación",
      text: "¿Está seguro que desea enviar la notificación?",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: "#28a745",
      cancelButtonColor: "#dc3545",
      confirmButtonText: "Enviar",
      cancelButtonText: "Cancelar",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        let inicio = `Les informamos que ${json_user.nombre} ${json_user.apellido} ha terminado el pedido`;
        if (estatus === 2) {
          inicio = `${json_user.nombre} ${json_user.apellido} ha modificado el pedido`;
        }
        await numbers.reduce(async (promise, current) => {
          await promise;
          var data = JSON.stringify({
            token: process.env.REACT_APP_NOTIFICATION_TOKEN,
            to: current.number,
            body: `✉️ Notificación automática:

    ${inicio} del día 📅 ${moment(filter.fecha).format(
              "DD/MM/YYYY"
            )} y se encuentra disponible en la plataforma, favor de verificarlo ✅`,
          });

          var config = {
            method: "post",
            url: `https://api.ultramsg.com/${process.env.REACT_APP_NOTIFICATION_INSTANCE}/messages/chat`,
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          };
          try {
            await axios(config);
          } catch (error) {
            Swal.showValidationMessage(
              `Error - Favor de comunicarlo con el equipo de desarrollo`
            );
            return;
          }
        }, Promise.resolve());
      },
      allowOutsideClick: () => {
        return !Swal.isLoading();
      },
      backdrop: true,
    }).then((res) => {
      if (res.isConfirmed) {
        api
          .post("api/pedido-empaque/notificacion", {
            data: {
              id_campo: id_campo,
              fecha: filter.fecha,
              estatus: estatus,
            },
          })
          .then(() => {
            getEmpaqueData();
            Swal.fire({
              icon: "success",
              title: "Notificación enviada",
              timer: 1400,
            });
          });
      }
    });
  };

  /*------------------------Modal functions------------------------*/
  const openModal = (campoIndex, variedadIndex) => {
    let tamanos = [...tamanoList];
    let variedad_tamanos = [
      ...empaqueListShown[campoIndex].variedades[variedadIndex].tamanos,
    ];

    for (let item of tamanos) {
      for (let value of variedad_tamanos) {
        if (item.tamano === value.tamano) {
          item.valor = value.valor;
          break;
        }
      }
    }
    setTamanoOptionModal(tamanos);
    setForm({
      ...form,
      id_variedad:
        empaqueListShown[campoIndex].variedades[variedadIndex].id_variedad,
      variedad: empaqueListShown[campoIndex].variedades[variedadIndex].variedad,
      id_campo: empaqueListShown[campoIndex].id_campo,
    });
    setIsVisible(true);
  };

  const closeModal = () => {
    const tipo_pedido = TIPO_PEDIDO.map((item) => {
      if (item.tipo === "Exacto") {
        return {
          ...item,
          activo: 1,
        };
      }
      return {
        ...item,
        activo: 0,
      };
    });
    setTipoPedidoList(tipo_pedido);
    setTamanoOptionModal([]);
    setVariedadOptionsModal([]);
    setIsBicolorActive(false);
    setEditar({
      ...editar,
      status: false,
      id_empaque: 0,
    });
    let tamanos = tamanoList.map((item) => ({
      ...item,
      valor: 0,
      selected: 0,
    }));

    let tipo_etiqueta_list = tipoEtiqueta.map((item) => ({
      ...item,
      activo: 0,
    }));

    setTamanoList(tamanos);
    setTipoEtiqueta(tipo_etiqueta_list);
    setIsVisible(false);
    setForm({
      ...form,
      calibre: "",
      valor_calibre: 0,
      id_campo: 0,
      id_variedad: 0,
      variedad: "",
      resto: 0,
      resto_check: false,
      id_tipo_etiqueta: 0,
      id_peso_envase: 0,
      id_tipo_caja: 0,
      id_etiqueta: 0,
      bultos_por_palet: -1,
      otro_bultos_por_palet: false,
      cantidad_palet: "",
      fumigar: false,
      prioridad: false,
      observaciones: "",
      limite: "",
    });
    getEmpaqueElements();
  };

  const handleTamanoClick = (tamano) => {
    let tamanos = [...tamanoList];
    unselectElements();
    const index = tamanos.findIndex((item) => item.tamano === tamano);
    tamanos.forEach((item, i) => {
      tamanos[i].selected = 0;
    });
    tamanos[index].selected = 1;
    setTamanoOptionModal(tamanos);
    setTipoCajaList([]);
    setEtiquetaList([]);
    setBultosPorPaletList([]);
    setForm({
      ...form,
      calibre: tamanos[index].tamano,
      valor_calibre: tamanoList[index].valor,
      id_tipo_etiqueta: 0,
      bultos_por_palet: -1,
      otro_bultos_por_palet: false,
      id_peso_envase: 0,
      id_tipo_caja: 0,
      id_etiqueta: 0,
      cantidad_palet: 0,
    });
  };

  const handleTipoEtiquetaClickButton = (index) => {
    let tipo_etiqueta_arr = [...tipoEtiqueta];
    let peso_envase_arr = [...pesoEnvaseList];
    pesoEnvaseList.forEach((item, i) => {
      peso_envase_arr[i].estatus = 0;
    });
    tipoEtiqueta.forEach((item, i) => {
      tipo_etiqueta_arr[i].activo = 0;
    });
    tipo_etiqueta_arr[index].activo = 1;
    setTipoEtiqueta(tipo_etiqueta_arr);
    setPesoEnvaseList(peso_envase_arr);
    setEtiquetaList([]);
    setTipoCajaList([]);
    setTipoCajaList([]);
    setBultosPorPaletList([]);
    setForm({
      ...form,
      id_tipo_etiqueta: tipo_etiqueta_arr[index].idtipo_etiqueta,
      id_peso_envase: 0,
      id_tipo_caja: 0,
      id_etiqueta: 0,
      bultos_por_palet: -1,
      otro_bultos_por_palet: false,
      cantidad_palet: 0,
    });
  };

  const handlePesoEnvaseClickButton = (index) => {
    let peso_envase_arr = [...pesoEnvaseList];
    pesoEnvaseList.forEach((item, i) => {
      peso_envase_arr[i].estatus = 0;
    });
    peso_envase_arr[index].estatus = 1;
    setPesoEnvaseList(peso_envase_arr);
    setEtiquetaList([]);
    setBultosPorPaletList([]);

    setForm({
      ...form,
      id_peso_envase: pesoEnvaseList[index].idtamano_envase,
      id_tipo_caja: 0,
      id_etiqueta: 0,
      bultos_por_palet: -1,
      otro_bultos_por_palet: false,
      cantidad_palet: 0,
    });

    api
      .post("api/etiquetas/requerir/todos-envase", {
        data: pesoEnvaseList[index].idtamano_envase,
      })
      .then((response) => {
        setTipoCajaList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTipoCajaClickButton = (index) => {
    let tipo_caja_arr = [...tipoCajaList];
    tipoCajaList.forEach((item, i) => {
      tipo_caja_arr[i].estatus = 0;
    });
    tipo_caja_arr[index].estatus = 1;
    setTipoCajaList(tipo_caja_arr);
    setForm({
      ...form,
      id_tipo_caja: tipoCajaList[index].id_envase,
      id_etiqueta: 0,
      bultos_por_palet: -1,
      otro_bultos_por_palet: false,
      cantidad_palet: 0,
    });

    api
      .post("api/etiquetas/relacion-etiqueta-envase", {
        data: {
          idenvase: tipoCajaList[index].id_envase,
          idtipo_etiqueta: form.id_tipo_etiqueta,
        },
      })
      .then((response) => {
        setEtiquetaList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    api
      .post("api/etiquetas/relacion_envase_palets", {
        data: tipoCajaList[index].id_envase,
      })
      .then((response) => {
        setBultosPorPaletList(response.data);
      });
  };

  const handleEtiquetaClickButton = (index) => {
    let etiqueta_arr = [...etiquetaList];
    etiquetaList.forEach((item, i) => {
      etiqueta_arr[i].estatus = 0;
    });
    etiqueta_arr[index].estatus = 1;
    setForm({
      ...form,
      id_etiqueta: etiquetaList[index].id_etiqueta,
      cantidad_palet: 0,
    });
  };

  const handleBultosPorPaletClickButton = (index) => {
    let bultos_por_palet_arr = [...bultosPorPaletList];
    bultosPorPaletList.forEach((item, i) => {
      bultos_por_palet_arr[i].estatus = 0;
    });
    bultos_por_palet_arr[index].estatus = 1;
    setBultosPorPaletList(bultos_por_palet_arr);
    setForm({
      ...form,
      bultos_por_palet:
        bultosPorPaletList[index].id_palet === 0
          ? 0
          : bultosPorPaletList[index].palet,
      otro_bultos_por_palet: bultosPorPaletList[index].id_palet === 0,
      cantidad_palet: 0,
      resto: 0,
    });
  };

  const cantidadPaletChangeHandler = (e) => {
    let resto = 0;
    if (e.target.value !== "") {
      if (Number(form.bultos_por_palet) === 0) {
        Swal.fire({
          icon: "info",
          title: "Favor de especificar los bultos por palet",
        });
        return;
      }
      const cantidad =
        parseInt(e.target.value) * parseInt(form.bultos_por_palet);
      resto = form.valor_calibre - cantidad;

      // if (resto < 0) {
      //   Swal.fire({
      //     icon: "info",
      //     title: "El total de cajas excede el límite en el calibre",
      //   });
      //   setForm({
      //     ...form,
      //     cantidad_palet: 0,
      //     resto: 0,
      //   });
      //   return;
      // }

      if (isBicolorActive) {
        const cantidad_repartida = cantidad / variedadSeleccionadasLength;
        let bicolor_form = [...bicolorForm];
        for (let i in bicolorForm) {
          if (bicolorForm[i].proyeccion < cantidad_repartida) {
            Swal.fire({
              icon: "info",
              title: "El total de cajas excede el limite en el calibre",
            });
            setForm({
              ...form,
              cantidad_palet: 0,
              resto: 0,
            });
            return;
          }
          bicolor_form[i].resta = cantidad_repartida;
        }
        setBicolorForm(bicolor_form);
      }
    }
    setForm({
      ...form,
      cantidad_palet: e.target.value,
      resto: resto,
    });
  };

  const restoCheckHandler = (value) => {
    let cantidad_palet = form.cantidad_palet;
    let resto = form.resto;
    let tamano = tamanoOptionModal.find((item) => item.selected === 1);

    if (value.target.checked) {
      cantidad_palet = Math.ceil(tamano.valor / form.bultos_por_palet);
      resto = 0;
    }

    setForm({
      ...form,
      resto_check: value.target.checked,
      cantidad_palet: cantidad_palet,
      resto: resto,
    });
  };

  const getEmpaqueElements = () => {
    const token = localStorage.getItem("@Token");
    api
      .get("api/empaque/yegueres/?all=false", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        setYeguere(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    api
      .get(`api/empaque/destino/?all=false`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
      })
      .then((res) => {
        setDestino(res.data);
      })
      .catch((error) => {
        console.log(error);
      });

    api
      .get(`api/empaque/tarimas/?all=false`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
      })
      .then((res) => {
        setTarima(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    api.get("api/empaque/destino-inmediato").then((response) => {
      setDestinoInmediatoList(response.data);
    });
  };

  const etiquetasButtonHanldler = (tipo, index) => {
    switch (tipo) {
      case YEGUERE:
        let yeguere_arr = [...yeguere];
        yeguere.forEach((item, i) => {
          yeguere_arr[i].activo = 0;
        });
        yeguere_arr[index].activo = 1;
        setYeguere(yeguere_arr);
        setForm({
          ...form,
          id_yeguere: yeguere[index].idyeguere,
        });
        break;
      case DESTINO:
        let destino_arr = [...destino];
        destino.forEach((item, i) => {
          destino_arr[i].activo = 0;
        });
        destino_arr[index].activo = 1;
        setDestino(destino_arr);
        setForm({
          ...form,
          id_destino: destino[index].iddestino,
        });
        break;
      case INMEDIATO:
        let inmediato_arr = [...destinoInmediatoList];
        destinoInmediatoList.forEach((item, i) => {
          inmediato_arr[i].selected = 0;
        });
        inmediato_arr[index].selected = 1;
        setDestinoInmediatoList(inmediato_arr);
        setForm({
          ...form,
          id_destino_inmediato: destinoInmediatoList[index].id_destino,
        });
        break;
      case TIPO_PEDIDO_VALUE:
        let tipo_pedido_arr = [...tipoPedidoList];
        tipoPedidoList.forEach((item, i) => {
          tipo_pedido_arr[i].activo = 0;
        });
        tipo_pedido_arr[index].activo = 1;
        setTipoPedidoList(tipo_pedido_arr);
        setForm({
          ...form,
          tipo_pedido: tipoPedidoList[index].tipo,
        });
        break;
      case TARIMA:
        let tarima_arr = [...tarima];
        tarima.forEach((item, i) => {
          tarima_arr[i].activo = 0;
        });
        tarima_arr[index].activo = 1;
        setTarima(tarima_arr);
        setForm({
          ...form,
          tipo_pedido: tarima[index].idtarima,
        });
        break;
      default:
        break;
    }
  };

  const validateData = () => {
    if (
      form.calibre === "" ||
      form.id_tipo_etiqueta === 0 ||
      form.id_peso_envase === 0 ||
      form.id_tipo_caja === 0 ||
      form.id_etiqueta === 0 ||
      form.bultos_por_palet === -1 ||
      form.cantidad_palet === ""
    ) {
      Swal.fire({
        icon: "warning",
        title: "Favor de llenar todos los campos",
      });
      return;
    }

    if (form.resto_check === true && form.limite === "") {
      Swal.fire({
        icon: "warning",
        title: "Favor de llenar todos los campos",
      });
      return;
    }

    if (Number(form.cantidad_palet) === 0 && Number(form.valor_calibre) > 0) {
      Swal.fire({
        icon: "warning",
        title: "Favor de llenar todos los campos",
      });
      return;
    }

    const user = localStorage.getItem("@usuario");
    const user_json = JSON.parse(user);
    const yeguere_option = yeguere.find((item) => item.activo === 1);
    const destino_option = destino.find((item) => item.activo === 1);
    const tipo_pedido_option = tipoPedidoList.find((item) => item.activo === 1);
    const inmediato_option = destinoInmediatoList.find(
      (item) => item.selected === 1
    );
    const tarima_option = tarima.find((item) => item.activo === 1);

    let data = form;
    data["id_yeguere"] = yeguere_option.idyeguere;
    data["id_destino"] = destino_option.iddestino;
    data["tipo_pedido"] = tipo_pedido_option.tipo;
    data["id_destino_inmediato"] = inmediato_option.id_destino;
    data["id_tarima"] = tarima_option.idtarima;

    if (!isBicolorActive) {
      if (editar.status) {
        updateDate(user_json, data);
      }

      if (!editar.status) {
        saveData(user_json, data);
      }
    }

    if (isBicolorActive) {
      if (!editar.status) {
        saveDataBicolor(user_json, data);
      }
    }
  };

  const saveData = (user_json, data) => {
    api
      .post("api/pedido-empaque/proyeccion-diaria/pedido-empaque/", {
        data: {
          id_usuario: user_json.id,
          fecha: filter.fecha,
          data: data,
        },
      })
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Pedido guardado",
          timer: 1500,
          showConfirmButton: false,
          showCancelButton: false,
        });
        getEmpaqueData();
        closeModal();
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          if (error.response.status === 400) {
            Swal.fire({
              icon: "warning",
              title: "Ya cuenta con un pedido libre",
              text: "Favor de verificar la información",
            });
          }
          return;
        }
        Swal.fire({
          icon: "error",
          title: "Algo salió mal",
          text: "Favor de comunicarlo al equipo de desarrollo",
        });
      });
  };

  const updateDate = (user_json, data) => {
    api
      .put("api/pedido-empaque/proyeccion-diaria/pedido-empaque/", {
        data: {
          id_empaque: editar.id_empaque,
          id_usuario: user_json.id,
          fecha: filter.fecha,
          data: data,
        },
      })
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Pedido guardado",
          timer: 1500,
          showConfirmButton: false,
          showCancelButton: false,
        });
        getEmpaqueData();
        closeModal();
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          if (error.response.status === 400) {
            Swal.fire({
              icon: "warning",
              title: "Ya cuenta con un pedido libre",
              text: "Favor de verificar la información",
            });
          }
          return;
        }
        Swal.fire({
          icon: "error",
          title: "Algo salió mal",
          text: "Favor de comunicarlo al equipo de desarrollo",
        });
      });
  };

  const deletePedido = (id_empaque) => {
    Swal.fire({
      icon: "info",
      title: "Eliminar registro",
      text: "Los registros eliminados no se podran recuperar ¿Está seguro que desea eliminarlo?",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#dc3545",
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#28a745",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await api.delete(
            `api/pedido-empaque/proyeccion-diaria/pedido-empaque/?id_empaque=${id_empaque}`
          );
          return response;
        } catch {
          Swal.showValidationMessage("Algo salio mal");
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
      backdrop: true,
    })
      .then((res) => {
        if (res.isConfirmed) {
          Swal.fire({
            icon: "success",
            timer: 1500,
            title: "Registro eliminado",
          });
        }
      })
      .finally(() => {
        getEmpaqueData();
      });
  };

  const editarEmpaque = (id_empaque, campIndex, variedadIndex) => {
    api
      .get(`api/pedido-empaque/editar/?id_empaque=${id_empaque}`)
      .then((response) => {
        let data = response.data[0][0];

        let yeguere_arr = yeguere.map((item) => ({
          ...item,
          activo: 0,
        }));
        let tipo_pedido = tipoPedidoList.map((item) => ({
          ...item,
          activo: 0,
        }));
        let destino_inmediato_arr = destinoInmediatoList.map((item) => ({
          ...item,
          selected: 0,
        }));
        let destino_arr = destino.map((item) => ({
          ...item,
          activo: 0,
        }));
        let tarimas_list = tarima.map((item) => ({
          ...item,
          activo: 0,
        }));

        const yeguereIndex = yeguere.findIndex(
          (item) => item.idyeguere === data.id_yeguere
        );
        const tipoPedidoIndex = tipoPedidoList.findIndex(
          (item) => item.tipo === data.tipo_pedido
        );
        const destinoInmediatoIndex = destinoInmediatoList.findIndex(
          (item) => item.id_destino === data.id_destino_inmediato
        );
        const destinoIndex = destino.findIndex(
          (item) => item.iddestino === data.id_destino
        );
        const tarminaIndex = tarima.findIndex(
          (item) => item.idtarima === data.id_tarima
        );

        yeguere_arr[yeguereIndex].activo = 1;
        tipo_pedido[tipoPedidoIndex].activo = 1;
        destino_inmediato_arr[destinoInmediatoIndex].selected = 1;
        destino_arr[destinoIndex].activo = 1;
        tarimas_list[tarminaIndex].activo = 1;

        let tamanos = tamanoList.map((item) => ({
          ...item,
          valor:
            item.tamano === data.calibre
              ? data.bultos_por_palet * data.cantidad_palet +
                empaqueListShown[campIndex].variedades[
                  variedadIndex
                ].tamanos.find((value) => value.tamano === data.calibre).valor
              : 0,
          selected: item.tamano === data.calibre ? 1 : 0,
        }));

        setTamanoOptionModal(tamanos);
        setTipoEtiqueta(response.data[1]);
        setPesoEnvaseList(response.data[2]);
        setTipoCajaList(response.data[3]);
        setEtiquetaList(response.data[4]);
        setBultosPorPaletList(response.data[5]);
        setYeguere(yeguere_arr);
        setTipoPedidoList(tipo_pedido);
        setDestinoInmediatoList(destino_inmediato_arr);
        setDestino(destino_arr);
        setTarima(tarimas_list);
        setForm({
          ...form,
          calibre: data.calibre,
          valor_calibre:
            data.bultos_por_palet * data.cantidad_palet +
            empaqueListShown[campIndex].variedades[variedadIndex].tamanos.find(
              (value) => value.tamano === data.calibre
            ).valor,
          id_campo: data.id_campo,
          id_variedad: 0,
          variedad: data.variedad,
          resto: 0,
          resto_check: data.resto === 1,
          id_tipo_etiqueta: data.id_tipo_etiqueta,
          id_peso_envase: data.id_peso_envase,
          id_tipo_caja: data.id_tipo_caja,
          id_etiqueta: data.id_etiqueta,
          bultos_por_palet: data.bultos_por_palet,
          otro_bultos_por_palet: false,
          cantidad_palet: data.cantidad_palet,
          fumigar: data.fumigar === 1,
          prioridad: data.prioridad === 1,
          observaciones: data.observaciones,
          limite: data.limite,
        });
        setIsVisible(true);
        setEditar({
          ...editar,
          status: true,
          id_empaque: id_empaque,
        });
      });
  };

  /*------------------------Modal bicolor functions------------------------*/
  const openModaBicolor = (campoIndex) => {
    let variedades = [...empaqueListShown[campoIndex].variedades];
    let tamanos = [...tamanoList];
    let variedadesList = [];

    variedades.forEach((variedad) => {
      variedadesList.push({
        id_variedad: variedad.id_variedad,
        variedad: variedad.variedad,
        tamanos: variedad.tamanos,
      });
      variedad.tamanos.forEach((tamano) => {
        for (let tam of tamanos) {
          if (tam.tamano === tamano.tamano) {
            tam.valor += tamano.valor;
            break;
          }
        }
      });
    });
    setVariedadesCampoBicolorSelected(variedadesList);
    setTamanoOptionModal(tamanos);
    setIsVisible(true);
    setIsBicolorActive(true);
    setForm({
      ...form,
      id_campo: empaqueListShown[campoIndex].id_campo,
    });
  };

  const handleTamanoClickBicolor = (tamano) => {
    let variedades = variedadesCampoBicolorSelected.map((item) => ({
      id_variedad: item.id_variedad,
      variedad: item.variedad,
      valor: item.tamanos.find((value) => value.tamano === tamano).valor,
      selected: 0,
    }));
    let tamanos = tamanoOptionModal.map((item) => ({
      ...item,
      selected: 0,
    }));
    const index = tamanoOptionModal.findIndex((item) => item.tamano === tamano);
    tamanos[index].selected = 1;
    setForm({
      ...form,
      calibre: tamano,
    });
    setTamanoOptionModal(tamanos);
    setVariedadOptionsModal(variedades);
    setBicolorFormAux({
      ...bicolorFormAux,
      calibre: tamano,
      valor_calibre: tamanos[index].valor,
    });
    setForm({
      ...form,
      calibre: "",
      valor_calibre: 0,
      id_tipo_etiqueta: 0,
      bultos_por_palet: -1,
      otro_bultos_por_palet: false,
      id_peso_envase: 0,
      id_tipo_caja: 0,
      id_etiqueta: 0,
    });
  };

  const handleVariedadClickBicolor = (index) => {
    let variedad_list_modal_options = [...variedadOptionsModal];
    let calibre = bicolorFormAux.calibre;
    let valor_calibre = bicolorFormAux.valor_calibre;

    variedad_list_modal_options[index].selected =
      variedad_list_modal_options[index].selected === 1 ? 0 : 1;

    const variedades_seleccionadas = variedad_list_modal_options.filter(
      (item) => item.selected === 1
    );

    const id_variedad_list = variedades_seleccionadas.map((item) => {
      return item.id_variedad;
    });

    if (id_variedad_list.length <= 1) {
      calibre = "";
      valor_calibre = 0;
    }
    const data_bicolor_variedad = variedades_seleccionadas.map((item) => ({
      id_variedad: item.id_variedad,
      variedad: item.variedad,
      proyeccion: item.valor,
      resta: 0,
    }));

    setVariedadSeleccioandasLength(variedades_seleccionadas.length);
    setVariedadOptionsModal(variedad_list_modal_options);
    setBicolorForm(data_bicolor_variedad);
    unselectElements();
    setForm({
      ...form,
      calibre: calibre,
      valor_calibre: valor_calibre,
      id_tipo_etiqueta: 0,
      id_tipo_caja: 0,
      id_etiqueta: 0,
      bultos_por_palet: -1,
      id_peso_envase: 0,
    });
  };

  const saveDataBicolor = (user, info) => {
    const token = localStorage.getItem("@Token");
    api
      .post(
        "api/pedido-empaque/pedido-bicolor/",
        {
          data: {
            data: info,
            bicolorForm: bicolorForm,
            fecha: filter.fecha,
            id_usuario: user.id,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + token,
          },
        }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Pedido guardado",
          timer: 1500,
          showConfirmButton: false,
          showCancelButton: false,
        });
        getEmpaqueData();
        closeModal();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Algo salió mal",
          text: "Favor de comunicarlo al equipo de desarrollo",
        });
      });
  };

  const deletePedidoBicolor = (id_empaque) => {
    const token = localStorage.getItem("@Token");
    Swal.fire({
      icon: "info",
      title: "Eliminar registro",
      text: "Los registros eliminados no se podran recuperar ¿Está seguro que desea eliminarlo?",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      confirmButtonColor: "#dc3545",
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#28a745",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await api.delete(
            `api/pedido-empaque/pedido-bicolor/?id_empaque=${id_empaque}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + token,
              },
            }
          );
          return response;
        } catch {
          Swal.showValidationMessage("Algo salio mal");
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
      backdrop: true,
    })
      .then((res) => {
        if (res.isConfirmed) {
          Swal.fire({
            icon: "success",
            timer: 1500,
            title: "Registro eliminado",
          });
        }
      })
      .finally(() => {
        getEmpaqueData();
      });
  };

  useEffect(() => {
    const getCultivo = async () => {
      try {
        const res = await api.get("api/cultivo/all-cultivos-get");
        setCultivoList(res.data);
        if (res.data.length > 0) {
          setFilter({
            ...filter,
            id_cultivo: res.data[0].idcultivo,
          });
          getTamano(res.data[0].idcultivo);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getVariedades = () => {
      const token = localStorage.getItem("@Token");
      api
        .get("api/cultivo/response/all-tipo-cultivo/variedad", {
          headers: {
            Authorization: "Token " + token,
          },
        })
        .then((response) => {
          setVariedadList(response.data);
        });
    };

    const getNotificationNumbers = () => {
      const token = localStorage.getItem("@Token");
      const user = localStorage.getItem("@usuario");
      const json_user = JSON.parse(user);
      api
        .get(
          `api/permisos/notification-numbers/?type=empaque&id_usuario=${json_user.id}`,
          {
            headers: {
              Authorization: "Token " + token,
            },
          }
        )
        .then((response) => {
          setNotificationNumbers(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getNotificationNumbers();
    getCultivo();
    getVariedades();
    getEmpaqueElements();
  }, []);

  useEffect(() => {
    if (form.calibre !== "" && tipoEtiqueta.length === 0) {
      api
        .get("api/etiquetas/obtener/all-tipo-etiqueta")
        .then((response) => {
          setTipoEtiqueta(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [form.calibre]);

  useEffect(() => {
    if (number(filter.id_cultivo) !== 0 && variedadList.length > 0) {
      getTamano();
      const variedades = variedadList.filter(
        (item) => parseInt(item.idcultivo) === parseInt(filter.id_cultivo)
      );
      setVariedadOptions(variedades);
    }
  }, [filter.id_cultivo, variedadList]);

  useEffect(() => {
    if (parseInt(filter.id_cultivo) !== 0) {
      getEmpaqueData();
    }

    if (filter.id_cultivo !== 0) {
      getPesoEnvase();
    }
  }, [filter]);

  return (
    <>
      <Card className="my-2">
        <CardHeader>Pedido de empaque</CardHeader>
        <CardBody>
          <Row>
            <Col md={3}>
              <select
                value={filter.id_cultivo}
                className="form-control"
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    id_cultivo: e.target.value,
                  });
                }}
              >
                <option value="0">Cultivo...</option>
                {cultivoList.map((item) => (
                  <option key={item.idcultivo} value={item.idcultivo}>
                    {item.cultivo}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={3}>
              <input
                className="form-control"
                type="date"
                value={filter.fecha}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    fecha: e.target.value,
                  });
                }}
              />
            </Col>
            <Col md={3}>
              <select
                className="form-control"
                value={filter.id_variedad}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    id_variedad: e.target.value,
                  });
                }}
              >
                <option value="0">Variedad...</option>
                {variedadOptions.map((item) => (
                  <option value={item.idtipo} key={item.idtipo}>
                    {item.tipo}
                  </option>
                ))}
              </select>
            </Col>
          </Row>
          <br />
          <Row>
            <Col className="table-responsive">
              {empaqueListShown.map((campo, campoIndex) => (
                <table key={campo.id_campo} className="table">
                  <thead>
                    <tr>
                      <th>
                        <button
                          className="btn btn-outline-primary"
                          onClick={() => openModaBicolor(campoIndex)}
                          style={{ marginRight: "10px" }}
                        >
                          <FontAwesomeIcon icon="far fa-clipboard" />
                        </button>
                        {campo.campo}
                      </th>
                      {tamanoList.map((tamano) => (
                        <th style={{ textAlign: "center" }} key={tamano.tamano}>
                          {tamano.tamano}
                        </th>
                      ))}
                      <th>{campo.proyeccion}</th>
                      <th>
                        {campo.enviado !== 1 && (
                          <button
                            className="btn btn-outline-success"
                            onClick={() =>
                              sendNotification(campo.id_campo, campo.enviado)
                            }
                          >
                            <FontAwesomeIcon icon="fab fa-whatsapp" />
                          </button>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {campo.variedades.map((variedad, variedadIndex) => (
                      <>
                        <tr key={variedad.id_variedad}>
                          <td>
                            <button
                              className="btn btn-primary btn-sm"
                              style={{ borderRadius: "100px" }}
                              onClick={() => {
                                openDetalleEmpaque(campoIndex, variedadIndex);
                              }}
                            >
                              {variedad.isVisible ? (
                                <FontAwesomeIcon icon="fas fa-minus" />
                              ) : (
                                <FontAwesomeIcon icon="fas fa-plus" />
                              )}
                            </button>{" "}
                            {variedad.variedad}
                          </td>
                          {variedad.tamanos.map((tamano) => (
                            <td
                              key={tamano.tamano}
                              style={{ textAlign: "center" }}
                            >
                              {tamano.valor}
                              <br />
                              <FontAwesomeIcon icon="fa-solid fa-lock" />
                            </td>
                          ))}
                          <td>{variedad.proyeccion}</td>
                          <td>
                            <button
                              className="btn btn-outline-dark"
                              onClick={() =>
                                openModal(campoIndex, variedadIndex)
                              }
                            >
                              <FontAwesomeIcon icon="fas fa-boxes" />
                            </button>
                          </td>
                        </tr>
                        {variedad.isVisible && (
                          <tr>
                            <td colSpan="8">
                              <table className="table mb-0">
                                <tbody>
                                  {variedad.empaque.map((empaque) => (
                                    <tr key={empaque.id_empaque}>
                                      <td>
                                        {empaque.bicolor && (
                                          <FontAwesomeIcon
                                            icon="fas fa-project-diagram"
                                            style={{ color: "#0d73a5" }}
                                          />
                                        )}
                                      </td>
                                      <td style={{ fontWeight: "bold" }}>
                                        {empaque.tamano}
                                      </td>
                                      <td>{empaque.etiqueta}</td>
                                      <td>Tipo: {empaque.tipo_etiqueta}</td>
                                      <td>
                                        Cantidad:{" "}
                                        {empaque.resto === 1
                                          ? "Resto"
                                          : empaque.cantidad_palet}{" "}
                                        - {empaque.bultos_por_palet}
                                        {empaque.bicolor &&
                                          " / " + empaque.resta}
                                      </td>
                                      <td>
                                        <Tooltip text={empaque.observaciones}>
                                          Observaciones
                                        </Tooltip>
                                      </td>
                                      <td>Fumigar: {empaque.fumigar}</td>
                                      <td>
                                        {!empaque.bicolor && (
                                          <button
                                            className="btn btn-outline-primary btn-sm"
                                            onClick={() =>
                                              editarEmpaque(
                                                empaque.id_empaque,
                                                campoIndex,
                                                variedadIndex
                                              )
                                            }
                                          >
                                            <FontAwesomeIcon icon="fas fa-pen" />
                                          </button>
                                        )}{" "}
                                        <button
                                          className="btn btn-outline-danger btn-sm"
                                          onClick={
                                            !empaque.bicolor
                                              ? () =>
                                                  deletePedido(
                                                    empaque.id_empaque
                                                  )
                                              : () =>
                                                  deletePedidoBicolor(
                                                    empaque.id_empaque
                                                  )
                                          }
                                        >
                                          <FontAwesomeIcon icon="fas fa-trash-alt" />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  </tbody>
                </table>
              ))}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Modal isOpen={isVisible} toggle={closeModal} size="xl">
        <ModalHeader toggle={closeModal}>Empaque {form.variedad}</ModalHeader>
        <ModalBody>
          <HeaderModal
            tamanos_list={tamanoOptionModal}
            variedades_list={variedadOptionsModal}
            tamanosClickHandler={
              !isBicolorActive ? handleTamanoClick : handleTamanoClickBicolor
            }
            variedadesClickHanler={
              isBicolorActive ? handleVariedadClickBicolor : null
            }
            editar={editar}
            bicolor={isBicolorActive}
          />
          <br />
          <Row>
            <Col md={6}>
              <label>Tipo etiqueta</label>
              <hr />
              <Row>
                {form.calibre !== "" &&
                  tipoEtiqueta.map((item, index) => (
                    <Col key={item.idtipo_etiqueta} className="d-grid">
                      <button
                        className={returnActiveButton(item.activo)}
                        onClick={() => handleTipoEtiquetaClickButton(index)}
                      >
                        {item.etiqueta}
                      </button>
                    </Col>
                  ))}
              </Row>
            </Col>
            <Col md={6}>
              <label>Peso de envase</label>
              <hr />
              <Row>
                {form.id_tipo_etiqueta !== 0 &&
                  pesoEnvaseList.map((item, index) => (
                    <Col
                      className="d-grid"
                      md={3}
                      sm={2}
                      key={item.idtamano_envase}
                    >
                      <button
                        className={returnActiveButton(item.estatus)}
                        onClick={() => handlePesoEnvaseClickButton(index)}
                        style={{ marginTop: "5px" }}
                      >
                        {item.tamano}
                      </button>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={6}>
              <label>Tipo de caja</label>
              <hr />
              <Row>
                {form.id_peso_envase !== 0 &&
                  tipoCajaList.map((item, index) => (
                    <Col sm={12} md={4} className="d-grid" key={item.id_envase}>
                      <button
                        style={{ marginTop: "5px" }}
                        className={returnActiveButton(item.estatus)}
                        onClick={() => handleTipoCajaClickButton(index)}
                      >
                        {item.envase}
                      </button>
                    </Col>
                  ))}
              </Row>
            </Col>
            <Col md={6}>
              <label>Etiquetas</label>
              <hr />
              <Row>
                {form.id_tipo_caja !== 0 &&
                  etiquetaList.map((item, index) => (
                    <Col
                      sm={12}
                      md={4}
                      className="d-grid"
                      key={item.id_etiqueta}
                    >
                      <button
                        className={returnActiveButton(item.estatus)}
                        onClick={() => handleEtiquetaClickButton(index)}
                        style={{ marginTop: "5px" }}
                      >
                        {item.etiqueta}
                      </button>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={6}>
              <label>Bultos por palet</label>
              <hr />
              <Row>
                {form.id_tipo_caja !== 0 &&
                  bultosPorPaletList.map((item, index) => (
                    <Col className="d-grid">
                      <button
                        className={returnActiveButton(item.estatus)}
                        onClick={() => handleBultosPorPaletClickButton(index)}
                      >
                        {item.palet}
                      </button>
                    </Col>
                  ))}
              </Row>
              <br />
              <Row>
                <Col>
                  {form.otro_bultos_por_palet && (
                    <input
                      disabled={editar.status}
                      className="form-control"
                      min="0"
                      type="number"
                      value={form.bultos_por_palet}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          bultos_por_palet: Number(e.target.value),
                        });
                      }}
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col>
                  <label>Cantidad Palet</label>
                  <hr />
                  <input
                    className="form-control"
                    type="number"
                    min="0"
                    value={form.cantidad_palet}
                    disabled={form.bultos_por_palet === -1 || form.resto_check}
                    onChange={(e) => cantidadPaletChangeHandler(e)}
                    onWheel={(e) => e.target.blur()}
                  />
                </Col>
                {form.resto_check && (
                  <Col>
                    <label>Límite</label>
                    <hr />
                    <input
                      className="form-control"
                      type="number"
                      min="0"
                      value={form.limite}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          limite: e.target.value,
                        });
                      }}
                      onWheel={(e) => e.target.blur()}
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col>
                  <div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="resto"
                      onChange={(e) => restoCheckHandler(e)}
                      checked={form.resto_check}
                      disabled={isBicolorActive || form.bultos_por_palet === -1}
                    />{" "}
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      Resto cosecha
                    </label>
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <label>Resto: {form.resto}</label>
                </Col>
              </Row>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={6}>
              <Row>
                <Col>
                  <label>Yeguere</label>
                  <hr />
                  <Row>
                    {yeguere.map((item, index) => (
                      <Col
                        key={item.idyeguere}
                        className="d-grid"
                        md={4}
                        sm={6}
                        style={{ marginTop: "5px" }}
                      >
                        <button
                          className={returnActiveButton(item.activo)}
                          onClick={() =>
                            etiquetasButtonHanldler(YEGUERE, index)
                          }
                        >
                          {item.yeguere}
                        </button>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <label>Tipo de pedido</label>
                  <hr />
                  <Row>
                    {tipoPedidoList.map((item, index) => (
                      <Col md={6} sm={6} className="d-grid" key={item.id}>
                        <button
                          className={returnActiveButton(item.activo)}
                          onClick={() =>
                            etiquetasButtonHanldler(TIPO_PEDIDO_VALUE, index)
                          }
                        >
                          {item.tipo}
                        </button>
                      </Col>
                    ))}
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <div>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="prioridad"
                          onChange={(e) => {
                            setForm({
                              ...form,
                              prioridad: e.target.checked,
                            });
                          }}
                          checked={form.prioridad}
                        />{" "}
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          ¿Prioridad?
                        </label>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <label>Destino inmediato</label>
                  <hr />
                  <Row>
                    {destinoInmediatoList.map((item, index) => (
                      <Col className="d-grid" key={item.id_destino}>
                        <button
                          className={returnActiveButton(item.selected)}
                          onClick={() =>
                            etiquetasButtonHanldler(INMEDIATO, index)
                          }
                        >
                          {item.destino}
                        </button>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <label>País Destino</label>
              <hr />
              <Row>
                {destino.map((item, index) => (
                  <Col
                    key={item.iddestino}
                    className="d-grid"
                    md={6}
                    sm={6}
                    style={{ marginTop: "5px" }}
                  >
                    <button
                      className={returnActiveButton(item.activo)}
                      onClick={() => etiquetasButtonHanldler(DESTINO, index)}
                    >
                      {item.destino}
                    </button>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={6}>
              <label>Tarimas</label>
              <hr />
              <Row>
                {tarima.map((item, index) => (
                  <Col className="d-grid" key={item.idtarima}>
                    <button
                      className={returnActiveButton(item.activo)}
                      onClick={() => etiquetasButtonHanldler(TARIMA, index)}
                    >
                      {item.tarima}
                    </button>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col md={6}>
              <label>Observaciones</label>
              <hr />
              <textarea
                className="form-control"
                rows="3"
                value={form.observaciones}
                onChange={(e) => {
                  setForm({
                    ...form,
                    observaciones: e.target.value,
                  });
                }}
              />
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="fumigar"
                  checked={form.fumigar}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      fumigar: e.target.checked,
                    });
                  }}
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  ¿Fumigar?
                </label>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <button className="btn btn-primary" onClick={validateData}>
                Guardar
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PedidoEmpaque;
