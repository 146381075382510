import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import { Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../../Component/axios";
import "../../CSS/configuracion.css";
const MySwal = withReactContent(Swal);
library.add(fab, fas, far);

const COLORS = [
  { color: "aqua", hex: "#33cccc" },
  { color: "black", hex: "#000000" },
  { color: "blue", hex: "#0000FF" },
  { color: "blue_gray", hex: "#666699" },
  { color: "bright_green", hex: "#00FF00" },
  { color: "brown", hex: "#964B00" },
  { color: "coral", hex: "#FF7F50" },
  { color: "cyan_ega", hex: "#00FFFF" },
  { color: "dark_blue", hex: "#000080" },
  { color: "dark_blue_ega", hex: "#0000FF" },
  { color: "dark_green", hex: "#006400" },
  { color: "dark_green_ega", hex: "#008000" },
  { color: "dark_purple", hex: "#800080" },
  { color: "dark_red", hex: "#8B0000" },
  { color: "dark_red_ega", hex: "#800000" },
  { color: "dark_teal", hex: "#008080" },
  { color: "dark_yellow", hex: "#808000" },
  { color: "gold", hex: "#FFD700" },
  { color: "gray_ega", hex: "#808080" },
  { color: "gray25", hex: "#C0C0C0" },
  { color: "gray40", hex: "#666666" },
  { color: "gray50", hex: "#7F7F7F" },
  { color: "gray80", hex: "#CCCCCC" },
  { color: "green", hex: "#008000" },
  { color: "ice_blue", hex: "#B3FFD9" },
  { color: "indigo", hex: "#4B0082" },
  { color: "ivory", hex: "#FFFFF0" },
  { color: "lavender", hex: "#E6E6FA" },
  { color: "light_blue", hex: "#87CEEB" },
  { color: "light_green", hex: "#90EE90" },
  { color: "light_orange", hex: "#FFD8B1" },
  { color: "light_turquoise", hex: "#AFE4DE" },
  { color: "light_yellow", hex: "#FFFFE0" },
  { color: "lime", hex: "#00FF00" },
  { color: "magenta_ega", hex: "#FF00FF" },
  { color: "ocean_blue", hex: "#4F69C6" },
  { color: "olive_ega", hex: "#808000" },
  { color: "olive_green", hex: "#6B8E23" },
  { color: "orange", hex: "#FF6600" },
  { color: "pale_blue", hex: "#8DB4E2" },
  { color: "periwinkle", hex: "#CCCCFF" },
  { color: "pink", hex: "#FFC0CB" },
  { color: "plum", hex: "#DDA0DD" },
  { color: "purple_ega", hex: "#800080" },
  { color: "red", hex: "#FF0000" },
  { color: "rose", hex: "#FF007F" },
  { color: "sea_green", hex: "#2E8B57" },
  { color: "silver_ega", hex: "#C0C0C0" },
  { color: "sky_blue", hex: "#87CEEB" },
  { color: "tan", hex: "#D2B48C" },
  { color: "teal", hex: "#008080" },
  { color: "teal_ega", hex: "#008080" },
  { color: "turquoise", hex: "#40E0D0" },
  { color: "violet", hex: "#EE82EE" },
  { color: "yellow", hex: "#FFFF00" },
];

const chageColor = (color) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
  var rgb = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
  //Formula que determinara el color blanco o negro de las letras
  var colorText = contrast(rgb);

  return colorText;
};

function contrast(rgb) {
  const C = [rgb.r / 255, rgb.g / 255, rgb.b / 255];

  for (var i = 0; i < C.length; ++i) {
    if (C[i] <= 0.03928) {
      C[i] = C[i] / 12.92;
    } else {
      C[i] = Math.pow((C[i] + 0.055) / 1.055, 2.4);
    }
  }
  const L = 0.2126 * C[0] + 0.7152 * C[1] + 0.0722 * C[2];

  if (L > 0.179) {
    return "black";
  } else {
    return "white";
  }
}

const InsertarTipo = () => {
  const [listCultivo, setListCultivo] = useState([]);
  const [cultivo, setCultivo] = useState("");
  const [idcultivo, setIdCultivo] = useState(0);
  const [variedadList, setVariedadList] = useState([]);
  const [variedad, setVariedad] = useState("");
  const [editar, setEditar] = useState(false);
  const [idtipoCultivo, setIdTipoCultivo] = useState(0);
  const [editVariedad, setEditVariedad] = useState("");
  const [activar, setActivar] = useState(0);
  const [color, setColor] = useState("");
  const [editarColor, setEditarColor] = useState("");

  const InsertTipos = async (e) => {
    const user = await localStorage.getItem("@usuario");
    const json_user = JSON.parse(user);

    if (cultivo === "Cultivo" || variedad === "" || color === "") {
      MySwal.fire({
        position: "center",
        icon: "warning",
        title: "Favor de llenar los campos necesarios",
        showConfirmButton: false,
        timer: 1500,
      });
      setVariedad("");
      return;
    }

    api
      .post("api/cultivo/insertar/new_tipo_cultivo", {
        data: {
          idcultivo: idcultivo,
          tipo: variedad,
          idusuario: json_user.id,
          color: color,
        },
      })
      .then((response) => {
        ObtenerVariedad(idcultivo);
        setVariedad("");
        document.getElementById("variacion").value = "";
      })
      .catch((error) => {
        if (error.response.status === 400) {
          Swal.fire({
            title: "Error",
            icon: "warning",
            text: error.response.data.msg,
          });
        }

        if (error.response.status === 409) {
          setActivar(error.response.data.idtipo);
          Swal.fire({
            title: "Activar",
            icon: "warning",
            text: error.response.data.msg,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Activar",
          }).then((result) => {
            if (result.isConfirmed) {
              activarVariedad();
            }
          });
        }

        if (error.response.status === 500) {
          Swal.fire({
            title: "Error",
            icon: "error",
            text: "Oh oh, algo salio mal \n Favor de contactar con el equipo de desarrollo",
          });
        }
      });
  };

  const showForm = (e) => {
    if (e.target.value === "Cultivo") {
      setIdCultivo(0);
      setCultivo("");
      setVariedadList([]);
      return;
    }
    const xidcultivo = e.target.value;
    setIdCultivo(listCultivo[xidcultivo].idcultivo);
    setCultivo(listCultivo[xidcultivo].cultivo);
    ObtenerVariedad(listCultivo[xidcultivo].idcultivo);
  };

  const ObtenerVariedad = (xidcultivo) => {
    if (xidcultivo !== 0) {
      api
        .post("api/cultivo/all_tipo_cultivo", {
          data: { idcultivo: xidcultivo },
        })
        .then((response) => {
          if (response.data.length > 0) {
            setVariedadList(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const OpenModalEditarVariedad = (idtipo, index, color) => {
    setEditar(true);
    setIdTipoCultivo(idtipo);
    setEditVariedad(variedadList[index].tipo);
    setEditarColor(color === null ? "" : color);
  };

  const OpenWarningEliminarVariedad = (idtipo) => {
    Swal.fire({
      title: "¿Seguro que desea eliminar el registro?",
      text: "Los registros eliminados no se podran recuperar",
      showDenyButton: true,
      confirmButtonText: "Eliminar",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        EliminarTipo(idtipo);
      }
    });
  };

  const EliminarTipo = (xidtipo) => {
    api
      .post("api/cultivo/delete/tipo_cultivo", {
        data: {
          idtipo: xidtipo,
        },
      })
      .then((response) => {
        MySwal.fire({
          position: "center",
          icon: "success",
          title: "Registro eliminado",
          showConfirmButton: false,
          timer: 1500,
        });
        ObtenerVariedad(idcultivo);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text:
            error +
            " Contacte con el area de desarrollo para reviar el problema",
        });
      });
  };

  const EditarTipo = () => {
    if (editVariedad === "" || editarColor === "") {
      Swal.fire({
        title: "Campos vacios",
        icon: "warning",
        text: "Favor de llenar todos los campos",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    api
      .post("api/cultivo/edit/tipo_cultivo", {
        data: {
          idtipo: idtipoCultivo,
          tipo: editVariedad,
          color: editarColor,
        },
      })
      .then((response) => {
        setEditar(false);
        MySwal.fire({
          position: "center",
          icon: "success",
          title: "Registro modificado",
          showConfirmButton: false,
          timer: 1500,
        });
        ObtenerVariedad(idcultivo);
        setEditVariedad("");
      })
      .catch((error) => {
        if (error.response.status === 404) {
          Swal.fire({
            title: "Error",
            icon: "error",
            text: "Ruta no encontrada, favor contactar con el area de desarrollo para solucionar el problema",
          });
        }

        if (error.response.status === 409) {
          Swal.fire({
            title: "Activar",
            icon: "warning",
            text: error.response.data.msg,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Activar",
          }).then((result) => {
            if (result.isConfirmed) {
              activarVariedad();
            }
          });
        }

        if (error.response.status === 400) {
          Swal.fire({
            title: "Error",
            icon: "warning",
            text: error.response.data.msg,
          });
        }

        if (error.response.estatus === 500) {
          Swal.fire({
            title: "Error",
            icon: "error",
            text: "Oh oh, algo salio mal, favor de contactar con el area de desarrollo para solucionar el problema",
          });
        }
      });
  };

  const activarVariedad = () => {
    api
      .post("api/cultivo/activar/tipo_cultivo", { id: activar })
      .then((response) => {
        MySwal.fire({
          position: "center",
          icon: "success",
          title: response.data.msg,
          showConfirmButton: false,
          timer: 1500,
        });
        ObtenerVariedad(idcultivo);
      })
      .catch((error) => {
        if (error.response.status === 500) {
        }
      });
  };

  useEffect(() => {
    api
      .get("api/cultivo/all-cultivos-get")
      .then((response) => {
        setListCultivo(response.data);
      })
      .catch((error) => {
        if (error.response.estatus === 500) {
          Swal.fire({
            title: "Error",
            icon: "error",
            text: "Oh oh, algo salio mal, favor de contactar con el area de desarrollo para solucionar el problema",
          });
        }
      });
  }, []);

  return (
    <Card className="my-2" style={{ height: "90vh" }}>
      <CardHeader style={{ fontWeight: "bold", textAlign: "center" }}>
        Variedad
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={3} xs={12} sm={12} lg={3}>
            <select
              className="form-select"
              aria-label="cultivo"
              name="idcultivo"
              id="idcultivo"
              onChange={(e) => showForm(e)}
            >
              <option defaultValue>Cultivo</option>
              {listCultivo.map((item, index) => (
                <option key={item.idcultivo} value={index}>
                  {item.cultivo}
                </option>
              ))}
            </select>
          </Col>
          <Col md={3} xs={12} sm={12} lg={3}>
            <input
              placeholder="Variedad"
              className="form-control"
              type="text"
              id="variacion"
              name="variacion"
              value={variedad}
              onChange={(e) => setVariedad(e.target.value)}
            />
          </Col>
          <Col>
            <select
              className="form-control"
              value={color}
              onChange={(e) => {
                setColor(e.target.value);
              }}
            >
              <option value="">Seleccionar...</option>
              {COLORS.map((item) => (
                <option
                  style={{
                    backgroundColor: item.hex,
                    color: chageColor(item.hex),
                    fontWeight: "bold",
                  }}
                  key={item.color}
                  value={item.color}
                >
                  {item.color}
                </option>
              ))}
            </select>
          </Col>
          <Col md={3} xs={12} sm={12} lg={3} className="d-grid">
            <button
              onClick={() => InsertTipos()}
              className="btn btn-primary button-label-margin"
            >
              <i>
                <FontAwesomeIcon icon="fa-solid fa-plus" type="submit" />
              </i>{" "}
              Agregar
            </button>
          </Col>
        </Row>
        <br />
        <Row style={{ overflow: "auto", height: "70vh" }}>
          <Col>
            <table className="table">
              <thead
                className="table-light"
                style={{ position: "sticky", top: 0 }}
              >
                <tr>
                  <th>Cultivo</th>
                  <th>Variedad</th>
                  <th>Color</th>
                  <th>Acción</th>
                </tr>
              </thead>
              <tbody>
                {variedadList.map((item, index) => (
                  <tr key={index}>
                    <td>{cultivo}</td>
                    <td key={item.idtipo}>{item.tipo}</td>
                    <td>
                      <span
                        style={{
                          display: "inline-block",
                          width: "25px",
                          height: "25px",
                          backgroundColor:
                            item.color === null
                              ? "white"
                              : COLORS.find(
                                  (value) => value.color === item.color
                                ).hex,
                        }}
                      ></span>
                    </td>
                    <td>
                      <button
                        className="btn btn-danger btn-block btn-sm"
                        onClick={(e) =>
                          OpenWarningEliminarVariedad(item.idtipo)
                        }
                      >
                        <i className="trash">
                          <FontAwesomeIcon icon="fa-solid fa-trash" />
                        </i>
                      </button>
                      <button
                        className="btn btn-primary btn-block btn-sm"
                        onClick={(e) =>
                          OpenModalEditarVariedad(
                            item.idtipo,
                            index,
                            item.color
                          )
                        }
                      >
                        <i className="edit">
                          <FontAwesomeIcon icon="fa-solid fa-pen" />
                        </i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
        <Modal isOpen={editar} toggle={() => setEditar(!editar)}>
          <ModalHeader toggle={() => setEditar(!editar)}>
            Editar variedad
          </ModalHeader>
          <ModalBody>
            <span>Cultivo</span>
            <input value={cultivo} disabled className="form-control" />
            <br />
            <span>Variedad</span>
            <input
              className="form-control"
              name="edit-variedad"
              id="edit-variedad"
              onChange={(e) => setEditVariedad(e.target.value)}
              value={editVariedad}
            />
            <br />
            <label>Color</label>
            <select
              className="form-control"
              value={editarColor}
              onChange={(e) => {
                setEditarColor(e.target.value);
              }}
            >
              <option value="">Seleccionar...</option>
              {COLORS.map((item) => (
                <option
                  style={{
                    backgroundColor: item.hex,
                    color: chageColor(item.hex),
                    fontWeight: "bold",
                  }}
                  key={item.color}
                  value={item.color}
                >
                  {item.color}
                </option>
              ))}
            </select>
            <br />
            <button className="btn btn-success" onClick={(e) => EditarTipo()}>
              <i>
                <FontAwesomeIcon icon="fa-solid fa-floppy-disk" />
              </i>{" "}
              Guardar
            </button>
          </ModalBody>
        </Modal>
      </CardBody>
    </Card>
  );
};

export default InsertarTipo;
