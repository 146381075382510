import React from "react";
import Campo from '../../Provider/CampoProvider'
import Home from "../Home/Home";
import Login from "../Login/Login";
import {useAppContext } from '../../Context/UseAuth'

const LognRouter = () => {
    const appContext = useAppContext()

    return (
        <>
            {
                appContext.isLoggedin ? <Campo /> : <Login />
            }
        </>
    )
}

export default LognRouter
