import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { Row, Col } from "react-bootstrap";
import { Card, CardBody, CardHeader } from "reactstrap";
import "../../CSS/configuracion.css";
import api from "../../Component/axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
library.add(fab, fas, far);
const MySwal = withReactContent(Swal);

const validateInput = (inputValue) => {
  const nonAlphabetPattern = /^[^a-zA-Z]*$/;
  return nonAlphabetPattern.test(inputValue);
};

const Destino = () => {
  const [destinoList, setDestinoList] = useState([]);
  const [destino, setDestino] = useState("");

  const getDestino = () => {
    const token = localStorage.getItem("@Token");
    api
      .get("api/empaque/destino/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        setDestinoList(response.data);
      })
      .catch((error) => {});
  };

  const insertNewDestino = () => {
    const token = localStorage.getItem("@Token");
    if (destino === "" || validateInput(destino)) {
      MySwal.fire({
        icon: "info",
        title: "Favor de llenar todos los campos",
        timer: 1500,
      });
      return;
    }
    api
      .post(
        "api/empaque/destino/",
        {
          data: { destino: destino },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + token,
          },
        }
      )
      .then((response) => {
        MySwal.fire({
          icon: "success",
          title: "Datos guardados",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        if (error.response.status === 400) {
          MySwal.fire({
            icon: "error",
            title: error.response.data.msg,
          });
        } else {
          MySwal.fire({
            icon: "error",
            title: error,
            text: "Favor de contactar con el equipo de desarrollo para solucionar el problema",
          });
        }
      })
      .finally(() => {
        getDestino();
        setDestino("");
      });
  };

  const editDestino = (id_destino) => {
    const token = localStorage.getItem("@Token");
    const desino_selected = destinoList.find(
      (item) => item.iddestino === id_destino
    );
    MySwal.fire({
      title: "Modificar destino",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
        value: desino_selected.destino,
      },
      showCancelButton: true,
      confirmButtonText: "Guardar",
      showLoaderOnConfirm: true,
      preConfirm: async (destino) => {
        try {
          if (destino === "" || validateInput(destino)) {
            throw new Error("Favor de llenar los campos");
          }
          const response = await api.put(
            "api/empaque/destino/",
            {
              data: {
                iddestino: id_destino,
                destino: destino,
              },
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + token,
              },
            }
          );
          return response;
        } catch (error) {
          Swal.showValidationMessage(`Error - ${error}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
      backdrop: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Datos guardados",
        });
        getDestino();
      }
    });
  };

  const deleteDestino = (id_desino, estatus) => {
    const token = localStorage.getItem("@Token");
    const action = estatus === 0 ? "activar" : "desactivar";
    const responseText = estatus === 0 ? "activado" : "desactivado";
    MySwal.fire({
      icon: "warning",
      title: `¿Está seguro que desea ${action} el destino?`,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: estatus === 0 ? "Activar" : "Desactivar",
      confirmButtonColor: estatus === 0 ? "#28a745" : "#dc3545",
      preConfirm: async () => {
        try {
          const response = api.delete(
            `api/empaque/destino/?id_destino=${id_desino}&status=${
              estatus === 0 ? 1 : 0
            }`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + token,
              },
            }
          );
          return response;
        } catch (error) {
          Swal.showValidationMessage(
            `Error - Favor de comunicarlo con el equipo de desarrollo`
          );
          return;
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          icon: "success",
          title: `Registro ${responseText}`,
          showConfirmButton: false,
          timer: 1500,
        });
        getDestino();
      }
    });
  };

  useEffect(() => {
    getDestino();
  }, []);

  return (
    <Card className="my-2" style={{ height: "90vh" }}>
      <CardHeader style={{ fontWeight: "bold", textAlign: "center" }}>
        Agregar destino
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={3} xs={12} sm={12} lg={3}>
            <input
              className="form-control"
              value={destino}
              onChange={(e) => setDestino(e.target.value)}
            />
          </Col>
          <Col md={3} xs={12} sm={12} lg={3} className="d-grid">
            <button
              className="btn btn-primary"
              onClick={() => insertNewDestino()}
            >
              <i>
                <FontAwesomeIcon icon="fa-solid fa-plus" />
              </i>{" "}
              Agregar
            </button>
          </Col>
        </Row>
        <br />
        <Row>
          <Col style={{ overflow: "auto", height: "70vh" }}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Destino</th>
                  <th>Acción</th>
                </tr>
              </thead>
              <tbody>
                {destinoList.map((item, i) => (
                  <tr key={item.iddestino}>
                    <th>{item.destino}</th>
                    <td>
                      <button
                        className="btn btn-outline-primary"
                        onClick={() => editDestino(item.iddestino)}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-pencil" />
                      </button>{" "}
                      <button
                        className={
                          item.estatus === 1
                            ? "btn btn-outline-danger"
                            : "btn btn-outline-success"
                        }
                        onClick={() =>
                          deleteDestino(item.iddestino, item.estatus)
                        }
                      >
                        <FontAwesomeIcon icon="fas fa-power-off" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Destino;
