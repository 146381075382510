import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import api from "../../Component/axios.js";
import moment from "moment";
import Swal from "sweetalert2";

const ProyeccionSemanal = () => {
  const [tableContent, setTableContent] = useState([]);
  const [temporadaList, setTemporadaList] = useState();
  const [cultivoList, setCultivoList] = useState([]);
  const [semanaList, setSemanaList] = useState([]);
  const [modal, setModal] = useState({
    content: [],
    isVisible: false,
  });
  const [form, setForm] = useState({
    cultivo: 0,
    temporada: moment().year(),
    semana: moment().week(),
  });

  const getInfo = () => {
    if (form.temporada !== "0" && form.semana !== "0") {
      Swal.showLoading();
      api
        .get(
          `api/proyeccion-semanal/proyeccion/?id_campo=${localStorage.getItem(
            "codigo_campo"
          )}&id_cultivo=${form.cultivo}&temporada=${form.temporada}&semana=${
            form.semana
          }`
        )
        .then((response) => {
          if (Swal.isLoading) {
            setTimeout(() => {
              Swal.close();
            }, 1500);
          }
          setTableContent(response.data);
        });
    }
  };

  const saveData = () => {
    const user = localStorage.getItem("@usuario");
    const json_user = JSON.parse(user);
    const fecha = semanaList.find(
      (item) => item.semana === parseInt(form.semana)
    );

    if (
      form.temporada === "" ||
      form.semana === "" ||
      temporadaList.length === 0
    ) {
      Swal.fire({
        icon: "info",
        title: "Favor de llenar todos los campos",
      });
      return;
    }

    const all_total_semana_values = tableContent.filter(
      (item) => item.total_semana !== ""
    );

    if (all_total_semana_values.length === 0) {
      Swal.fire({
        icon: "info",
        title: "Favor de capturar el total de semana",
      });
      return;
    }

    api
      .post("api/proyeccion-semanal/proyeccion/", {
        data: {
          tableContent,
          temporada: form.temporada,
          semana: form.semana,
          inicio: fecha.inicio,
          fin: fecha.fin,
          id_usuario: json_user.id,
          id_campo: localStorage.getItem("codigo_campo"),
        },
      })
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Se guardo con éxito",
          timer: 1500,
          showConfirmButton: false,
        });
      });
  };

  const deleteProyeccion = (id_proyeccion) => {
    Swal.fire({
      icon: "warning",
      title: "¿Está seguro que desea eliminar la proyeccion?",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#007bff",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await api.delete(
            `api/proyeccion-semanal/proyeccion/?id_proyeccion=${id_proyeccion}`
          );
          return response;
        } catch (error) {
          Swal.showValidationMessage(`Error - ${error}`);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
      backdrop: true,
    }).then((res) => {
      if (res.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Registro eliminado",
          timer: 1500,
          showConfirmButton: false,
        });
        getInfo();
      }
    });
  };

  const semanaChangeHandler = (index, e) => {
    let arr = [...tableContent];
    arr[index].total_semana = e.target.value;
    setTableContent(arr);
  };

  const getBitacoraData = (id_variedad) => {
    api
      .get(
        `api/proyeccion-semanal/bitacora/?temporada=${form.temporada}&semana=${
          form.semana
        }&id_campo=${localStorage.getItem(
          "codigo_campo"
        )}&id_variedad=${id_variedad}`
      )
      .then((response) => {
        setModal({
          ...modal,
          isVisible: true,
          content: response.data,
        });
      });
  };

  useEffect(() => {
    const ObtenerCultivoxCampo = async () => {
      const idcampo = localStorage.getItem("codigo_campo");
      const token = localStorage.getItem("@Token");
      try {
        const response = await api.post(
          "api/cultivo/get/cultivo-por-campo",
          { data: idcampo },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + token,
            },
          }
        );
        const data = response.data;
        if (data.length === 1) {
          setForm({
            ...form,
            cultivo: data[0].idcultivo,
          });
        }
        setCultivoList(data);
      } catch (error) {
        console.log(error);
      }
    };

    ObtenerCultivoxCampo();

    api.get("api/temporada/temporada").then((response) => {
      setTemporadaList(response.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (form.temporada === "0") {
      setSemanaList([]);
      setTableContent([]);
      return;
    }
    api
      .get(`api/proyeccion-semanal/semanas/?temporada=${form.temporada}`)
      .then((response) => {
        setSemanaList(response.data);
      });
  }, [form.temporada]);

  useEffect(() => {
    getInfo();
  }, [form]);

  return (
    <Card className="my-2">
      <CardHeader style={{ fontWeight: "bold" }}>Proyección semanal</CardHeader>
      <CardBody>
        <div className="row">
          {cultivoList.length > 1 && (
            <div className="col-2">
              <select
                className="form-control"
                value={form.cultivo}
                onChange={(e) => {
                  setForm({
                    ...form,
                    cultivo: e.target.value,
                  });
                }}
              >
                <option>Cultivo...</option>
                {cultivoList?.map((cultivo) => (
                  <option key={cultivo.cultivo} value={cultivo.idcultivo}>
                    {cultivo.cultivo}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="col-2">
            <select
              className="form-control"
              value={form.temporada}
              onChange={(e) => {
                setForm({
                  ...form,
                  temporada: e.target.value,
                });
              }}
            >
              <option value="0">Temporada...</option>
              {temporadaList?.map((temporada) => (
                <option key={temporada.temporada} value={temporada.temporada}>
                  {temporada.temporada}
                </option>
              ))}
            </select>
          </div>
          <div className="col-3">
            <select
              className="form-control"
              value={form.semana}
              onChange={(e) => {
                setForm({
                  ...form,
                  semana: e.target.value,
                });
              }}
            >
              <option value="0">Semana...</option>
              {semanaList?.map((semana) => (
                <option key={semana.semana} value={semana.semana}>
                  {`${semana.semana} - ${moment(semana.inicio).format(
                    "YYYY/MM/DD"
                  )} - ${moment(semana.fin).format("YYYY/MM/DD")}`}
                </option>
              ))}
            </select>
          </div>
          <div className="col-2">
            <button className="btn btn-success" onClick={() => saveData()}>
              <FontAwesomeIcon icon="fas fa-save" /> Guardar
            </button>
          </div>
        </div>
        <br />
        <div className="row">
          {tableContent.length > 0 && (
            <div className="col-12 table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Variedad</th>
                    <th>Prod/Ha.</th>
                    <th>Total Semana</th>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {tableContent.map((item, index) => (
                    <tr key={item.id_variedad}>
                      <td>{item.variedad}</td>
                      <td>
                        {Intl.NumberFormat().format(item.produccion_hectarea)}
                      </td>
                      <td>
                        <input
                          className="form-control"
                          value={item.total_semana}
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          min="0"
                          onChange={(event) => {
                            semanaChangeHandler(index, event);
                          }}
                        />
                      </td>
                      <td>
                        <button
                          className="btn btn-outline-dark"
                          onClick={() => getBitacoraData(item.id_variedad)}
                        >
                          <FontAwesomeIcon icon="fas fa-book" />
                        </button>{" "}
                        {item.existe && (
                          <button
                            className="btn btn-outline-danger"
                            onClick={() => deleteProyeccion(item.id_semanal)}
                          >
                            <FontAwesomeIcon icon="fas fa-trash" />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </CardBody>
      <Modal
        isOpen={modal.isVisible}
        toggle={() => {
          setModal({
            ...modal,
            isVisible: false,
          });
        }}
        size="xl"
      >
        <ModalHeader
          toggle={() => {
            setModal({
              ...modal,
              isVisible: false,
            });
          }}
        >
          Bitácora
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            <table className="table">
              <thead className="table-light">
                <tr>
                  <th scope="col">Temporada</th>
                  <th scope="col">Campo</th>
                  <th scope="col">Variedad</th>
                  <th scope="col">Cantidad</th>
                  <th scope="col">Usuario</th>
                  <th scope="col">Fecha</th>
                </tr>
              </thead>
              <tbody>
                {modal.content.map((item) => (
                  <tr key={item.id_bitacora}>
                    <td>{item.temporada}</td>
                    <td>{item.campo}</td>
                    <td>{item.variedad}</td>
                    <td>{item.cantidad}</td>
                    <td>{item.usuario}</td>
                    <td>{item.fecha}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </ModalBody>
      </Modal>
    </Card>
  );
};

export default ProyeccionSemanal;
