import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../../Component/axios";
import "../../CSS/configuracion.css";
const MySwal = withReactContent(Swal);
library.add(fab, fas, far);

const InsertarTamano = () => {
  const [listCultivo, setListCultivo] = useState([]);
  const [listVariedad, setVariedadList] = useState([]);
  const [idcultivo, setIdCultivo] = useState(0);
  const [listaIdTipo, setListaIdTipo] = useState([]);
  const [tamano, setTamano] = useState("");
  const [listaTamanoxCultivo, setListaTamanoxCultivo] = useState([]);

  const ArrVariedad = (e, index) => {
    const check = e.target.checked;
    const i = index;
    const idtipo = e.target.value;
    var arridtipo = listaIdTipo;

    if (check) {
      arridtipo[i] = parseInt(idtipo);
    }

    if (!check) {
      arridtipo[i] = 0;
    }

    setListaIdTipo(arridtipo);
  };

  const obtenerTamanoxTipo = () => {
    api
      .post("api/cultivo/get/tamano_cultivo", { data: { id: idcultivo } })
      .then((response) => {
        setListaTamanoxCultivo(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const pushTamano = () => {
    if (tamano.trim() === "" || listaIdTipo.length === 0 || idcultivo === 0) {
      MySwal.fire({
        position: "center",
        icon: "warning",
        title: "Favor de llenar los campos requeridos",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    const json_user = JSON.parse(localStorage.getItem("@usuario"));
    api
      .post("api/cultivo/insert/new_tamano/", {
        data: {
          idarray: listaIdTipo,
          tamano: tamano,
          idusuario: json_user.id,
        },
      })
      .then((response) => {
        MySwal.fire({
          position: "center",
          icon: "success",
          title: "Registro guardado",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        MySwal.fire({
          position: "center",
          icon: "error",
          title: "Algo salió mal!",
        });
      })
      .finally(() => {
        setTamano("");
        obtenerTamanoxTipo();
      });
  };

  const deleteTamano = (tamano, estatus) => {
    const action = estatus === 0 ? "activar" : "desactivar";
    const responseText = estatus === 0 ? "activado" : "desactivado";
    Swal.fire({
      icon: "warning",
      title: `¿Está seguro que desea ${action} el tamaño?`,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: estatus === 0 ? "Activar" : "Desactivar",
      confirmButtonColor: estatus === 0 ? "#28a745" : "#dc3545",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await api.delete(
            `api/cultivo/insert/new_tamano/?tamano=${tamano}&estatus=${
              estatus === 1 ? 0 : 1
            }`
          );
          return response;
        } catch (error) {
          Swal.showValidationMessage(
            `Error - Favor de comunicarlo con el equipo de desarrollo`
          );
          return;
        }
      },
      allowOutsideClick: () => Swal.isLoading(),
      backdrop: true,
    }).then((res) => {
      if (res.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: `Registro ${responseText}`,
          timer: 1500,
        });
        obtenerTamanoxTipo();
      }
    });
  };

  useEffect(() => {
    if (idcultivo !== 0) {
      api
        .post("api/cultivo/all_tipo_cultivo", {
          data: { idcultivo: idcultivo },
        })
        .then((response) => {
          setVariedadList(response.data);
        })
        .catch((error) => {
          console.log(error);
        });

      obtenerTamanoxTipo();
    } else {
      setVariedadList([]);
      setListaTamanoxCultivo([]);
    }
  }, [idcultivo]);

  useEffect(() => {
    if (idcultivo !== 0) {
      api
        .post("api/cultivo/all_tipo_cultivo", {
          data: { idcultivo: idcultivo },
        })
        .then((response) => {
          setVariedadList(response.data);
        })
        .catch((error) => {
          console.log(error);
        });

      obtenerTamanoxTipo();
    } else {
      setVariedadList([]);
      setListaTamanoxCultivo([]);
    }
  }, [idcultivo]);

  useEffect(() => {
    api
      .get("api/cultivo/all-cultivos-get")
      .then((response) => {
        setListCultivo(response.data);
      })
      .catch((error) => {
        if (error.response.estatus === 500) {
          Swal.fire({
            title: "Error",
            icon: "error",
            text: "Oh oh, algo salio mal, favor de contactar con el area de desarrollo para solucionar el problema",
          });
        }
      });
  }, []);

  return (
    <Card className="my-2">
      <CardHeader style={{ textAlign: "center", fontWeight: "bold" }}>
        Nuevo tamaño
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm={12} md={4}>
            <Row>
              <Col>
                <label>Cultivo</label>
                <select
                  className="form-select"
                  onChange={(e) => {
                    setIdCultivo(Number(e.target.value));
                  }}
                  value={idcultivo}
                >
                  <option defaultValue value={0}>
                    Cultivo
                  </option>
                  {listCultivo.map((item, index) => (
                    <option key={item.idcultivo} value={item.idcultivo}>
                      {item.cultivo}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <label>Calibre</label>
                <input
                  className="form-control"
                  name="tamano"
                  id="tamano"
                  placeholder=""
                  onChange={(e) => setTamano(e.target.value)}
                  value={tamano}
                />
              </Col>
            </Row>
            <Row>
              <Col className="d-grid">
                <button
                  className="btn btn-success"
                  onClick={(e) => pushTamano()}
                  style={{ marginTop: "25px" }}
                >
                  <i>
                    <FontAwesomeIcon icon="fa-solid fa-plus" />
                  </i>{" "}
                  Agregar
                </button>
              </Col>
            </Row>
          </Col>
          <Col
            style={{
              border: "1px solid",
              maxHeight: "500px",
              overflow: "scroll",
              marginBottom: "20px",
              marginTop: "20px",
            }}
            sm={12}
            md={4}
          >
            {listVariedad.map((item, index) => (
              <div key={item.tipo}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={item.idtipo}
                  id={`${item.tipo}-check`}
                  onChange={(e) => ArrVariedad(e, index, e)}
                />{" "}
                <label htmlFor="flexCheckDefault">{item.tipo}</label>
              </div>
            ))}
          </Col>
          <Col sm={12} md={4}>
            {idcultivo !== 0 && (
              <div
                className="table-responsive-sm"
                style={{ maxHeight: "500px", overflowY: "scroll" }}
              >
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Variedad</th>
                      <th>Tamaño</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listaTamanoxCultivo.map((item, i) => (
                      <tr key={i}>
                        <td>{item.variedad}</td>
                        <td>{item.tamano}</td>
                        <td>
                          <button
                            className={
                              item.estatus === 1
                                ? "btn btn-outline-danger btn-sm"
                                : "btn btn-outline-success btn-sm"
                            }
                            onClick={() =>
                              deleteTamano(item.tamano, item.estatus)
                            }
                          >
                            <FontAwesomeIcon icon="fas fa-power-off" />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default InsertarTamano;
