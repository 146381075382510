import React, { useEffect } from "react";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { useCampoContext } from "../../Context/CampoAuth";
import { useAppContext } from "../../Context/UseAuth.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import "react-pro-sidebar/dist/css/styles.css";
import { useState } from "react";
library.add(fab, fas, far);

const LinkItem = ({ url, icon, label }) => {
  return (
    <MenuItem icon={<FontAwesomeIcon icon={icon} />}>
      <Link to={url}>{label}</Link>
    </MenuItem>
  );
};

const ProsideBar = ({ access }) => {
  const [isOpen, setIsOpen] = useState(false);
  const campoContext = useCampoContext();
  const appContext = useAppContext();

  const Logout = () => {
    localStorage.clear();
    appContext.setIsLoggedin(false);
    window.location.href = "/";
  };

  const SelectCampoHandeler = () => {
    localStorage.removeItem("codigo_campo");
    localStorage.removeItem("Campo");
    campoContext.setSelectedCampo(0);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleMediaQuery = (mq) => setIsOpen(mq.matches);

    handleMediaQuery(mediaQuery); // Establecemos el estado inicial
    mediaQuery.addEventListener("change", handleMediaQuery); // Nos suscribimos a los cambios en el media query

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQuery); // Nos desuscribimos cuando el componente se desmonta
    };
  }, []);

  return (
    <ProSidebar
      style={{ height: "100vh", position: "sticky", top: "0px" }}
      collapsed={isOpen}
    >
      <SidebarHeader>
        <div style={{ float: "right", margin: "10px" }}>
          {!isOpen && localStorage.getItem("Campo")}
          <FontAwesomeIcon
            icon="fa-solid fa-bars"
            style={{ marginLeft: "15px", cursor: "pointer" }}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        </div>
      </SidebarHeader>
      <Menu iconShape="square">
        <SubMenu
          title="Usuario"
          icon={<FontAwesomeIcon icon="fa-solid fa-user" />}
        >
          <MenuItem
            icon={<FontAwesomeIcon icon="fa-solid fa-map-location-dot" />}
            onClick={() => SelectCampoHandeler()}
          >
            Seleccionar campo
          </MenuItem>
          <MenuItem
            icon={
              <FontAwesomeIcon icon="fa-solid fa-arrow-right-from-bracket" />
            }
            onClick={() => Logout()}
          >
            Cerrar sesión
          </MenuItem>
        </SubMenu>

        {access.map((parentAccess) => {
          const { modulo } = parentAccess;
          if (modulo.parent_id === null) {
            const childs = access.filter(
              (item) => item.modulo.parent_id === modulo.id
            );

            if (childs.length === 0) {
              return (
                <LinkItem
                  key={modulo.id}
                  url={modulo.url}
                  label={modulo.name}
                  icon={modulo.icon}
                />
              );
            }
            return (
              <SubMenu
                key={modulo.id}
                title={modulo.name}
                icon={<FontAwesomeIcon icon={modulo.icon} />}
              >
                {childs.map((childAccess) => (
                  <LinkItem
                    key={childAccess.modulo.url}
                    url={childAccess.modulo.url}
                    label={childAccess.modulo.name}
                    icon={childAccess.modulo.icon}
                  />
                ))}
              </SubMenu>
            );
          }
          return null;
        })}
      </Menu>
    </ProSidebar>
  );
};

export default ProsideBar;
