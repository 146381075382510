import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useModulos } from "../../CustomHooks/useModulos";
import { useUserAccess } from "../../CustomHooks/useUserAccess";
import { useUsers } from "../../CustomHooks/useUsers";
import { useFormik } from "formik";
import { userSelectValidationSchema } from "../../validations";
import { ErrorMessage } from "../../Component/ErrorMessage";
import api from "../../Component/axios";
import Swal from "sweetalert2";

export function Permisos() {
  const { users } = useUsers();
  const { modulos } = useModulos();

  const [moduloList, setModuloList] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  const clearData = () => {
    formik.resetForm();
  };

  const createAccess = async (userId, accessToAdd) => {
    await accessToAdd.reduce(async (prev, modulo) => {
      await prev;

      await api.post("api/permisos/accesos/", {
        user: userId,
        modulo: modulo.id,
      });
    }, Promise.resolve());
  };

  const removeAccess = async (userId, accessToRemove) => {
    await accessToRemove.reduce(async (prev, access) => {
      await prev;

      await api.delete(`api/permisos/accesos/${access.id}/`);
    }, Promise.resolve());
  };

  const handleSubmit = async (formikValues) => {
    setIsSaving(true);

    const userId = formikValues.user;
    const accessToAdd = moduloList
      .filter((modulo) => modulo.isChecked)
      .filter(
        (modulo) =>
          userAccess.some((access) => access.modulo.id === modulo.id) === false
      );

    const accessToRemove = userAccess.filter(
      (access) =>
        moduloList
          .filter((modulo) => !modulo.isChecked)
          .some((modulo) => modulo.id === access.modulo.id) === true
    );

    try {
      if (accessToAdd.length > 0) {
        await createAccess(userId, accessToAdd);
      }
      if (accessToRemove.length > 0) {
        await removeAccess(userId, accessToRemove);
      }
      Swal.fire({
        position: "center",
        icon: "success",
        title: `¡Permisos guardados correctamente!`,
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: `No fue posible guardar los permisos`,
        showConfirmButton: false,
        timer: 2500,
      });
    }

    clearData();
    setIsSaving(false);
  };

  const formik = useFormik({
    initialValues: {
      user: "",
    },
    onSubmit: handleSubmit,
    validationSchema: userSelectValidationSchema,
    enableReinitialize: true,
  });

  const { userAccess } = useUserAccess(formik.values.user);

  const handleSelect = (index, isChecked) => {
    setModuloList((prev) => {
      const selectedItem = prev[index];
      selectedItem.isChecked = isChecked;

      if (isChecked && selectedItem.parent_id) {
        const parentIndex = modulos.findIndex(
          (item) => item.id === selectedItem.parent_id
        );
        prev[parentIndex].isChecked = true;
      }

      if (!isChecked && !selectedItem.parent_id) {
        modulos.forEach((modulo, index) => {
          if (modulo.parent_id !== selectedItem.id) {
            return;
          }
          prev[index].isChecked = false;
        });
      }

      return [...prev];
    });
  };

  const handleSelectAll = (isChecked) => {
    setModuloList(moduloList.map((modulo) => ({ ...modulo, isChecked })));
  };

  useEffect(() => {
    const initialAccessList = modulos.map((item) => {
      const moduloId = item.id;

      return {
        ...item,
        isChecked: userAccess.some((item) => item.modulo.id === moduloId),
      };
    });
    setModuloList([...initialAccessList]);
  }, [modulos, userAccess]);

  return (
    <Card className="my-2" style={{ height: "100%" }}>
      <Card.Header className="text-center">Administrar permisos</Card.Header>
      <Card.Body>
        <Row>
          <Col md={4} sm={12}>
            <form onSubmit={formik.handleSubmit}>
              {formik.errors.user && formik.touched.user && (
                <ErrorMessage>{formik.errors.user}</ErrorMessage>
              )}
              <select
                name="user"
                value={formik.values.user}
                onChange={formik.handleChange}
                className={`form-select my-2 ${
                  formik.errors.user && formik.touched.user && "is-invalid"
                }`}
              >
                <option disabled={formik.values.user}>
                  Seleccionar usuario...
                </option>
                {users.map((user) => (
                  <option
                    key={user.id}
                    value={user.id}
                    label={`(${user.username}) ${user.first_name} ${user.last_name}`}
                  />
                ))}
              </select>
              <Button type="submit" className="my-2" disabled={isSaving}>
                {isSaving ? "Guardando..." : "Guardar"}
              </Button>
            </form>
          </Col>
          <Col>
            {formik.values.user && (
              <div className="etiquetas-container">
                <div className="form-check my-2">
                  <input
                    onChange={(event) => {
                      handleSelectAll(event.target.checked);
                    }}
                    className="form-check-input"
                    type="checkbox"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Seleccionar todos
                  </label>
                </div>
                <hr class="hr my-0" />

                {moduloList.map((modulo, index) => {
                  return (
                    <div
                      key={modulo.id}
                      style={modulo.level === 1 ? { marginLeft: 54 } : null}
                      className={`form-check`}
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 8 }}
                        icon={modulo.icon}
                      />
                      <input
                        onChange={(event) =>
                          handleSelect(index, event.target.checked)
                        }
                        checked={modulo.isChecked}
                        className="form-check-input"
                        type="checkbox"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault"
                      >
                        {modulo.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
