import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { ErrorMessage } from "../../Component/ErrorMessage";
import api from "../../Component/axios";
import Swal from "sweetalert2";

import { userValidationSchema } from "../../validations";
import { useUsers } from "../../CustomHooks/useUsers";
import { useCampos } from "../../CustomHooks/useCampos";
import { useCamposUser } from "../../CustomHooks/useCamposUser";

export function Users() {
  const { users, fetchUsers } = useUsers();
  const { campos } = useCampos();

  const [selectedUser, setSelectedUser] = useState(null);
  const { camposUser } = useCamposUser(selectedUser?.id);

  const [searchValue, setSearchValue] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);

  const [showBanned, setShowBanned] = useState(false);

  const token = localStorage.getItem("@Token");

  let filteredUsers = users.filter((user) =>
    `${user.username}${user.first_name}${user.last_name}`
      .toLowerCase()
      .includes(searchValue.toLowerCase())
  );

  if (showBanned) {
    filteredUsers = filteredUsers.filter((user) => user.is_active === true);
  }

  const toggleActivate = async (user, isActive) => {
    const alert = await Swal.fire({
      title: `¿Está seguro que deseas ${
        isActive ? "desactivar" : "activar"
      } a ${user.username}?`,
      showDenyButton: true,
      denyButtonText: `No, cancelar`,
      confirmButtonText: `Si, ${isActive ? "desactivar" : "activar"}`,
      denyButtonColor: "#6c757d",
      confirmButtonColor: isActive ? "#dc3545" : "#198754",
    });
    if (!alert.isConfirmed) {
      return;
    }

    setIsLoading(true);
    try {
      await api.put(
        `api/usuarios/${user.id}/`,
        { ...user, is_active: !isActive },
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      );

      setTimeout(async () => {
        fetchUsers();
        Swal.fire({
          position: "center",
          icon: "success",
          title: `¡Usuario ${
            isActive ? "desactivado" : "activado"
          } exitosamente!`,
          showConfirmButton: false,
          timer: 2500,
        });
      }, 1000);
    } catch (error) {
      Swal.fire({
        title: `No fue posible desactivar a ese usuario`,
        icon: "error",
        text: "algo salio mal! \n Favor de contactar con el equipo de desarrollo",
      });
    }
    setIsLoading(false);
  };

  const addCampo = async (camposToAdd, userId) => {
    camposToAdd.reduce(async (prev, campoId) => {
      await prev;

      await api.post("api/permisos/usuario-campo/", {
        idcampo: campoId,
        idusuario: userId,
        idusuario_registro: JSON.parse(localStorage.getItem("@usuario")).id,
        estatus: 1,
      });
    }, Promise.resolve());
  };

  const removeCampo = async (campoToRemove) => {
    campoToRemove.reduce(async (prev, campoId) => {
      await prev;

      await api.delete(`api/permisos/usuario-campo/${campoId.pk}/`);
    }, Promise.resolve());
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const data = {
      ...values,
      first_name: values.firstName,
      last_name: values.lastName,
    };
    const url = "api/usuarios/";

    try {
      let res = null;

      if (selectedUser) {
        res = await api.put(`${url}${selectedUser.id}/`, data, {
          headers: {
            Authorization: "Token " + token,
          },
        });
      } else {
        res = await api.post(url, data, {
          headers: {
            Authorization: "Token " + token,
          },
        });
      }

      const camposToAdd = values.campos.filter(
        (campoToAdd) =>
          camposUser.some(
            (campo) => campo.idcampo.toString() === campoToAdd
          ) === false
      );

      if (camposToAdd.length > 0) {
        if (selectedUser) {
          await addCampo(camposToAdd, selectedUser.id);
        } else {
          await addCampo(camposToAdd, res.data.id);
        }
      }

      if (selectedUser) {
        const camposToRemove = camposUser.filter(
          (campoUser) =>
            values.campos.some(
              (campoToRemove) => campoToRemove === campoUser.idcampo.toString()
            ) === false
        );

        if (camposToRemove.length > 0) {
          await removeCampo(camposToRemove, selectedUser.id);
        }
      }

      setTimeout(async () => {
        await fetchUsers();
        Swal.fire({
          position: "center",
          icon: "success",
          title: `¡Usuario ${
            selectedUser ? "editado" : "creado"
          } exitosamente!`,
          showConfirmButton: false,
          timer: 2500,
        });
      }, 1000);
    } catch (error) {
      Swal.fire({
        title: `No fue posible ${
          selectedUser ? "editar" : "crear"
        } a ese usuario`,
        icon: "error",
        text: "algo salio mal! \n Favor de contactar con el equipo de desarrollo",
      });
    }

    setTimeout(() => {
      setIsLoading(false);
      closeModal();
    }, 1000);
  };

  const formik = useFormik({
    initialValues: {
      username: "",
      campos: [],
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: userValidationSchema,
    onSubmit: handleSubmit,
  });

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedUser(null);
    formik.resetForm();
    setIsUpdatingPassword(false);
  };

  const handleEditClick = (user) => {
    setIsModalVisible(true);
    setSelectedUser({ ...user });
    formik.setValues({
      ...user,
      firstName: user.first_name,
      lastName: user.last_name,
      confirmPassword: user.password,
    });
  };

  const handleCancelClick = () => {
    closeModal();
  };

  useEffect(() => {
    formik.setFieldValue(
      "campos",
      camposUser.map((campo) => campo.idcampo)
    );
  }, [camposUser]);

  return (
    <main
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        height: "100vh",
        padding: 20,
      }}
    >
      <Card className="mt-4">
        <Card.Header className="text-center">Usuarios</Card.Header>
        <Card.Body>
          <Form className="row my-4">
            <Row>
              <Col md={6} lg={4}>
                <Form.Control
                  value={searchValue}
                  onChange={(event) => {
                    setSearchValue(event.target.value);
                  }}
                  placeholder="Buscar..."
                />
              </Col>

              <Col>
                <Button
                  onClick={() => {
                    setIsModalVisible(true);
                  }}
                  style={{ float: "right" }}
                  variant="success"
                >
                  <FontAwesomeIcon icon="fa-solid fa-plus" /> Crear
                </Button>
              </Col>
            </Row>
          </Form>

          <Button onClick={() => setShowBanned(!showBanned)} className="my-2">
            Mostrar/Ocultar inactivos
          </Button>

          <Table className="table-responsive">
            <thead className="table-light">
              <tr>
                <th>Usuario</th>
                <th>Nombre</th>
                <th>Apellido</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user) => (
                <tr key={user.id}>
                  <td>{user.username}</td>
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  <td>
                    <button
                      className="btn btn-primary btn-block btn-sm"
                      onClick={() => handleEditClick(user)}
                    >
                      <FontAwesomeIcon icon="fa-solid fa-pen" />
                    </button>
                    <button
                      className={`btn ${
                        user.is_active ? "btn-danger" : "btn-success"
                      } btn-block btn-sm mx-1`}
                      onClick={() => toggleActivate(user, user.is_active)}
                    >
                      <FontAwesomeIcon
                        icon={`fa-solid ${
                          user.is_active ? "fa-ban" : "fa-check"
                        }`}
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal show={isModalVisible} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedUser ? "Editar" : "Crear"} usuario</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3">
              {formik.errors.username && formik.touched.username && (
                <ErrorMessage>{formik.errors.username}</ErrorMessage>
              )}
              <Form.Label>Nombre de usuario</Form.Label>
              <Form.Control
                id="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                type="text"
                className={
                  formik.errors.username &&
                  formik.touched.username &&
                  "is-invalid"
                }
              />
            </Form.Group>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  {formik.errors.firstName && formik.touched.firstName && (
                    <ErrorMessage>{formik.errors.firstName}</ErrorMessage>
                  )}
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    id="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    type="text"
                    className={
                      formik.errors.firstName &&
                      formik.touched.firstName &&
                      "is-invalid"
                    }
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  {formik.errors.lastName && formik.touched.lastName && (
                    <ErrorMessage>{formik.errors.lastName}</ErrorMessage>
                  )}
                  <Form.Label>Apellido</Form.Label>
                  <Form.Control
                    id="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    type="text"
                    className={
                      formik.errors.lastName &&
                      formik.touched.lastName &&
                      "is-invalid"
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label>Campos</Form.Label>
              {formik.errors.campos && (
                <ErrorMessage>{formik.errors.campos}</ErrorMessage>
              )}
              <Form.Select
                id="campos"
                value={formik.values.campos}
                onChange={formik.handleChange}
                className={formik.errors.campos && "is-invalid"}
                style={{ height: 130 }}
                multiple
              >
                {campos.map((campo) => (
                  <option value={campo.idcampo} key={campo.idcampo}>
                    {campo.campo}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            {selectedUser && (
              <Form.Group className="mb-3">
                <Form.Check
                  value={isUpdatingPassword}
                  onChange={() => {
                    setIsUpdatingPassword((prev) => {
                      if (prev === false) {
                        formik.setFieldValue("password", "");
                        formik.setFieldValue("confirmPassword", "");
                      } else {
                        formik.setFieldValue("password", selectedUser.password);
                        formik.setFieldValue(
                          "confirmPassword",
                          selectedUser.password
                        );
                      }
                      return !prev;
                    });
                  }}
                  type="checkbox"
                  id="changePassCheck"
                  label="Cambiar contraseña"
                />
              </Form.Group>
            )}

            <Form.Group className="mb-3" id="password">
              {formik.errors.password && formik.touched.password && (
                <ErrorMessage>{formik.errors.password}</ErrorMessage>
              )}
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                disabled={!isUpdatingPassword && selectedUser}
                id="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                type="password"
                className={
                  formik.errors.password &&
                  formik.touched.password &&
                  "is-invalid"
                }
              />
            </Form.Group>

            <Form.Group className="mb-3">
              {formik.errors.confirmPassword &&
                formik.touched.confirmPassword && (
                  <ErrorMessage>{formik.errors.confirmPassword}</ErrorMessage>
                )}
              <Form.Label>Confirmar contraseña</Form.Label>
              <Form.Control
                disabled={!isUpdatingPassword && selectedUser}
                name="confirmPassword"
                id="confirmPassword"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                type="password"
                className={
                  formik.errors.confirmPassword &&
                  formik.touched.confirmPassword &&
                  "is-invalid"
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={handleCancelClick} variant="secondary">
            Cancelar
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => {
              formik.handleSubmit();
            }}
            type="submit"
            variant={selectedUser ? "primary" : "success"}
          >
            {isLoading ? " Enviando..." : selectedUser ? " Editar" : " Crear"}
          </Button>
        </Modal.Footer>
      </Modal>
    </main>
  );
}
