import React, { useState, useContext, createContext, useEffect } from "react";

const CampoContext = createContext({
  selecedCampo: [],
});

export const AppProvider = ({ children }) => {
  const [selectedCampo, setSelectedCampo] = useState(0);

  const setCampoState = async (codigoCampo) => {
    try {
      localStorage.setItem("codigo_campo", codigoCampo);
    } catch {}
  };

  const getCampoState = async () => {
    try {
      const result = localStorage.getItem("codigo_campo");
      if (result) {
        return result;
      }
    } catch {}
    return 0;
  };

  useEffect(() => {
    const fetchCampoState = async () => {
      const data = await getCampoState();
      if (data) {
        setSelectedCampo(data);
      }
    };

    fetchCampoState();
  }, []);

  return (
    <CampoContext.Provider
      value={{ selectedCampo, setSelectedCampo, setCampoState }}
    >
      {children}
    </CampoContext.Provider>
  );
};

export const useCampoContext = () => useContext(CampoContext);
