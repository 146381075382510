import { useEffect, useState } from "react";
import api from "../Component/axios";

export function useCamposUser(userId) {
  const [camposUser, setCamposUser] = useState([]);

  useEffect(() => {
    const fetchCamposUser = async () => {
      const token = localStorage.getItem("@Token");
      try {
        const res = await api.post(
          "api/permisos/get/campos-user/",
          { idusuario: userId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + token,
            },
          }
        );
        setCamposUser([...res.data]);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCamposUser();
  }, [userId]);

  return { camposUser };
}
