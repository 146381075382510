import { useCallback, useEffect, useState } from "react";
import api from "../Component/axios";

export function useUsers() {
  const token = localStorage.getItem("@Token");

  const [users, setUsers] = useState([]);

  const fetchUsers = useCallback(async () => {
    try {
      const res = await api.get("api/usuarios/", {
        headers: {
          Authorization: "Token " + token,
        },
      });
      setUsers([...res.data]);
    } catch (error) {}
  }, [token]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return { users, fetchUsers };
}
