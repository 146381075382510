import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import api from "../../Component/axios";

const AgregarEtiqueta = () => {
  const [etiquetaList, setEtiquetaList] = useState([]);
  const [etiqueta, setEtiqueta] = useState("");

  const getEtiquetas = () => {
    api.get("api/etiquetas/get/all-etiqueta").then((response) => {
      setEtiquetaList(response.data);
    });
  };

  const insertEtiqueta = () => {
    const user = localStorage.getItem("@usuario");
    const json_user = JSON.parse(user);
    if (etiqueta === "") {
      Swal.fire({
        icon: "info",
        title: "Favor de ingresar la etiqueta",
      });
      return;
    }

    api
      .post("api/etiquetas/get/all-etiqueta", {
        data: {
          etiqueta: etiqueta,
          id_usuario: json_user.id,
        },
      })
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: response.data.msg,
          timer: 1500,
        });
        getEtiquetas();
        setEtiqueta("");
      })
      .catch((error) => {
        Swal.fire({
          icon: "info",
          title: error.response.data.msg,
        });
      });
  };

  const updateEtiqueta = (id_etiqueta, estatus) => {
    api
      .put("api/etiquetas/get/all-etiqueta", {
        data: {
          id_etiqueta: id_etiqueta,
          estatus: estatus === 1 ? 0 : 1,
        },
      })
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: response.data.msg,
          timer: 1500,
        });
        getEtiquetas();
      });
  };

  useEffect(() => {
    getEtiquetas();
  }, []);

  return (
    <Card className="m-2">
      <CardHeader style={{ textAlign: "center", fontWeight: "bold" }}>
        Agregar Etiqueta
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={3} sm={12}>
            <div style={{ display: "flex", gap: "10px" }}>
              <input
                className="form-control"
                value={etiqueta}
                onChange={(e) => {
                  setEtiqueta(e.target.value);
                }}
              />
              <button
                className="btn btn-success"
                onClick={() => {
                  insertEtiqueta();
                }}
              >
                Guardar
              </button>
            </div>
          </Col>
          <Col md={3} sm={12}>
            <div className="table-responsive" style={{ maxHeight: "600px" }}>
              <table className="table">
                <thead>
                  <tr>
                    <th>Etiqueta</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  {etiquetaList.map((item) => (
                    <tr key={item.idetiqueta}>
                      <td>{item.etiqueta}</td>
                      <td>
                        <button
                          className={
                            item.active === 1
                              ? "btn btn-success"
                              : "btn btn-danger"
                          }
                          onClick={() => {
                            updateEtiqueta(item.idetiqueta, item.active);
                          }}
                        >
                          <FontAwesomeIcon icon="fas fa-power-off" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default AgregarEtiqueta;
