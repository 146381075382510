import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../Component/axios";
import FileSaver from "file-saver";
import moment from "moment";
import Swal from "sweetalert2";

const ConsolidadoPedido = () => {
  const [form, setForm] = useState({
    fecha: moment().add(1, "day").format("YYYY-MM-DD"),
  });
  const [empaque, setEmpaque] = useState([]);
  const [loading, setLoading] = useState(false);

  const downloadExcel = () => {
    const token = localStorage.getItem("@Token");
    const user = localStorage.getItem("@usuario");
    const json_user = JSON.parse(user);
    setLoading(true);
    api
      .get(
        `api/reportes/reporte/?fecha=${encodeURIComponent(
          form.fecha
        )}&id_usuario=${encodeURIComponent(json_user.id)}`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const fileNameHeader = "x-suggested-filename";
        const suggestedFileName = response.headers[fileNameHeader];
        const effectiveFileName =
          suggestedFileName === undefined
            ? "Reporte consolidado de pedidos - " + form.fecha + ".xls"
            : suggestedFileName;

        FileSaver.saveAs(response.data, effectiveFileName);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (form.fecha !== "") {
      Swal.showLoading();
      const token = localStorage.getItem("@Token");
      const user = localStorage.getItem("@usuario");
      const json_user = JSON.parse(user);
      api
        .get(
          `api/reportes/consolidado-de-pedidos/?fecha=${form.fecha}&id_usuario=${json_user.id}`,
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then((response) => {
          Swal.close();
          setEmpaque(response.data);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Algo salio mal!",
            text: "Favor de comunicarlo con el equipo de desarrollo",
          });
          console.log(error);
        });
    }
  }, [form]);

  return (
    <Card className="my-2">
      <CardHeader style={{ textAlign: "center", fontWeight: "bold" }}>
        Consolidado de pedidos
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={3} style={{ display: "flex", gap: "4px" }}>
            <input
              className="form-control"
              type="date"
              value={form.fecha}
              onChange={(e) => {
                setForm({
                  ...form,
                  fecha: e.target.value,
                });
              }}
            />
            <button
              className="btn btn-outline-success"
              onClick={
                loading
                  ? null
                  : () => {
                      downloadExcel();
                    }
              }
            >
              {loading ? (
                <FontAwesomeIcon icon="fas fa-spinner" spin />
              ) : (
                <FontAwesomeIcon icon="far fa-file-excel" />
              )}
            </button>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Variedad</th>
                  <th>Calibre</th>
                  <th>Og/Cv</th>
                  <th>Etiqueta</th>
                  <th>Peso</th>
                  <th>Envase</th>
                  <th>Cant</th>
                  <th>Estiba</th>
                  <th>Cajas</th>
                  <th>Yegueres</th>
                  <th>País destino</th>
                  <th>Destino inmediato</th>
                  <th>Tarima</th>
                  <th>Tipo pedido</th>
                  <th>Fumigar</th>
                  <th>Campos</th>
                </tr>
              </thead>
              <tbody>
                {empaque.map((item, i) => (
                  <tr key={i}>
                    <td>{item.variedad}</td>
                    <td>{item.calibre}</td>
                    <td>{item.ogcv}</td>
                    <td>{item.etiqueta}</td>
                    <td>{item.peso}</td>
                    <td>{item.envase}</td>
                    <td>{item.cantidad}</td>
                    <td>{item.estiba}</td>
                    <td>{item.cajas}</td>
                    <td>{item.yeguere}</td>
                    <td>{item.pais_destino}</td>
                    <td>{item.destino_inmediato}</td>
                    <td>{item.tarima}</td>
                    <td>{item.tipo_pedido}</td>
                    <td>{item.fumigar === 0 ? "No" : "Si"}</td>
                    <td>{item.campo}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ConsolidadoPedido;
