import React, { useState } from "react";
import "./styles.css";

export const Tooltip = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <label
      className="tooltip-container"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {!showTooltip && (
        <div className="tooltip">
          <div className="tooltip-text">{text}</div>
          <div className="tooltip-arrow"></div>
        </div>
      )}
    </label>
  );
};
