import { useEffect, useState } from "react";
import api from "../Component/axios";

export function useModulos() {
  const [modulos, setModulos] = useState([]);

  const fetchAccess = async () => {
    try {
      const res = await api.get(`api/permisos/modulos/`);
      setModulos([...res.data]);
    } catch (error) {}
  };

  useEffect(() => {
    fetchAccess();
  }, []);

  return { modulos };
}
