import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Swal from "sweetalert2";
import api from "../../Component/axios";
library.add(fab, fas, far);

const ReporteProyeccionTemporada = () => {
  const [form, setForm] = useState({
    cultivo: 0,
    temporada: "",
  });
  const [tableContent, setTableContent] = useState([]);
  const [tamanoList, setTamanoList] = useState([]);
  const [cultivoList, setCultivoList] = useState([]);

  const getData = (cultivo, temporada) => {
    if (temporada === "" || cultivo === 0) {
      setTableContent([]);
      return;
    }
    Swal.showLoading();

    api
      .get(
        `api/temporada/proyeccion-temporada/?id_campo=${localStorage.getItem(
          "codigo_campo"
        )}&id_cultivo=${cultivo}&temporada=${temporada}`
      )
      .then((response) => {
        setTableContent(response.data);
      })
      .finally(() => {
        Swal.close();
      });
  };

  useEffect(() => {
    const ObtenerCultivoxCampo = async () => {
      const idcampo = localStorage.getItem("codigo_campo");
      const token = localStorage.getItem("@Token");
      try {
        const response = await api.post(
          "api/cultivo/get/cultivo-por-campo",
          { data: idcampo },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + token,
            },
          }
        );
        const data = response.data;

        if (data.length === 1) {
          setForm({
            ...form,
            cultivo: data[0].idcultivo,
            temporada: localStorage.getItem("temporada")
              ? localStorage.getItem("temporada")
              : "",
          });
          getData(
            data[0].idcultivo,
            localStorage.getItem("temporada")
              ? localStorage.getItem("temporada")
              : ""
          );
        }
        setCultivoList(data);
      } catch (error) {
        console.log(error);
      }
    };

    ObtenerCultivoxCampo();
  }, []);

  useEffect(() => {
    if (form.cultivo !== 0) {
      api
        .get(`api/temporada/tamanos?id_cultivo=${form.cultivo}`)
        .then((response) => {
          setTamanoList(response.data);
        });
    }
  }, [form.cultivo]);

  return (
    <Card className="my-2">
      <CardHeader>Reporte proyección de temporada</CardHeader>
      <CardBody>
        <div className="row">
          <div className="col-2">
            <select
              className="form-control"
              onChange={(e) => {
                setForm({
                  ...form,
                  cultivo: e.target.value,
                });
              }}
              value={form.cultivo}
            >
              <option value="0">Cultivo...</option>
              {cultivoList.map((cultivo) => (
                <option key={cultivo.idcultivo} value={cultivo.idcultivo}>
                  {cultivo.cultivo}
                </option>
              ))}
            </select>
          </div>
          <div className="col-2">
            <input
              type="number"
              className="form-control"
              placeholder="Temporada"
              value={form.temporada}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                setForm({
                  ...form,
                  temporada: e.target.value,
                });
              }}
            />
          </div>
          <div className="col-3">
            <button
              className="btn btn-primary"
              onClick={() => {
                getData(form.cultivo, form.temporada);
              }}
            >
              <FontAwesomeIcon icon="fas fa-search" /> Buscar
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12 table-responsive">
            {tableContent.length > 0 && (
              <table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Sup.</th>
                    <th>Prod/Ha.</th>
                    {tamanoList?.map((tamano) => (
                      <th key={tamano.tamano}>{tamano.tamano}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableContent?.map((variedad, variedadIndex) => (
                    <>
                      <tr key={variedad.id_variedad}>
                        <td style={{ fontWeight: "bold" }}>
                          {variedad.variedad}
                        </td>
                        <td>{variedad.superficie}</td>
                        <td>
                          {Intl.NumberFormat().format(
                            variedad.produccion_hectarea
                          )}
                        </td>
                        {variedad.tamanos?.map((tamano) => (
                          <td key={tamano.tamano}>
                            {parseFloat(
                              tamano.valor / variedad.lotes.length
                            ).toFixed(2)}{" "}
                            %
                          </td>
                        ))}
                        <td></td>
                        <td></td>
                      </tr>
                      {variedad.lotes?.map((lote) => (
                        <tr key={lote.id_lote}>
                          <td>{lote.lote}</td>
                          <td>{lote.superficie}</td>
                          <td>
                            {lote.produccion_hectarea === ""
                              ? 0
                              : lote.produccion_hectarea}
                          </td>
                          {lote.tamanos?.map((tamano) => (
                            <td key={tamano.tamano}>
                              {tamano.valor === "" ? 0 : tamano.valor}
                            </td>
                          ))}
                          <td>{lote.porcentaje_total}%</td>
                        </tr>
                      ))}
                    </>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ReporteProyeccionTemporada;
