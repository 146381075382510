import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import api from "../../Component/axios";
import moment from "moment";
import FileSaver from "file-saver";

const OrdenEmpaque = () => {
  const [form, setForm] = useState({
    fecha: moment().add(1, "day").format("YYYY-MM-DD"),
    id_campo: 0,
    id_variedad: 0,
  });
  const [tableSyle, setTableStyle] = useState(false);
  const [reporteEmpaque, setReporteEmpaque] = useState([]);
  const [variedadList, setVariedadList] = useState([]);
  const [campoList, setCampoList] = useState([]);
  const [isVisible, setIsVisible] = useState({
    excel: false,
    campos: false,
  });
  const [campoValues, setCampoValues] = useState([]);

  const buscarOrdenEmpaque = () => {
    Swal.showLoading();
    const user = localStorage.getItem("@usuario");
    const json_user = JSON.parse(user);
    let filter = ``;
    if (parseInt(form.id_campo) !== 0) {
      filter += `&id_campo=${form.id_campo}`;
    }
    if (parseInt(form.id_variedad) !== 0) {
      const variedad = variedadList.find(
        (item) => item.idtipo === parseInt(form.id_variedad)
      );
      filter += `&variedad=${variedad.tipo}`;
    }
    api
      .get(
        `api/pedido-empaque/registros-pedidos-de-empaque/all/?fecha=${form.fecha}&id_usuario=${json_user.id}${filter}`
      )
      .then((response) => {
        setReporteEmpaque(response.data);
      })
      .finally(() => {
        Swal.close();
      });
  };

  const showEmpaque = (campoIndex, variedadIndex) => {
    let arr = [...reporteEmpaque];
    let status = 1;
    if (arr[campoIndex].variedades[variedadIndex].show === 1) {
      status = 0;
    }
    arr[campoIndex].variedades[variedadIndex].show = status;
    setReporteEmpaque(arr);
  };

  const excelNormal = () => {
    const user = localStorage.getItem("@usuario");
    const json_user = JSON.parse(user);
    api
      .post(
        "api/pedido-empaque/reporte-detallado/export-excel/download",
        { data: { fecha: form.fecha, id_usuario: json_user.id } },
        { responseType: "blob" }
      )
      .then((response) => {
        const fileNameHeader = "x-suggested-filename";
        const suggestedFileName = response.headers[fileNameHeader];
        const effectiveFileName =
          suggestedFileName === undefined
            ? "OrdenEmpaque_" + form.fecha + ".xls"
            : suggestedFileName;
        FileSaver.saveAs(response.data, effectiveFileName);
        setIsVisible({
          ...isVisible,
          excel: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const excelDetallado = () => {
    if (parseInt(form.id_campo) === 0) {
      Swal.fire({
        icon: "info",
        title: "Favor de seleccionar un campo",
      });
      return;
    }
    const user = localStorage.getItem("@usuario");
    const json_user = JSON.parse(user);
    const campo = campoList.find(
      (item) => item.idcampo === parseInt(form.id_campo)
    );
    api
      .post(
        "api/pedido-empaque/detallado-reporte/export-excel/download",
        {
          data: {
            fecha: form.fecha,
            id_campo: form.id_campo,
            id_usuario: json_user.id,
            campo: campo.campo,
          },
        },
        { responseType: "blob" }
      )
      .then((response) => {
        const fileNameHeader = "x-suggested-filename";
        const suggestedFileName = response.headers[fileNameHeader];
        const effectiveFileName =
          suggestedFileName === undefined
            ? "OrdenDeEmpaque_" + campo.campo + "_" + form.fecha + ".xls"
            : suggestedFileName;
        FileSaver.saveAs(response.data, effectiveFileName);
        setIsVisible({
          ...isVisible,
          excel: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const campoSelectChangeHandler = (e) => {
    const arr = Array.from(e.target.selectedOptions);
    const selected = arr.map((item) => {
      return campoList.find((campo) => campo.idcampo === parseInt(item.value));
    });

    const temp = campoValues.filter((item) => {
      return selected.some((value) => item === value.idcampo).idcampo;
    });

    const basura = campoValues.filter((item) => {
      return !selected.some((value) => item === value.idcampo).idcampo;
    });

    basura.forEach((item) => {
      const index = temp.findIndex((value) => value === item);
      temp.splice(index, 1);
    });

    selected.forEach((item) => {
      if (!temp.includes(item.idcampo)) {
        temp.push(item.idcampo);
      }
    });
    setCampoValues(temp);
  };

  const excelProgramaDelDia = () => {
    const token = localStorage.getItem("@Token");

    api
      .post(
        "api/reportes/programa-dia/",
        {
          data: {
            fecha: form.fecha,
            id_campo: campoValues,
          },
        },
        { headers: { Authorization: `Token ${token}` }, responseType: "blob" }
      )
      .then((response) => {
        FileSaver.saveAs(response.data, `Programa del dia ${form.fecha}`);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title:
            "Algo salió mal, favor de comunicarlo con el equipo de desarrollo",
        });
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("@Token");
    const user = localStorage.getItem("@usuario");
    const json_user = JSON.parse(user);
    api
      .get("api/cultivo/response/all-tipo-cultivo/variedad", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        setVariedadList(response.data);
      });

    api
      .get(`api/permisos/get/campos-user/?id_usuario=${json_user.id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        setCampoList(response.data);
      });
  }, []);

  useEffect(() => {
    if (form.fecha !== "") {
      buscarOrdenEmpaque();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleMediaQuery = (mq) => {
      setTableStyle(mq.matches);
    };
    handleMediaQuery(mediaQuery); // Establecemos el estado inicial
    mediaQuery.addEventListener("change", handleMediaQuery); // Nos suscribimos a los cambios en el media query

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQuery); // Nos desuscribimos cuando el componente se desmonta
    };
  }, []);

  return (
    <>
      <Card className="my-2">
        <CardHeader style={{ textAlign: "center", fontWeight: "bold" }}>
          Orden de empaque
        </CardHeader>
        <CardBody>
          <Row>
            <Col md={2}>
              <input
                type="date"
                className="form-control"
                value={form.fecha}
                onChange={(e) => {
                  setForm({
                    ...form,
                    fecha: e.target.value,
                  });
                }}
              />
            </Col>
            <Col md={2}>
              <select
                className="form-control"
                value={form.id_campo}
                onChange={(e) => {
                  setForm({
                    ...form,
                    id_campo: e.target.value,
                  });
                }}
              >
                <option value="0">Campo</option>
                {campoList.map((campo) => (
                  <option key={campo.idcampo} value={campo.idcampo}>
                    {campo.campo}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={2}>
              <select
                className="form-control"
                value={form.id_variedad}
                onChange={(e) => {
                  setForm({
                    ...form,
                    id_variedad: e.target.value,
                  });
                }}
              >
                <option value={0}>Variedad</option>
                {variedadList.map((variedad) => (
                  <option key={variedad.idtipo} value={variedad.idtipo}>
                    {variedad.tipo}
                  </option>
                ))}
              </select>
            </Col>
            <Col md={2} style={{ display: "flex", gap: "10px" }}>
              <button
                className="btn btn-outline-success"
                onClick={() => {
                  setIsVisible({
                    ...isVisible,
                    excel: true,
                  });
                }}
              >
                <FontAwesomeIcon icon="fa-regular fa-file-excel" />
              </button>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              {reporteEmpaque.map((campo, campoIndex) => (
                <div
                  key={campo.id_campo}
                  style={{ width: "100%", marginBottom: "20px" }}
                >
                  <div
                    style={{
                      backgroundColor: "#F0F0F0 ",
                      padding: "3px",
                      borderRadius: "5px",
                      borderBottom: "solid",
                      borderBottomWidth: "3px",
                      borderBottomColor: "black",
                    }}
                  >
                    <h5>{campo.campo}</h5>
                  </div>
                  <br />
                  {campo.variedades.map((variedad, variedadIndex) => (
                    <div
                      key={variedad.id_variedad}
                      style={{ marginBottom: "15px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <button
                            className="btn btn-primary btn-sm"
                            style={{ borderRadius: "100px" }}
                            onClick={() =>
                              showEmpaque(campoIndex, variedadIndex)
                            }
                          >
                            {variedad.show === 0 ? (
                              <FontAwesomeIcon icon="fa-solid fa-plus" />
                            ) : (
                              <FontAwesomeIcon icon="fa-solid fa-minus" />
                            )}
                          </button>{" "}
                          {variedad.bicolor === 1 ? (
                            <label>
                              <label>{variedad.variedad}</label>{" "}
                              <FontAwesomeIcon
                                icon="fas fa-project-diagram"
                                style={{ color: "#0d73a5" }}
                              />
                            </label>
                          ) : (
                            variedad.variedad
                          )}
                        </div>
                        <label>
                          Proyeccion: <label>{variedad.proyeccion}</label>
                        </label>
                      </div>
                      {variedad.show === 1 && (
                        <div>
                          <div className="table-responsive">
                            <table
                              className="table"
                              style={!tableSyle ? { tableLayout: "fixed" } : {}}
                            >
                              <thead>
                                <tr style={{ fontSize: "12px" }}>
                                  <th>N.Orden</th>
                                  <th>Calibre</th>
                                  <th>Proyección</th>
                                  <th>Og/Cv</th>
                                  <th>Etiqueta</th>
                                  <th>Peso</th>
                                  <th>Envase</th>
                                  <th>Cant.</th>
                                  <th>Estiba</th>
                                  <th>Cajas</th>
                                  <th>Yegueres</th>
                                  <th>Fumigar</th>
                                  <th>País destino</th>
                                  <th>Destino inmediato</th>
                                  <th>Tarima</th>
                                  <th>Tipo pedido</th>
                                  <th>Prioridad</th>
                                  <th>Bolsa/Clamshell</th>
                                </tr>
                              </thead>
                              <tbody>
                                {variedad.empaque.map((empaque) => (
                                  <tr
                                    key={empaque.id_empaque}
                                    style={{ fontSize: "12px" }}
                                  >
                                    <td>{empaque.id_empaque}</td>
                                    <td>{empaque.tamano}</td>
                                    <td>{empaque.proyeccion}</td>
                                    <td>{empaque.tipo_etiqueta}</td>
                                    <td>{empaque.etiqueta}</td>
                                    <td>{empaque.peso}</td>
                                    <td>{empaque.envase}</td>
                                    <td>
                                      {empaque.resto === 1
                                        ? `Libre - ${
                                            empaque.limite + " límite" ?? ""
                                          }`
                                        : empaque.cantidad}{" "}
                                      {empaque.extra && " - Extra"}
                                    </td>
                                    <td>{empaque.estiba}</td>
                                    <td>
                                      {empaque.resto === 1
                                        ? "Libre"
                                        : empaque.caja}
                                    </td>
                                    <td>{empaque.yegueres}</td>
                                    <td>
                                      {empaque.fumigar === 0 ? "NO" : "SI"}
                                    </td>
                                    <td>{empaque.destino}</td>
                                    <td>{empaque.inmediato_destino}</td>
                                    <td>{empaque.tarima}</td>
                                    <td>{empaque.tipo_pedido}</td>
                                    <td>
                                      {empaque.prioridad === 0 ? "NO" : "SI"}
                                    </td>
                                    <td>{empaque.bolsa_clam}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Modal
        isOpen={isVisible.excel}
        toggle={() => {
          setIsVisible({
            ...isVisible,
            excel: false,
          });
        }}
        size="lg"
      >
        <ModalHeader
          toggle={() => {
            setIsVisible({
              ...isVisible,
              excel: false,
            });
          }}
        >
          Descargar Excel
        </ModalHeader>
        <ModalBody>
          <div style={styles.descarga}>
            <div>
              <div style={styles.descarga_card}>
                <FontAwesomeIcon
                  icon="fa-solid fa-file-excel"
                  color="#198754"
                />
                Reporte normal
              </div>
              <div className="d-grid">
                <button
                  className="btn btn-success"
                  onClick={() => {
                    excelNormal();
                  }}
                >
                  Descargar
                </button>
              </div>
            </div>
            <div>
              <div style={styles.descarga_card}>
                <FontAwesomeIcon
                  icon="fa-solid fa-file-excel"
                  color="#198754"
                />
                Programa del día
              </div>
              <div className="d-grid">
                <button
                  className="btn btn-success"
                  onClick={() => {
                    setIsVisible({
                      ...isVisible,
                      campos: true,
                    });
                  }}
                >
                  Descargar
                </button>
              </div>
            </div>
            <div>
              <div style={styles.descarga_card}>
                <FontAwesomeIcon
                  icon="fa-solid fa-file-excel"
                  color="#198754"
                />
                Reporte detallado
              </div>
              <div className="d-grid">
                <button
                  className="btn btn-success"
                  onClick={() => {
                    excelDetallado();
                  }}
                >
                  Descargar
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={isVisible.campos}
        toggle={() => {
          setIsVisible({
            ...isVisible,
            campos: false,
          });
        }}
      >
        <ModalHeader
          toggle={() => {
            setIsVisible({
              ...isVisible,
              campos: false,
            });
          }}
        >
          Campos
        </ModalHeader>
        <ModalBody>
          <div style={{ minHeight: "150px" }}>
            <select
              multiple
              className="form-control"
              style={{ height: "100%" }}
              onChange={(e) => campoSelectChangeHandler(e)}
            >
              {reporteEmpaque.map((item) => (
                <option key={item.id_campo} value={item.id_campo}>
                  {item.campo}
                </option>
              ))}
            </select>
            <button
              onClick={() => excelProgramaDelDia()}
              className="btn btn-success mt-2"
            >
              Descargar
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const styles = {
  descarga: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  descarga_card: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    fontSize: "20px",
  },
};

export default OrdenEmpaque;
