import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Swal from "sweetalert2";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import api from "../../Component/axios";
import moment from "moment";
library.add(fab, fas, far);

const ProyeccionTemporada = () => {
  const [cultivoList, setCultivoList] = useState([]);
  const [tamanoList, setTamanoList] = useState([]);

  const [tableContent, setTableContent] = useState([]);

  const [form, setForm] = useState({
    temporada: moment().year(),
    cultivo: 0,
  });

  const [isVisible, setIsVisible] = useState(false);
  const [bitacoraContent, setBitacoraContent] = useState([]);

  const getData = () => {
    Swal.showLoading();
    api
      .get(
        `api/temporada/proyeccion-temporada/?id_campo=${localStorage.getItem(
          "codigo_campo"
        )}&id_cultivo=${form.cultivo}&temporada=${form.temporada}`
      )
      .then((response) => {
        setTableContent(response.data);
      })
      .finally(() => {
        Swal.close();
      });
  };

  const tamanoChangeHandler = (
    variedadIndex,
    loteIndex,
    tamanoIndex,
    value
  ) => {
    let arr = [...tableContent];
    let total = 0;
    let porcentajeVariedad = 0;

    arr[variedadIndex].lotes[loteIndex].tamanos[tamanoIndex].valor =
      value.target.value;

    for (let index in arr[variedadIndex].lotes[loteIndex].tamanos) {
      let valor = arr[variedadIndex].lotes[loteIndex].tamanos[index].valor;
      if (valor === "") {
        valor = 0;
      }
      total += parseFloat(valor);
      if (total > 100) {
        total -= parseFloat(
          arr[variedadIndex].lotes[loteIndex].tamanos[tamanoIndex].valor
        );
        arr[variedadIndex].lotes[loteIndex].tamanos[tamanoIndex].valor = 0;
        break;
      }
    }

    for (let index in arr[variedadIndex].lotes) {
      let valor = arr[variedadIndex].lotes[index].tamanos[tamanoIndex].valor;
      if (valor === "") {
        valor = 0;
      }
      porcentajeVariedad += parseInt(valor);
    }

    arr[variedadIndex].tamanos[tamanoIndex].valor = porcentajeVariedad;
    arr[variedadIndex].lotes[loteIndex].porcentaje_total = `${total}`;

    setTableContent(arr);
  };

  const produccionChangeHandler = (variedadIndex, loteIndex, e) => {
    let arr = [...tableContent];
    let total = 0;
    arr[variedadIndex].lotes[loteIndex].produccion_hectarea = e.target.value;
    for (let index in arr[variedadIndex].lotes) {
      let valor = arr[variedadIndex].lotes[index].produccion_hectarea;
      if (valor === "") {
        valor = 0;
      }
      total += parseInt(valor);
    }
    arr[variedadIndex].produccion_hectarea = total;
    setTableContent(arr);
  };

  const validateData = () => {
    let error = false;
    let lote = "";
    for (let variedadIndex in tableContent) {
      for (let loteIndex in tableContent[variedadIndex].lotes) {
        if (
          tableContent[variedadIndex].lotes[loteIndex].produccion_hectarea !==
            "" &&
          parseInt(
            tableContent[variedadIndex].lotes[loteIndex].produccion_hectarea
          ) !== 0
        ) {
          if (
            parseInt(
              tableContent[variedadIndex].lotes[loteIndex].porcentaje_total
            ) < 100
          ) {
            error = true;
            lote = tableContent[variedadIndex].lotes[loteIndex].lote;
            break;
          }
        }
      }
    }
    if (error) {
      Swal.fire({
        icon: "info",
        title: "Porcentaje no alcanzado",
        text: "El porcentaje total en el lote " + lote + " no es del 100%",
      });
      return;
    }

    saveData();
  };

  const saveData = () => {
    Swal.showLoading();
    const user = localStorage.getItem("@usuario");
    const json_user = JSON.parse(user);
    api
      .post("api/temporada/proyeccion-temporada/", {
        data: {
          captura: tableContent,
          temporada: form.temporada,
          id_campo: localStorage.getItem("codigo_campo"),
          id_usuario: json_user.id,
        },
      })
      .then((response) => {
        if (Swal.isLoading()) {
          Swal.hideLoading();
        }
        Swal.fire({
          icon: "success",
          title: response.data.msg,
          timer: 1500,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        if (Swal.isLoading()) {
          Swal.hideLoading();
        }
        Swal.fire({
          icon: "error",
          title: "Algo salio mal!",
        });
      });
  };

  const getBitacora = (id_lote) => {
    const user = localStorage.getItem("@usuario");
    const json_user = JSON.parse(user);
    api
      .get(
        `api/temporada/bitacora/?temporada=${
          form.temporada
        }&id_campo=${localStorage.getItem(
          "codigo_campo"
        )}&id_lote=${id_lote}&id_usuario=${json_user.id}`
      )
      .then((response) => {
        setBitacoraContent(response.data);
      })
      .finally(() => {
        setIsVisible(true);
      });
  };

  useEffect(() => {
    const ObtenerCultivoxCampo = async () => {
      const idcampo = localStorage.getItem("codigo_campo");
      const token = localStorage.getItem("@Token");
      try {
        const response = await api.post(
          "api/cultivo/get/cultivo-por-campo",
          { data: idcampo },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + token,
            },
          }
        );
        const data = response.data;

        if (data.length === 1) {
          setForm({
            ...form,
            cultivo: data[0].idcultivo,
          });
        }
        setCultivoList(data);
      } catch (error) {
        console.log(error);
      }
    };

    ObtenerCultivoxCampo();
  }, []);

  useEffect(() => {
    if (form.cultivo !== 0) {
      api
        .get(`api/temporada/tamanos?id_cultivo=${form.cultivo}`)
        .then((response) => {
          setTamanoList(response.data);
        });
    }
  }, [form.cultivo]);

  return (
    <>
      <Card className="my-2">
        <CardHeader>Proyección de temporada</CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-3">
              <select
                value={form.cultivo}
                onChange={(value) => {
                  setForm({
                    ...form,
                    cultivo: value.target.value,
                  });
                }}
                className="form-control"
              >
                <option>Cultivo...</option>
                {cultivoList.map((item) => (
                  <option value={item.idcultivo} key={item.idcultivo}>
                    {item.cultivo}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-3">
              <input
                className="form-control"
                value={form.temporada}
                onWheel={(e) => e.target.blur()}
                type="number"
                onChange={(value) => {
                  setForm({
                    ...form,
                    temporada: value.target.value,
                  });
                  localStorage.setItem("temporada", value.target.value);
                }}
                min="0"
              />
            </div>
            <div className="col-3 d-grid gap-2">
              <button className="btn btn-primary" onClick={() => getData()}>
                <FontAwesomeIcon icon="fas fa-search" /> Buscar
              </button>
            </div>
            <div className="col-3 d-grid gap-2">
              <button
                className="btn btn-success"
                onClick={() => validateData()}
              >
                <FontAwesomeIcon icon="fas fa-save" /> Guardar
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 table-responsive">
              {tableContent.length > 0 && (
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th>Sup.</th>
                      <th>Prod/Ha.</th>
                      {tamanoList?.map((tamano) => (
                        <th key={tamano.tamano}>{tamano.tamano}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tableContent?.map((variedad, variedadIndex) => (
                      <>
                        <tr key={variedad.id_variedad}>
                          <td></td>
                          <td style={{ fontWeight: "bold" }}>
                            {variedad.variedad}
                          </td>
                          <td>{variedad.superficie}</td>
                          <td>
                            {Intl.NumberFormat().format(
                              variedad.produccion_hectarea
                            )}
                          </td>
                          {variedad.tamanos?.map((tamano) => (
                            <td key={tamano.tamano}>
                              {parseFloat(
                                tamano.valor / variedad.lotes.length
                              ).toFixed(2)}{" "}
                              %
                            </td>
                          ))}
                          <td></td>
                          <td></td>
                        </tr>
                        {variedad.lotes?.map((lote, loteIndex) => (
                          <tr key={loteIndex}>
                            <td></td>
                            <td>{lote.lote}</td>
                            <td>{lote.superficie}</td>
                            <td>
                              <input
                                onChange={(e) =>
                                  produccionChangeHandler(
                                    variedadIndex,
                                    loteIndex,
                                    e
                                  )
                                }
                                className="form-control"
                                onWheel={(e) => e.target.blur()}
                                value={lote.produccion_hectarea}
                                type="number"
                              />
                            </td>
                            {lote.tamanos?.map((tamano, tamanoIndex) => (
                              <td key={tamano.tamano}>
                                <input
                                  className="form-control"
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  value={tamano.valor}
                                  min="0"
                                  onChange={(e) =>
                                    tamanoChangeHandler(
                                      variedadIndex,
                                      loteIndex,
                                      tamanoIndex,
                                      e
                                    )
                                  }
                                />
                              </td>
                            ))}
                            <td>{lote.porcentaje_total}%</td>
                            <td>
                              <button
                                className="btn btn-outline-dark"
                                onClick={() => {
                                  getBitacora(lote.id_lote);
                                }}
                              >
                                <FontAwesomeIcon icon="fas fa-book" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      <Modal
        isOpen={isVisible}
        size="xl"
        toggle={() => {
          setIsVisible(false);
        }}
      >
        <ModalHeader
          toggle={() => {
            setIsVisible(false);
          }}
        >
          Bitácora
        </ModalHeader>
        <ModalBody className="table-responsive">
          <table className="table">
            <thead className="table-light">
              <tr>
                <th scope="col">Temporada</th>
                <th scope="col">Campo</th>
                <th scope="col">Lote</th>
                <th scope="col">Cantidad</th>
                <th scope="col">Usuario</th>
                <th scope="col">Fecha</th>
              </tr>
            </thead>
            <tbody>
              {bitacoraContent?.map((item) => (
                <tr key={item.id_bitacora}>
                  <td>{item.temporada}</td>
                  <td>{item.campo}</td>
                  <td>{item.lote}</td>
                  <td>{item.cantidad}</td>
                  <td>{item.usuario}</td>
                  <td>{item.fecha}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ProyeccionTemporada;
