import React from "react";
import { useCampoContext } from "../../Context/CampoAuth";
import ProsideBar from "../Nav/ProsideBar";
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
} from "react-router-dom";
import Home from "../Home/Home";
import InsertarTipo from "../configuraciones/insertar_tipos.js";
import InsertarCultivo from "../configuraciones/insertar_cultivo.js";
import Inicio from "../Home/Inicio.js";
import InsertarTamano from "../configuraciones/insertar_tamano.js";
import RelacionarCultivoLote from "../configuraciones/relacionar_tipo_lote.js";
import Etiquetas from "../configuraciones/Etiquetas.js";
import Palet from "../configuraciones/palet.js";
import Yeguere from "../configuraciones/yeguere.js";
import Destino from "../configuraciones/destino.js";
import Tarima from "../configuraciones/tarima.js";
import ProduccionDiariaGeneral from "../Reportes/ProduccionDiariaGeneral";
import ProduccionSemanalGeneral from "../Reportes/ProduccionSemanalGeneral";
import { Row, Col } from "reactstrap";
import { Users } from "../configuraciones/Users";
import { Permisos } from "../configuraciones/Permisos";
import { useUserAccess } from "../../CustomHooks/useUserAccess";
import ProyeccionTemporada from "../Proyeccion_temporada/Proyeccion.Temporada";
import ProyeccionDiaria from "../Proyeccion_diaria/Proyeccion.diaria";
import ReporteProyeccionTemporada from "../Proyeccion_temporada/Reporte.Proyeccion.Temporada";
import ProyeccionSemanal from "../proyeccion_semanal/Proyeccion.semanal";
import OrdenEmpaque from "../orden_empaque/OrdenEmpaque.jsx";
import AgregarEtiqueta from "../configuraciones/AgregarEtiqueta";
import Notification from "../Administracion/Notification";
import ConsolidadoPedido from "../Reportes/ConsolidadoPedidos";
import PedidoEmpaque from "../pedido_empaque/PedidoEmpaque";

const ROUTES = [
  {
    url: "/proyeccion/temporada/captura",
    component: <ProyeccionTemporada />,
  },
  {
    url: "/proyeccion/temporada/reporte",
    component: <ReporteProyeccionTemporada />,
  },
  {
    url: "/proyeccion/diaria",
    component: <ProyeccionDiaria />,
  },
  {
    url: "/proyeccion/semanal",
    component: <ProyeccionSemanal />,
  },
  {
    url: "/empaque/pedido",
    component: <PedidoEmpaque />,
  },
  {
    url: "/empaque/orden",
    component: <OrdenEmpaque />,
  },
  {
    url: "/configuracion/cultivo/agregar",
    component: <InsertarCultivo />,
  },
  {
    url: "/configuracion/variedad/agregar",
    component: <InsertarTipo />,
  },
  {
    url: "/configuracion/tamano/agregar",
    component: <InsertarTamano />,
  },
  {
    url: "/configuracion/lote/agregar",
    component: <RelacionarCultivoLote />,
  },
  {
    url: "/configuracion/etiquetas",
    component: <Etiquetas />,
  },
  {
    url: "/configuracion/usuarios",
    component: <Users />,
  },
  {
    url: "/configuracion/permisos",
    component: <Permisos />,
  },
  {
    url: "/empaque/configuracion/pallet/agregar",
    component: <Palet />,
  },
  {
    url: "/empaque/configuracion/yeguere/agregar",
    component: <Yeguere />,
  },
  {
    url: "/empaque/configuracion/destino/agregar",
    component: <Destino />,
  },
  {
    url: "/empaque/configuracion/tarima/agregar",
    component: <Tarima />,
  },
  {
    url: "/reportes/proyeccion/diaria",
    component: <ProduccionDiariaGeneral />,
  },
  {
    url: "/reportes/proyeccion/semanal",
    component: <ProduccionSemanalGeneral />,
  },
  {
    url: "/configuracion/agregar-etiqueta",
    component: <AgregarEtiqueta />,
  },
  {
    url: "/configuracion/notificaciones",
    component: <Notification />,
  },
  {
    url: "/reportes/consolidado-de-pedidos",
    component: <ConsolidadoPedido />,
  },
];

const CampoRouter = () => {
  const appCampoContext = useCampoContext();
  const user = JSON.parse(localStorage.getItem("@usuario"));
  const { userAccess } = useUserAccess(user.id);

  const permittedUrls = userAccess
    .filter((access) => access.modulo.url !== null)
    .map((access) => access.modulo.url);

  const permittedRoutes = ROUTES.filter((route) =>
    permittedUrls.includes(route.url)
  );

  return (
    <>
      {appCampoContext.selectedCampo > 0 ? (
        <Router>
          <div style={{ display: "flex", backgroundColor: "#F2F4F4" }}>
            <ProsideBar access={userAccess} />
            <div
              className="container-fluid"
              style={{ marginLeft: "10px", marginRight: "20px", width: "100%" }}
            >
              <Row>
                <Col xs={12}>
                  <Switch>
                    <Route exact path="/" element={<Inicio />} />
                    {permittedRoutes.map((route) => (
                      <Route
                        key={route.url}
                        exact
                        path={route.url}
                        element={route.component}
                      />
                    ))}
                  </Switch>
                </Col>
              </Row>
            </div>
          </div>
        </Router>
      ) : (
        <Home />
      )}
    </>
  );
};

export default CampoRouter;
