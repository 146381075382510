import React, { useState, useContext, createContext, useEffect } from "react";

const AppContext = createContext({
  isLoggedin: [],
  handleLogIn: () => {},
});

export const AppProvider = ({ children }) => {
  const [isLoggedin, setIsLoggedin] = useState(false);

  const setLoginState = async () => {
    try {
      await localStorage.setItem("login", true);
    } catch {}
  };

  const getLoginState = async () => {
    try {
      const result = localStorage.getItem("login");
      if (result) {
        return true;
      }
    } catch {}
    return false;
  };

  const handleLogIn = () => {
    setIsLoggedin(true);
  };

  const handleLogout = () => {
    setIsLoggedin(false);
  };

  useEffect(() => {
    const fetcData = async () => {
      const data = await getLoginState();
      if (data) {
        setIsLoggedin(true);
      }
    };

    fetcData();
  }, []);

  return (
    <AppContext.Provider
      value={{
        isLoggedin,
        handleLogIn,
        handleLogout,
        setLoginState,
        setIsLoggedin,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
