import { useEffect, useState } from "react";
import api from "../Component/axios";

export function useCampos() {
  const [campos, setCampos] = useState([]);

  const fetchCampos = async () => {
    try {
      const res = await api.get("api/permisos/obtener/all-campos");
      setCampos([...res.data]);
    } catch (error) {}
  };

  useEffect(() => {
    fetchCampos();
  }, []);

  return { campos };
}
