import "./login.css";
import React from "react";
import api from "../../Component/axios.js";
import { useAppContext } from "../../Context/UseAuth.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { Formik, Form, Field } from "formik";

import image from "../../assets/bg.jpg";
import logo from "../../assets/divine.webp";
import Swal from "sweetalert2";

import { validationSchema } from "../../validations.js";
library.add(fab, fas, far);

function Login() {
  const appContext = useAppContext();

  const iniciarSesion = async (credenciales) => {
    await api
      .post("api/permisos/login/user", { data: credenciales })
      .then(async (response) => {
        localStorage.setItem("@usuario", JSON.stringify(response.data.user));
        localStorage.setItem("@Token", response.data.token);
        appContext.setIsLoggedin(true);
        appContext.setLoginState();
      })
      .catch((error) => {
        console.log(error.response.data);
        Swal.fire({
          icon: "error",
          title: "Usuario o contraseña incorrectas",
        });
      });
  };

  return (
    <main
      className="login-container"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(4, 15, 5, 0.3), rgba(4, 15, 5,0.85)), url(${image})`,
      }}
    >
      <Formik
        initialValues={{
          user: "",
          contra: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => iniciarSesion(values)}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ errors, touched }) => (
          <Form className="form-container">
            <img src={logo} className="logo" alt="logo" />
            <h5>¡Bienvenido!</h5>

            <div className="container">
              {errors.user && (
                <span style={{ color: "red" }}>{errors.user}</span>
              )}
              <div className="input-group mb-2">
                <span className="input-group-text" id="basic-addon1">
                  <FontAwesomeIcon icon="fa-solid fa-user" />
                </span>
                <Field
                  className="form-control m-0"
                  placeholder="Usuario"
                  name="user"
                />
              </div>

              {errors.contra && (
                <span style={{ color: "red" }}>{errors.contra}</span>
              )}
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">
                  <FontAwesomeIcon icon="fa-solid fa-lock" />
                </span>
                <Field
                  type="password"
                  className="form-control m-0"
                  placeholder="Contraseña"
                  name="contra"
                />
              </div>
            </div>

            <button
              className="btn btn-large btn-primary w-100 mt-8"
              type="submit"
            >
              Iniciar sesión
            </button>
          </Form>
        )}
      </Formik>
    </main>
  );
}

export default Login;
