import { AppProvider } from '../Context/CampoAuth'
import CampoRouter from '../Vistas/Router/CampoRouter'

const Campo = () => {
    return (
        <AppProvider>
            <CampoRouter />
        </AppProvider>
    )
}

export default Campo;