import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../Component/axios";
import moment from "moment";
import Swal from "sweetalert2";

const ProduccionSemanalGeneral = () => {
  const [campos, setCampos] = useState([]);
  const [variedades, setVariedades] = useState([]);
  const [proyeccion, setProyeccion] = useState([]);
  const [semanas, setSemanas] = useState([]);

  const getRport = (values) => {
    Swal.showLoading();
    api
      .post("api/proyeccion-semanal/reporte", {
        data: values.semana,
      })
      .then((response) => {
        setCampos(response.data[0]);
        setVariedades(response.data[1]);
        setProyeccion(response.data[2]);
      })
      .finally(() => {
        Swal.close();
      });
  };

  useEffect(() => {
    api
      .get(`api/proyeccion-semanal/semanas/?temporada=${moment().year()}`)
      .then((response) => {
        setSemanas(response.data);
      });
  }, []);

  return (
    <Card className="my-2" style={{ height: "90vh" }}>
      <CardHeader style={{ textAlign: "center" }}>
        <b>Reporte de producción semanal por campo</b>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <Formik
              initialValues={{
                semana: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => getRport(values)}
              validateOnBlur={false}
            >
              {({ errors }) => (
                <Form>
                  <div className="row">
                    <div className="col-4">
                      <span style={{ color: errors.semana ? "red" : "black" }}>
                        Semana
                      </span>
                      <Field
                        type="date"
                        className="form-control"
                        name="semana"
                        as="select"
                        style={{ borderColor: errors.semana && "red" }}
                      >
                        <option value="">Semana</option>
                        {semanas.map((item, i) => (
                          <option key={i} value={item.semana}>
                            {`${item.semana} - ${moment(item.inicio).format(
                              "YYYY/MM/DD"
                            )} - ${moment(item.fin).format("YYYY/MM/DD")}`}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div
                      className="col-2 d-grid gap-2"
                      style={{ marginTop: "25px" }}
                    >
                      <button className="btn btn-primary" type="submit">
                        <FontAwesomeIcon icon="fas fa-search" /> Buscar
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
        <br />
        <Row>
          {proyeccion.length > 0 && (
            <Col
              className="table-responsive"
              style={{ margin: "20px", overflow: "scroll", height: "70vh" }}
            >
              <table className="table table-bordered table-striped table-condensed table-sm">
                <thead>
                  <tr>
                    <th>Variedad</th>
                    {campos.map((item) => (
                      <th key={item.idcampo}>{item.campo}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {variedades.map((variedad) => (
                    <tr key={variedad.idtipo}>
                      <td>{variedad.tipo}</td>
                      {campos.map((campo) =>
                        proyeccion.map(
                          (item, i) =>
                            campo.campo === item.campo &&
                            item.variedad === variedad.tipo && (
                              <td key={i}>{item.proyeccion}</td>
                            )
                        )
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

const validationSchema = Yup.object().shape({
  semana: Yup.string().required().label("Semana"),
});

export default ProduccionSemanalGeneral;
