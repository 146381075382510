import { useCallback, useEffect, useState } from "react";
import api from "../Component/axios";

export function useUserAccess(userId) {
  const [userAccess, setUserAccess] = useState([]);

  const fetchAccess = useCallback(async () => {
    try {
      const res = await api.get(`api/permisos/accesos/?user_id=${userId}`);
      setUserAccess([...res.data]);
    } catch (error) {}
  }, [userId]);

  useEffect(() => {
    fetchAccess();
  }, [fetchAccess]);

  return { userAccess };
}
