import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../Component/axios";
import { Formik, Form, Field } from "formik";
import { notificationValidationSchema } from "../../validations";
import Swal from "sweetalert2";

const Notification = () => {
  const [numbers, setNumbers] = useState([]);
  const [campoList, setCampoList] = useState([]);

  const saveNumbers = (values) => {
    const token = localStorage.getItem("@Token");
    api
      .post(
        "api/permisos/notification-numbers/",
        { data: values },
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: response.data.msg,
          timer: 1500,
        });
        getNumbers();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Algo salio mal!",
          text: "Favor de comunicarlo al equipo de desarrollo",
        });
      });
  };

  const deleteNumber = (id_numero, estatus) => {
    const token = localStorage.getItem("@Token");
    let xestatus = 0;
    if (estatus === 0) {
      xestatus = 1;
    }

    api
      .delete(
        `api/permisos/notification-numbers/?id_numero=${id_numero}&estatus=${xestatus}`,
        {
          headers: {
            Authorization: "Token " + token,
          },
        }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: response.data.msg,
          timer: 1500,
        });
        getNumbers();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getNumbers = () => {
    const token = localStorage.getItem("@Token");
    api
      .get(`api/permisos/notification-numbers/?estatus=1`, {
        headers: {
          Authorization: "Token " + token,
        },
      })
      .then((response) => {
        setNumbers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    api.get("api/permisos/obtener/all-campos").then((response) => {
      setCampoList(response.data);
    });
    getNumbers();
  }, []);

  return (
    <Card className="my-2">
      <CardHeader style={{ textAlign: "center", fontWeight: "bold" }}>
        Notificaciones
      </CardHeader>
      <CardBody>
        <Formik
          initialValues={{
            id_campo: 0,
            nombre: "",
            numero: "",
            tipo: "",
          }}
          onSubmit={(values) => saveNumbers(values)}
          validationSchema={notificationValidationSchema}
          validateOnBlur={false}
        >
          {({ errors, touched }) => (
            <Form>
              <Row>
                <Col md={2} sm={12}>
                  <label style={errors.id_campo && { color: "red" }}>
                    Campo
                  </label>
                  <Field
                    as="select"
                    className="form-control"
                    name="id_campo"
                    style={errors.id_campo && { borderColor: "red" }}
                  >
                    <option value={0}>Seleccionar...</option>
                    {campoList.map((campo) => (
                      <option key={campo.idcampo} value={campo.idcampo}>
                        {campo.campo}
                      </option>
                    ))}
                  </Field>
                </Col>
                <Col md={2} sm={12}>
                  <label style={errors.nombre && { color: "red" }}>
                    Nombre
                  </label>
                  <Field
                    type="input"
                    className="form-control"
                    name="nombre"
                    style={errors.nombre && { borderColor: "red" }}
                  />
                </Col>
                <Col md={2} sm={12}>
                  <label style={errors.numero && { color: "red" }}>
                    Numero / ID
                  </label>
                  <Field
                    type="input"
                    className="form-control"
                    name="numero"
                    style={errors.numero && { borderColor: "red" }}
                  />
                </Col>
                <Col md={2} sm={12}>
                  <label style={errors.tipo && { color: "red" }}>Tipo</label>
                  <Field
                    as="select"
                    className="form-control"
                    name="tipo"
                    style={errors.tipo && { borderColor: "red" }}
                  >
                    <option value="">Seleccionar</option>
                    <option value="ambas">Ambas</option>
                    <option value="empaque">Empaque</option>
                    <option value="diaria">Diaria</option>
                  </Field>
                </Col>
                <Col md={3} sm={12} className="d-grid">
                  <button type="submit" className="btn btn-success my-4">
                    Guardar
                  </button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
        <Row>
          <Col className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre</th>
                  <th>Número</th>
                  <th>Tipo</th>
                  <th>Campo</th>
                  <th>Acción</th>
                </tr>
              </thead>
              <tbody>
                {numbers.map((item) => (
                  <tr key={item.id_number}>
                    <td>{item.id_number}</td>
                    <td>{item.nombre}</td>
                    <td>{item.number}</td>
                    <td>{item.type}</td>
                    <td>{item.campo}</td>
                    <td>
                      <button
                        className={
                          item.estatus === 1
                            ? "btn btn-sm btn-success"
                            : "btn btn-sm btn-danger"
                        }
                        onClick={() =>
                          deleteNumber(item.id_number, item.estatus)
                        }
                      >
                        <FontAwesomeIcon icon="fas fa-power-off" />
                      </button>{" "}
                      {/* <button className="btn btn-sm btn-primary">
                        <FontAwesomeIcon icon="fas fa-pen" />
                      </button> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Notification;
